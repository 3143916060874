import axios from 'axios';
import { showToast, showDialog } from 'vant';

import refactorApi from '@/axios/refactorApi';
import env from '@/config/env.js';
import i18n from '@/i18n/i18n';
import store from '@/store/h5.js';
import route from '@/store/modules/router';
import * as types from '@/store/mutation-types.js';
import errCode from '@/utils/common/errCode.js';
let timer = null;

const { t } = i18n.global;
let xCsrfToken = '';
let tokenTimes = 0;
let getTokenPromise; // 全局获取token promise 状态函数
let msgInstance = ''; // 流控弹窗

const message = env.curVersionEnv === 'enJdc' ? 'Poor network connection. Check the network settings.' : '网络不太顺畅，请检查网络设置';

const getCsrfToken = () => {
  return store.state.h5.xCsrfToken;
};

const getCsrfTokenCreated = () => {
  return store.state.h5.xCsrfTokenCreated || 0;
};

export const requestCsrfToken = async focus => {
  const nowTime = new Date().getTime();
  xCsrfToken = getCsrfToken();
  // 存在 token 并且不需要强制且有效期内获取则直接获取已存在的
  if (!focus && xCsrfToken && nowTime - getCsrfTokenCreated() <= 600 * 1000) {
    return xCsrfToken;
  }
  // 当前没有正在获取的请求，开始获取
  if (!getTokenPromise) {
    getTokenPromise = getToken();
    tokenTimes++;
  }

  // 兼顾后续请求如果需要获取 token 则等待当前获取完成更新值
  if (getTokenPromise) {
    const res = await getTokenPromise;
    // 更新store值
    store.commit(types.USER_TOKEN_VALUE, res.data?.value);
    store.commit(types.USER_TOKEN_CREATED, res.data?.created);
    // 获取完成之后清除
    getTokenPromise = null;
  }
  return getCsrfToken();
};

const axiosCommonConfig = {
  baseURL: env.newApiBaseURL,
  timeout: 600000,
  withCredentials: true,
  crossDomain: true,
  responseType: 'json',
};

const instance = axios.create(axiosCommonConfig);

const noCsrfTokenList = [refactorApi.user.login];

// 请求拦截器
instance.interceptors.request.use(
  async config => {
    // 请求头添加x-csrf-token
    if (!noCsrfTokenList.includes(config.url)) {
      xCsrfToken = await requestCsrfToken();
      if (xCsrfToken) {
        config.headers['x-csrf-token'] = xCsrfToken;
        config.headers.client = window.currentClient;
      }
    }

    config.params = Object.assign({}, config.params, {
      t: new Date().getTime(),
      app: 'jdc',
      lang: route.state.lang,
    });

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  response => {
    const data = response.data;
    const url = response.config.url;
    // 系统错误提示
    if ([errCode.notLogin, errCode.noLogin, errCode.apiNoLogin].includes(data.code)) {
      if (!url?.includes('/jdc/rest/sso/postLogin') && !url?.includes('/jdc/api/user/profile/')) {
        showToast(data.message);
      }
    }
    if ([errCode.notLogin, errCode.noLogin].includes(data.code)) {
      store.dispatch(types.NOT_LOGIN);
      return response.data;
    }
    if (data.code === errCode.denyAccess) {
      showToast({
        message: data.message,
        duration: 3000,
      });
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
      timer = setTimeout(() => {
        store.dispatch(types.NOT_LOGIN);
        return response.data;
      }, 3000);
    }
    if (!response.config.url.includes(refactorApi.gateway.getToken)) {
      tokenTimes = 0;
    }
    if (response.config.responseType === 'blob') {
      return response;
    }
    return response.data;
  },
  async error => {
    if (error.response?.status && error.response.status === errCode.flowControlCode) {
      if (!msgInstance) {
        msgInstance = showDialog({
          message: t('lang.common.flowControlTips'),
          confirmButtonText: t('lang.common.gotIt'),
          confirmButtonColor: '#00b6b6',
          className: 'flow-control-dialog',
        }).then(() => {
          msgInstance = '';
        });
      }
      return;
    }
    if (error.code === errCode.networkErr) {
      setTimeout(() => showToast(message), 10);
    }
    if (error.response.status) {
      const status = error.response.status;
      switch (status) {
        case 412:
          if (tokenTimes < 10) {
            // 登录状态时获取新的token
            await requestCsrfToken(true);
            return instance.request(error.config); // 登录成功后重新请求！
          }
          break;
        default:
          break;
      }
    }
    return error;
  }
);
function getToken() {
  return axios.get(refactorApi.gateway.getToken, axiosCommonConfig);
}

export default instance;
