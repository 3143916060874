// 海外版H5路由地址
export const enRoutes = [
  {
    path: '/',
    redirect: '/home',
    component: () => import('@H5Components/layout/index'),
    children: [
      {
        name: 'home',
        path: '/home',
        component: () => import('@H5Components/home/index'),
        meta: {
          title: 'Home',
          pageCodes: {
            h5: 'P1EFDCBDD89381',
          },
        },
      },
      {
        name: 'enProfile',
        path: '/enProfile',
        component: () => import('@/components/enH5/enProfile.vue'),
        meta: {
          title: 'Profile',
          pageCodes: {
            h5: 'P86E816B1A9C429',
          },
        },
      },
      {
        name: 'group',
        path: '/group',
        component: () => import('@/components/enH5/group/index'),
        meta: {
          title: 'Groups',
          pageCodes: {
            h5: 'P4F4F119F1A0AD2',
          },
        },
      },
    ],
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('@H5Components/login/index.vue'),
    meta: {
      title: 'Login',
      shouldNotLogin: true, // 不需要登录
      pageCodes: {
        h5: 'P1EFEA1299D6AB',
      },
    },
  },
  {
    name: 'enCompletedInfo',
    path: '/enCompletedInfo',
    component: () => import('@/components/enH5/enCompleteInfo.vue'),
    meta: {
      title: 'Complete Information',
      pageCodes: {
        h5: 'P1F01967AC9834',
      },
    },
  },
  {
    name: 'cookie',
    path: '/cookie',
    component: () => import('@H5Components/agreementAndPrivacy/CookiePolicy.vue'),
    meta: {
      title: 'Cookie Policy',
      shouldNotLogin: true,
      pageCodes: {
        h5: 'P1EFF0838B1D93',
      },
    },
  },
  {
    name: 'userAgreement',
    path: '/userAgreement',
    component: () => import('@H5Components/agreementAndPrivacy/UserAgreementInfo.vue'),
    meta: {
      title: 'Terms of Use',
      shouldNotLogin: true,
      pageCodes: {
        h5: 'P1EFFAD277761C',
      },
    },
  },
  {
    name: 'privacy',
    path: '/privacy',
    component: () => import('@H5Components/agreementAndPrivacy/PrivacyStatementInfo.vue'),
    meta: {
      title: 'Privacy Statement',
      shouldNotLogin: true,
      pageCodes: {
        h5: 'P1F002BAD13A45',
      },
    },
  },
  {
    name: 'groupDetail',
    path: '/groupDetail',
    component: () => import('@/components/enH5/groupDetail/index'),
    meta: {
      title: 'Group',
      pageCodes: {
        h5: 'P9635214A228D14',
      },
    },
  },
  {
    name: 'postDetail',
    path: '/postDetail',
    component: () => import('@H5Components/post/postDetail'),
    meta: {
      title: 'Post',
      pageCodes: {
        h5: 'P6E5A04692B1D0B',
      },
    },
  },
  {
    name: 'personalData',
    path: '/personalData',
    component: () => import('@H5Components/personalData/HomeData.vue'),
    meta: {
      title: 'User Information',
      pageCodes: {
        h5: 'P86E839A4F0CA7D',
      },
    },
  },
  {
    name: 'myPost',
    path: '/myPost',
    component: () => import('@H5Components/personalCenter/MyPost.vue'),
    meta: {
      title: 'My Posts',
      pageCodes: {
        h5: 'P86E8416F4AFA86',
      },
    },
  },
  {
    name: 'beanrecord',
    path: '/beanrecord',
    component: () => import('@H5Components/bean/BeanRecord'),
    meta: {
      title: 'My points',
      pageCodes: {
        h5: 'P90AF2BADDAF061',
      },
    },
  },
  {
    name: 'nickName',
    path: '/nickName',
    component: () => import('@H5Components/personalData/NickNameInfo.vue'),
    meta: {
      title: 'Nick Name',
      pageCodes: {
        h5: 'PF1BC299C93E89',
      },
    },
  },
  {
    name: 'personalSign',
    path: '/personalSign',
    component: () => import('@H5Components/personalData/PersonalSignInfo.vue'),
    meta: {
      title: 'Personal Sign',
      pageCodes: {
        h5: 'P732546CE999B66',
      },
    },
  },
  {
    name: 'realName',
    path: '/realName',
    component: () => import('@H5Components/personalData/CommonEditInfo.vue'),
    meta: {
      title: 'Real Name',
      pageCodes: {
        h5: 'P61E7ACE3E8D142',
      },
    },
  },
  {
    name: 'industry',
    path: '/industry',
    component: () => import('@H5Components/personalData/EnIndustryInfo.vue'),
    meta: {
      title: 'Industry',
      pageCodes: {
        h5: 'P4EB20871907D1A',
      },
    },
  },
  {
    name: 'company',
    path: '/company',
    component: () => import('@H5Components/personalData/CompanyInfo.vue'),
    meta: {
      title: 'Company',
      pageCodes: {
        h5: 'PF1CEC2DF06E09',
      },
    },
  },
  {
    name: 'email',
    path: '/email',
    component: () => import('@H5Components/personalData/EmailInfo.vue'),
    meta: {
      title: 'Email',
      pageCodes: {
        h5: 'P732675F3997DB3',
      },
    },
  },
  {
    name: 'phoneNumber',
    path: '/phoneNumber',
    component: () => import('@H5Components/personalData/EnPhoneNumberInfo.vue'),
    meta: {
      title: 'Phone Number',
      pageCodes: {
        h5: 'P732675F3997DB3',
      },
    },
  },
  {
    name: 'accountDeregistration',
    path: '/accountDeregistration',
    component: () => import('@H5Components/agreementAndPrivacy/AccountDeregistration.vue'),
    meta: {
      title: 'Account Deregistration',
      pageCodes: {
        h5: 'P5D1C51E22D1AAC',
      },
    },
  },
  {
    name: 'lottery',
    path: '/lottery/:id',
    component: () => import('@H5Components/lottery/index.vue'),
    meta: {
      title: 'HUAWEI JDC',
      pageCodes: {
        h5: 'P5D1C55336B40F6',
      },
    },
  },
  {
    name: 'newThread',
    path: '/newThread',
    component: () => import('@H5Components/newThread/Index'),
    meta: {
      title: 'Create Post',
      pageCodes: {
        h5: 'P8872B88335D26C',
      },
    },
  },
  {
    name: 'hisHomePage',
    path: '/hisHomePage',
    component: () => import('@H5Components/hisCenter/HisHomePage.vue'),
    meta: {
      title: '',
      pageCodes: {
        h5: 'P1ACDB46562910B',
      },
    },
  },
  // 未匹配的路由
  {
    path: '/:catchAll(.*)',
    redirect: '/home?type=recommend',
  },
];
