import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-34c4d6ab"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "userAvatar"
};
const _hoisted_2 = {
  id: "inner-img"
};
const _hoisted_3 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("span", _hoisted_1, [_createElementVNode("span", _hoisted_2, [_createElementVNode("img", {
    ref: "img",
    loading: "lazy",
    class: "userAvatar-img",
    alt: "大V认证",
    src: $props.src,
    onLoad: _cache[0] || (_cache[0] = (...args) => $options.imgOnload && $options.imgOnload(...args)),
    onError: _cache[1] || (_cache[1] = (...args) => $options.onerrorHandler && $options.onerrorHandler(...args))
  }, null, 40, _hoisted_3)]), $options.getTypeItem ? (_openBlock(), _createElementBlock("span", {
    key: 0,
    class: _normalizeClass(["userAvatar-border", {
      'use-big-img': $props.useBigIcon
    }]),
    style: _normalizeStyle({
      backgroundImage: `url(${$props.useBigIcon ? $options.getTypeItem.bigImg : $options.getTypeItem.img})`
    })
  }, null, 6)) : (_openBlock(), _createElementBlock("span", {
    key: 1,
    class: _normalizeClass({
      'common-img-border-small': $data.isSmall,
      'common-img-border': true
    })
  }, null, 2))]);
}