import env from '@/config/env';
const sourceNames = [
  'laugh.gif',
  'smile.gif',
  'kiss.gif',
  'hug.gif',
  'envious.gif',
  'bare-teeth.gif',
  'shy.gif',
  'snicker.gif',
  'eyes-money.gif',
  'blink.gif',
  'naughty.gif',
  'passionate-kiss.gif',
  'cute.gif',
  'love-you.gif',
  'greedy.gif',
  'angry.gif',
  'applaud.gif',
  'awesome.gif',
  'not-good.gif',
  'friend.gif',
  'yes.gif',
  'bookworm.gif',
  'clown.gif',
  'crazy.gif',
  'cry-loudly.gif',
  'decline.gif',
  'doubt.gif',
  'faint.gif',
  'flower.gif',
  'wither.gif',
  'celebrate.gif',
  'fireworks.gif',
  'get-ill.gif',
  'ghost.gif',
  'gift.gif',
  'goodbye.gif',
  'heart.gif',
  'heartbroken.gif',
  'hehe.gif',
  'hold-fist.gif',
  'ill.gif',
  'knock-head.gif',
  'nasty.gif',
  'ok.gif',
  'picking-nose.gif',
  'police.gif',
  'pray.gif',
  'blush.gif',
  'anger.gif',
  'puzzled.gif',
  'roll-eyes.gif',
  'sad.gif',
  'sars.gif',
  'scold.gif',
  'shutup.gif',
  'sigh.gif',
  'sinister-smile.gif',
  'skeleton.gif',
  'sleep.gif',
  'sleepy.gif',
  'smiley-face.gif',
  'speechless.gif',
  'strong.gif',
  'struggle.gif',
  'surprise.gif',
  'frown.gif',
  'face-covering.gif',
  'surprised.gif',
  'disappointment.gif',
  'sweat.gif',
  'tears-laughter.gif',
  'think.gif',
  'tongue.gif',
  'tricky.gif',
  'turn.gif',
  'voice-down.gif',
  'vomit.gif',
  'witty.gif',
  'yeah.gif',
];
const getSourcePath = () => {
  return sourceNames.map(name => {
    return {
      name,
      url: `${env.staticUrl}/static_vue/miniProgramImg/wximage/emoji/${name}`,
    };
  });
};
const index = getSourcePath();
export default index;
