import axios from 'axios';
import { ElMessage as Message } from 'element-plus';

import { formatResData, getXcsrfToken, setXcsrfToken, getXcsrfTokenCreated, setXcsrfTokenCreated } from '@/axios/common';
import oceanClubApi from '@/axios/oceanClubApi';
import refactorApi from '@/axios/refactorApi';
import env from '@/config/env.js';
import i18n from '@/i18n/i18n';
import store from '@/store';
import route from '@/store/modules/router';
import user from '@/store/modules/user.js';
import * as types from '@/store/mutation-types.js';
import errCode from '@/utils/common/errCode.js';
import RouterUtils from '@/utils/common/RouterUtils';

const { t } = i18n.global;

const RequestURL = {};

let app = RouterUtils.getApp(window.location.href);
const lang = RouterUtils.getLang(window.location.href, localStorage.getItem('lang'));

let tokenTimes = 0;
let tokenPromise = '';
let msgInstance = '';

/**
 * @description 请求预处理，主要对 get 请求的参数进行 encode 编码，防止 axios 不对特殊字符编码导致后端解析参数出错从而请求不能正常返回
 * @param {object} obj 请求配置对象
 * @param {string} obj.method 请求Method
 * @param {string} obj.url 请求 url
 * @param {object} obj.params 请求-GET的参数
 * @returns {{ url: string, params: object }} 处理后的 ul 和 params
 */
function preprocessRequest(obj) {
  const { method: _method = '', url = '', params = {} } = obj || {};

  const method = _method.toLowerCase ? _method.toLowerCase() : _method;
  if (method !== 'get') {
    // 不处理非 get 请求
    return { url, params };
  }

  let newUrl = url;
  const newParams = {};

  // url 可能已经带上了部分参数
  if (newUrl.includes('?')) {
    newUrl += newUrl.endsWith('?') ? '' : '&';
  } else {
    newUrl += '?';
  }

  // params 中的参数可能和 url 已有参数冲突，后续遇到此类需要覆盖参数的场景再处理
  const queryString = Object.entries(params)
    .filter(([key, value]) => {
      if (Array.isArray(value)) {
        // 如果 value 是数组则仅进行encode编码，不自行进行拼接，特殊场景下自行添加额外逻辑处理
        newParams[`${encodeURIComponent(key)}`] = value.map(item => encodeURIComponent(item));
        return false;
      }
      if (!value) {
        // value 不存在，也不进行拼接，默认也走 axios 进行处理
        newParams[`${encodeURIComponent(key)}`] = value;
        return false;
      }
      return true;
    })
    .map(([key, value]) => {
      // 对参数进行 encode 编码
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join('&');

  newUrl += queryString;
  return { url: newUrl, params: newParams };
}

// 请求拦截器
axios.interceptors.request.use(
  async config => {
    // 请求头添加x-csrf-token，xCsrfToken 会在加载之后获取一次，如果存在则表示登录了或者登录有效
    const xCsrfToken = getXcsrfToken();
    if (xCsrfToken && !config.url.includes(refactorApi.gateway.getToken)) {
      const { value } = await getToken();
      config.headers['x-csrf-token'] = value;
    }
    if (config?.showMessage) {
      msgInstance = '';
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

function languageChange(navigatorLang, changeLang) {
  if (navigatorLang.indexOf('zh-cn') >= 0) {
    changeLang = 'zh';
  } else if (navigatorLang.indexOf('en') >= 0) {
    changeLang = 'en';
  } else if (navigatorLang.indexOf('ru') >= 0) {
    changeLang = 'ru';
  } else if (navigatorLang.indexOf('es') >= 0) {
    changeLang = 'es';
  } else if (navigatorLang.indexOf('fr') >= 0) {
    changeLang = 'fr';
  }
  window.open(`/enterprise/${changeLang}/index.html`, '_self');
}
// 例外的API，该API内的错误信息返回到组件，不做弹窗，urlReg 支持正则匹配
const EXCEPT_APIS = [
  { url: refactorApi.user.login, method: 'post' },
  { url: refactorApi.user.postRepair, method: 'post' },
  { url: refactorApi.post.warnPost, method: 'get' },
  { url: refactorApi.post.reply, method: 'post' },
  { url: refactorApi.post.PostComment, method: 'post' },
  { url: refactorApi.post.postReply, method: 'post' },
  { url: refactorApi.post.getThreadShare, method: 'get' },
  { url: refactorApi.user.getWechatPic, method: 'get' },
  { url: refactorApi.post.postNewThread, method: 'get' },
  { url: refactorApi.forum.joinGroup, method: 'post' },
  { urlReg: new RegExp(refactorApi.lottery.limitTimes('[^/]+')), method: 'get' },
  { url: refactorApi.forum.getGroupInfo, method: 'get' },
  { url: refactorApi.forum.getGroupInfoext, method: 'get' },
  { url: refactorApi.forum.getForumList, method: 'post' },
  { url: refactorApi.post.getThreadDetail, method: 'get' },
  { url: refactorApi.post.getTopicInfo, method: 'post' },
  { url: refactorApi.user.getLatestUserMedal, method: 'get' },
  { url: refactorApi.post.draft, method: 'post' },
];
const isExceptApi = ({ url: rawUrl, method }) => {
  const url = rawUrl.split('?')[0];
  return EXCEPT_APIS.some(item => (item.url === url || item.urlReg?.test(url)) && item.method === method);
};

const pathCodeMap = {
  '/jdc/refactor/viewForum': ['023010'],
  '/jdc/refactor/viewthread': ['023010'],
};
// 指定路由的页面不显示某个提示
function noNeedTipsByPath(code) {
  const codeMap = pathCodeMap[window.location.pathname];
  return codeMap && codeMap.includes(code);
}

// 不需要提示
function noNeedTips(code) {
  const dataObj = {
    '03201': t('lang.common.messageTip.beReviewed'),
  };
  return Object.prototype.hasOwnProperty.call(dataObj, code) || noNeedTipsByPath(code);
}

// 响应拦截器
axios.interceptors.response.use(
  response => {
    // 未登录
    const navigatorLang = (navigator.language || navigator.browserLanguage).toLowerCase();
    const changeLang = '';
    if (app === 'enterprise' && !lang) {
      // 企业社区没有语言的跳转链接
      languageChange(navigatorLang, changeLang);
    }
    const { config, data } = response;
    if (config.url.includes('csrftoken') && data) {
      const { value, created } = data;
      setXcsrfToken(value);
      setXcsrfTokenCreated(created);
    }
    const { code, message } = data || {};
    // 禁止访问的提示框不自动消失
    const duration = code === errCode.denyAccess ? 0 : 3000;
    if (code && ![errCode.success, errCode.oldSuccess, errCode.noLogin].includes(code) && !isExceptApi(config)) {
      if (!msgInstance && !noNeedTips(code)) {
        msgInstance = Message({
          message: message || t('lang.common.messageTip.busy'),
          type: 'warning',
          duration,
          onClose: () => {
            msgInstance = '';
          },
        });
      }
    } else {
      tokenTimes = 0;
    }
    return response;
  },
  async error => {
    if (error.response && error.response.status === errCode.csrfCode) {
      // 登录状态时获取新的token
      if (user.state.firstLogin) {
        const tokenData = await getToken(true);
        if (tokenData && tokenTimes < 10) {
          // 把失败的请求重新发送出去
          return axios.request(error.config);
        }
      }
    }
    if (error.response && error.response.status === errCode.flowControlCode) {
      if (!msgInstance) {
        msgInstance = Message({
          message: t('lang.common.flowControlTips'),
          type: 'warning',
          duration: 3000,
          onClose: () => {
            msgInstance = '';
          },
        });
      }
    }
    return Promise.reject(error);
  }
);

async function getToken(focus) {
  const nowTime = new Date().getTime();
  const token = getXcsrfToken();
  const tokenCreated = getXcsrfTokenCreated();
  if (!focus && token && nowTime - tokenCreated <= 600 * 1000) {
    return { value: token };
  }
  if (!tokenPromise) {
    const url = refactorApi.gateway.getToken;
    tokenPromise = request({ url, method: 'get', params: null, data: null, apiBaseURL: env.newApiBaseURL });
    tokenTimes++;
  }
  const result = await tokenPromise.catch(() => {});
  const { value, created } = result || {};
  tokenPromise = '';
  setXcsrfToken(value);
  setXcsrfTokenCreated(created);
  return result || {};
}

function deployParams(params) {
  if (typeof params === 'undefined') {
    params = Object.create(null);
  }

  return Object.assign(
    {
      lang: route.state.lang,
    },
    params
  );
}

function axiosRequest({ url, method, params, data, apiBaseURL, responseType, uploadProgress, showMessage, signal = null }) {
  const finalBaseURL = apiBaseURL || `${env.newApiBaseURL}/${app}/api`;
  const requestUrl = `${url}${url.indexOf('?') > -1 ? '&' : '?'}t=${new Date().getTime()}&app=${app}`;
  return axios.request({
    url: requestUrl,
    method,
    baseURL: finalBaseURL,
    params,
    data,
    timeout: 600000,
    withCredentials: true,
    crossDomain: true,
    responseType: responseType || 'json',
    showMessage: showMessage || false,
    onUploadProgress: progressEvent => {
      if (uploadProgress) {
        const videoUploadPercent = Number(((progressEvent.loaded / progressEvent.total) * 100).toFixed(2));
        uploadProgress.onProgress({ percent: videoUploadPercent });
      }
    },
    signal, //终止请求
  });
}
// 封装axios的请求
export function request({ url: _url, method, params: _params, data, apiBaseURL, responseType, showMessage, signal }) {
  _params = deployParams(_params);

  const { url, params } = preprocessRequest({ url: _url, params: _params, method });

  if (app === 'carrier') {
    app = 'gs';
  }
  return new Promise((resolve, reject) => {
    axiosRequest({ url, method, params, data, apiBaseURL, responseType, showMessage, signal })
      .then(response => {
        const result = formatResData(response, responseType);
        if (method === 'post' && (result.code === errCode.noUpdatedInfo || result.code === errCode.oldUpdatedInfo)) {
          store.commit(types.UPDATED_INFO, true);
          reject();
        } else {
          resolve(result);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

// 上传接口的封装
export function uploadRequest({ url, method, params, data, apiBaseURL, uploadProgress }) {
  params = deployParams(params);
  if (app === 'carrier') {
    app = 'gs';
  }
  return new Promise((resolve, reject) => {
    axiosRequest({ url, method, params, data, apiBaseURL, responseType: 'json', uploadProgress })
      .then(response => {
        if (method === 'post' && (response.data.code === errCode.noUpdatedInfo || response.data.code === errCode.oldUpdatedInfo)) {
          store.commit(types.UPDATED_INFO, true);
        } else {
          resolve(response.data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export default {
  name: 'api',
  GET(url, params, apiBaseURL, responseType, showMessage, signal) {
    return request({ url, method: 'get', params, data: null, apiBaseURL, responseType, showMessage, signal });
  },
  POST(url, data, apiBaseURL, responseType, showMessage) {
    return request({ url, method: 'post', params: null, data, apiBaseURL, responseType, showMessage });
  },
  UPLOAD(url, data, apiBaseURL, uploadProgress) {
    return uploadRequest({ url, method: 'post', params: null, data, apiBaseURL, uploadProgress });
  },
  DELETE(url, params, apiBaseURL) {
    return request({ url, method: 'delete', params, data: null, apiBaseURL });
  },
  RequestURL,
  refactorApi,
  getXcsrfToken,
  getToken,
  oceanClubApi,
};
