import { createWebHistory, createRouter } from 'vue-router';

import axios from '@/axios/h5.js';
import api from '@/axios/h5Api.js';
const componentJump = import('@H5Components/jump/index.vue');
const jumpMeta = {
  title: '华为JDC',
  shouldNotLogin: true, // 不用登录
  pageCodes: {
    h5: 'P1BD9F138C8683C',
  },
};
/*
 * @Author:
 * @Date: 2021-11-19 15:33:27
 * @Description: h5 页面
 */
import { enRoutes } from '@/router/enH5';
import store from '@/store/h5';
import * as types from '@/store/mutation-types.js';
import { getMsgFromMiniOriginal } from '@/utils/common/common';
import APPUtil from '@/utils/h5/APPUtil';
import { IS_EN_JDC, isBuriedPageCode } from '@/utils/web/constant';

const msgFromMiniOriginal = getMsgFromMiniOriginal();

// h5 routes
const routes = [
  {
    path: '/',
    redirect: '/home',
    component: () => import('@H5Components/layout/index'),
    children: [
      {
        name: 'message',
        path: '/message',
        component: () => import('@H5Components/message/index'),
        meta: {
          title: '消息',
          pageCodes: {
            h5: 'PC66CC4B51E5826',
          },
        },
      },
      {
        name: 'home',
        path: '/home',
        component: () => import('@H5Components/home/index'),
        meta: {
          title: IS_EN_JDC ? 'Home' : '首页',
          pageCodes: {
            h5: 'P5E1C84AE61CC93',
          },
        },
      },
      {
        name: 'bean',
        path: '/bean',
        component: () => import('@H5Components/bean/index'),
        meta: {
          title: '智豆兑换',
          pageCodes: {
            h5: 'P5856562BE90DD3',
          },
        },
      },
      {
        name: 'group',
        path: '/group',
        component: () => import('@H5Components/group/index'),
        meta: {
          title: '圈子',
          pageCodes: {
            h5: 'P585A367D527C5E',
          },
        },
      },
    ],
  },
  {
    name: 'reply',
    path: '/reply',
    component: () => import('@H5Components/message/ReplyList'),
    meta: {
      title: '回复与评论',
      pageCodes: {
        h5: 'P5CB38609DA3FEB',
      },
    },
  },
  {
    name: 'invite',
    path: '/invite',
    component: () => import('@H5Components/message/InviteList.vue'),
    meta: {
      title: '邀请回答',
      pageCodes: {
        h5: 'P5CB38CB53A0D40',
      },
    },
  },
  {
    name: 'like',
    path: '/like',
    component: () => import('@H5Components/message/LikeList.vue'),
    meta: {
      title: '收到的赞',
      pageCodes: {
        h5: 'P5CB7413B1C1278',
      },
    },
  },
  {
    name: 'at',
    path: '/at',
    component: () => import('@H5Components/message/At.vue'),
    meta: {
      title: '@我',
      pageCodes: {
        h5: 'P5CB746D57E6CB9',
      },
    },
  },
  {
    name: 'system',
    path: '/system',
    component: () => import('@H5Components/message/SystemList.vue'),
    meta: {
      title: '系统通知',
      pageCodes: {
        h5: 'PC66CCA80DA1A11',
      },
    },
  },
  {
    name: 'chat',
    path: '/chat',
    component: () => import('@H5Components/message/Chat.vue'),
    meta: {
      title: '私信',
      pageCodes: {
        h5: 'PC66CC4B51E5826',
      },
    },
  },
  {
    name: 'beanrecord',
    path: '/beanrecord',
    component: () => import('@H5Components/bean/BeanRecord'),
    meta: {
      title: '智豆记录',
      pageCodes: {
        h5: 'P5CB74FCA581DF4',
      },
    },
  },
  {
    name: 'prizerecord',
    path: '/prizerecord',
    component: () => import('@H5Components/bean/PrizeRecord'),
    meta: {
      title: '兑换记录',
      pageCodes: {
        h5: 'P58565628012D33',
      },
    },
  },
  {
    name: 'groupIntro',
    path: '/groupIntro',
    component: () => import('@H5Components/group/groupIntro'),
    meta: {
      title: '简介',
      pageCodes: {
        h5: 'P5CB3A8D338E468',
      },
    },
  },
  {
    name: 'groupDetail',
    path: '/groupDetail',
    component: () => import('@/components/enH5/groupDetail/index'),
    meta: {
      title: '圈子详情页',
      pageCodes: {
        h5: 'P585A367D527C5E',
      },
    },
  },
  {
    name: 'completedInfo',
    path: '/completedInfo',
    component: () => import('@H5Components/completedInfo/index'),
    meta: {
      title: '完善信息用户',
      pageCodes: {
        h5: 'P5CB3B03C9D9D68',
      },
    },
  },
  {
    name: 'postDetail',
    path: '/postDetail',
    component: () => import('@H5Components/post/postDetail'),
    meta: {
      title: msgFromMiniOriginal ? '华为JDC' : '帖子详情页',
      pageCodes: {
        h5: 'P5E1D4CB85D0D9A',
      },
    },
  },
  {
    name: 'safetyTips',
    path: '/safetyTips',
    component: () => import('@H5Components/post/safetyTips'),
    meta: {
      title: '安全提示',
    },
  },
  {
    name: 'search',
    path: '/search',
    component: () => import('@H5Components/search/index'),
    meta: {
      title: '搜索',
      pageCodes: {
        h5: 'P5856562AE6A242',
      },
    },
  },
  {
    name: 'relatedUser',
    path: '/relatedUser',
    component: () => import('@H5Components/search/relatedUser'),
    meta: {
      title: '相关用户',
      pageCodes: {
        h5: 'P5CB3B6A4D14B2C',
      },
    },
  },
  {
    name: 'relatedCircle',
    path: '/relatedCircle',
    component: () => import('@H5Components/search/relatedCircle'),
    meta: {
      title: '相关圈子',
      pageCodes: {
        h5: 'P5CB76A310D4F55',
      },
    },
  },
  {
    name: 'report',
    path: '/report',
    component: () => import('@H5Components/report/index'),
    meta: {
      title: '举报',
      pageCodes: {
        h5: 'P5CB772786903D8',
      },
    },
  },
  {
    name: 'devices',
    path: '/devices',
    component: () => import('@H5Components/devices/index'),
    meta: {
      title: '登录设备管理',
      pageCodes: {
        h5: 'P5CB3C718B767B9',
      },
    },
  },
  {
    name: 'newThread',
    path: '/newThread',
    component: () => import('@H5Components/newThread/Index'),
    meta: {
      title: '发表帖子',
      pageCodes: {
        h5: 'P585656299BF00C',
      },
    },
  },
  {
    name: 'tradeRecord',
    path: '/tradeRecord',
    component: () => import('@H5Components/post/element/TradeRecord'),
    meta: {
      title: '礼品兑换记录',
      pageCodes: {
        h5: 'P5CB3D51012F950',
      },
    },
  },
  {
    name: 'qrCode',
    path: '/qrCode',
    component: () => import('@H5Components/QRCode/index'),
    meta: {
      title: '参会二维码',
      pageCodes: {
        h5: 'P5CB3DB1AC49A62',
      },
    },
  },
  {
    name: 'qrDetail',
    path: '/qrDetail',
    component: () => import('@H5Components/QRCode/qrDetail'),
    meta: {
      title: '参会二维码详情',
      pageCodes: {
        h5: 'P5CB3E0426E2F1E',
      },
    },
  },
  {
    name: 'optionFeedback',
    path: '/optionFeedback',
    component: () => import('@H5Components/systemsetting/opinionFeedback'),
    meta: {
      title: '意见反馈',
      pageCodes: {
        h5: 'P5CB3E513F46475',
      },
    },
  },
  {
    name: 'pushNotificationSetting',
    path: '/pushNotificationSetting',
    component: () => import('@H5Components/systemsetting/pushNotificationSetting'),
    meta: {
      title: '推送通知设置',
      pageCodes: {
        h5: 'P68B0B38F0F2618',
      },
    },
  },
  {
    name: 'privacySecurity',
    path: '/privacySecurity',
    component: () => import('@H5Components/systemsetting/PrivacySecurity'),
    meta: {
      title: '隐私设置',
      pageCodes: {
        h5: 'P6A804F3976653C',
      },
    },
  },
  {
    name: 'signIn',
    path: '/signIn',
    component: () => import('@H5Components/individualCenter/signIn.vue'),
    meta: {
      title: '签到',
      pageCodes: {
        h5: 'P5CB3EB2ECB86DA',
      },
    },
  },
  {
    name: 'myCollection',
    path: '/myCollection',
    component: () => import('@H5Components/individualCenter/myCollection.vue'),
    meta: {
      title: '我的收藏',
      pageCodes: {
        h5: 'P5CB3F0E21FD40A',
      },
    },
  },
  {
    name: 'loginWithCode',
    path: '/loginWithCode',
    component: () => import('@H5Components/loginWithCode/index.vue'),
    meta: {
      title: '登录中',
      shouldNotLogin: true,
      pageCodes: {
        h5: 'P5CB3FBCE6062C0',
      },
    },
  },
  {
    name: 'userAgreement',
    path: '/userAgreement',
    component: () => import('@H5Components/agreementAndPrivacy/UserAgreementInfo.vue'),
    meta: {
      title: '用户协议',
      shouldNotLogin: true,
      pageCodes: {
        h5: 'P5CB40072DE3C0B',
      },
    },
  },
  {
    name: 'privacy',
    path: '/privacy',
    component: () => import('@H5Components/agreementAndPrivacy/PrivacyStatementInfo.vue'),
    meta: {
      title: '隐私声明',
      shouldNotLogin: true,
      pageCodes: {
        h5: 'P5CB4058C6927FA',
      },
    },
  },
  {
    name: 'personalData',
    path: '/personalData',
    component: () => import('@H5Components/personalData/HomeData.vue'),
    meta: {
      title: '个人资料',
      pageCodes: {
        h5: 'PC66CBF7930CABB',
      },
    },
  },
  {
    name: 'nickName',
    path: '/nickName',
    component: () => import('@H5Components/personalData/NickNameInfo.vue'),
    meta: {
      title: '昵称',
      pageCodes: {
        h5: 'P5CB7BEAB640DEF',
      },
    },
  },
  {
    name: 'personalSign',
    path: '/personalSign',
    component: () => import('@H5Components/personalData/PersonalSignInfo.vue'),
    meta: {
      title: '个性签名',
      pageCodes: {
        h5: 'P585656284B03B4',
      },
    },
  },
  {
    name: 'realName',
    path: '/realName',
    component: () => import('@H5Components/personalData/CommonEditInfo.vue'),
    meta: {
      title: '真实姓名',
      pageCodes: {
        h5: 'P5CB4158D19BCB2',
      },
    },
  },
  {
    name: 'department',
    path: '/department',
    component: () => import('@H5Components/personalData/CommonEditInfo.vue'),
    meta: {
      title: '部门',
      pageCodes: {
        h5: 'P5CB41CCB8EA65B',
      },
    },
  },
  {
    name: 'industry',
    path: '/industry',
    component: () => import('@H5Components/personalData/IndustryInfo.vue'),
    meta: {
      title: '行业',
      pageCodes: {
        h5: 'P5CB423063E44BE',
      },
    },
  },
  {
    name: 'company',
    path: '/company',
    component: () => import('@H5Components/personalData/CompanyInfo.vue'),
    meta: {
      title: '公司',
      pageCodes: {
        h5: 'P5CB7D59E2C49D1',
      },
    },
  },
  {
    name: 'email',
    path: '/email',
    component: () => import('@H5Components/personalData/EmailInfo.vue'),
    meta: {
      title: '邮箱',
      pageCodes: {
        h5: 'P585656292D407A',
      },
    },
  },
  {
    name: 'phoneNumber',
    path: '/phoneNumber',
    component: () => import('@H5Components/personalData/PhoneNumberInfo.vue'),
    meta: {
      title: '手机',
      pageCodes: {
        h5: 'P5856562A08A6A3',
      },
    },
  },
  {
    name: 'addCircle',
    path: '/addCircle',
    component: () => import('@H5Components/addCircle/index.vue'),
    meta: {
      title: '邀请码',
      pageCodes: {
        h5: 'P5CB42FF089B67A',
      },
    },
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('@H5Components/login/index.vue'),
    meta: {
      title: '华为JDC',
      shouldNotLogin: true, // 不需要登录
      pageCodes: {
        h5: 'P58565626E1E874',
      },
    },
  },
  {
    name: 'download',
    path: '/download',
    component: () => import('@H5Components/download/index.vue'),
    meta: {
      title: '华为JDC',
      shouldNotLogin: true, // 不需要登录
      pageCodes: {
        h5: 'P585656265D33EA',
      },
    },
  },
  {
    name: 'jump',
    path: '/jump',
    component: () => componentJump,
    meta: jumpMeta,
  },
  {
    name: 'jump',
    path: '/j/:id',
    component: () => componentJump,
    meta: jumpMeta,
  },
  {
    name: 'lottery',
    path: '/lottery/:id',
    component: () => import('@H5Components/lottery/index.vue'),
    meta: {
      title: '华为JDC',
      pageCodes: {
        h5: 'P1BD9F138C8683C',
      },
    },
  },
  {
    name: 'myPost',
    path: '/myPost',
    component: () => import('@H5Components/personalCenter/MyPost.vue'),
    meta: {
      title: '我的帖子',
      pageCodes: {
        h5: 'PC66CCC8EE961DA',
      },
    },
  },
  {
    name: 'myFocus',
    path: '/myFocus',
    component: () => import('@H5Components/personalCenter/MyFocusFans.vue'),
    meta: {
      title: '我的关注',
      pageCodes: {
        h5: 'P5CB7E36E488391',
      },
    },
  },
  {
    name: 'myFans',
    path: '/myFans',
    component: () => import('@H5Components/personalCenter/MyFocusFans.vue'),
    meta: {
      title: '我的粉丝',
      pageCodes: {
        h5: 'P5CB7EA0045C34B',
      },
    },
  },
  {
    name: 'dailyTasks',
    path: '/dailyTasks',
    component: () => import('@H5Components/personalCenter/DailyTasks.vue'),
    meta: {
      title: '每日任务',
      pageCodes: {
        h5: 'P5CB441DACC7912',
      },
    },
  },
  {
    name: 'hisHomePage',
    path: '/hisHomePage',
    component: () => import('@H5Components/hisCenter/HisHomePage.vue'),
    meta: {
      title: 'Ta的主页',
      pageCodes: {
        h5: 'P5CB7F4B5D5AF43',
      },
    },
  },
  {
    name: 'selectUser',
    path: '/selectUser',
    component: () => import('@H5Components/selectUser/SelectUser.vue'),
    meta: {
      title: '选择用户',
      pageCodes: {
        h5: 'P5CB7FDA5DBE8A5',
      },
    },
  },
  {
    name: 'exclusive',
    path: '/exclusive',
    component: () => import('@H5Components/bean/exclusive'),
    meta: {
      title: '新手专属',
      pageCodes: {
        h5: 'P78BD03191476E0',
      },
    },
  },
  {
    name: 'medalList',
    path: '/medalList',
    component: () => import('@H5Components/medalList/MedalList'),
    meta: {
      title: '勋章墙',
      pageCodes: {
        h5: 'P5C9FBA45F63DA4',
      },
    },
  },
  {
    name: 'medalManage',
    path: '/medalManage',
    component: () => import('@H5Components/medalManage/MedalManage'),
    meta: {
      title: '勋章管理',
      pageCodes: {
        h5: 'P86AC7C1CEF02AC',
      },
    },
  },
  {
    name: 'medalDetail',
    path: '/medalDetail',
    component: () => import('@H5Components/medalDetail/MedalDetail'),
    meta: {
      title: '勋章详情',
      pageCodes: {
        h5: 'P290D2ADA2BA8C2',
      },
    },
  },
  {
    name: 'sdkList',
    path: '/sdkList',
    component: () => import('@/components/h5/agreementAndPrivacy/SDKList.vue'),
    meta: {
      title: 'SDK列表页',
      pageCodes: {
        h5: 'PF1D116218DBC9',
      },
    },
  },
  // 未匹配的路由
  {
    path: '/:catchAll(.*)',
    redirect: '/home?type=recommend',
  },
];
// h5 router
const router = createRouter({
  history: createWebHistory('/h5'),
  routes: IS_EN_JDC ? enRoutes : routes,
});

/**
 * @description: 校验登录
 * @param shouldNotLogin boolean
 * @return boolean true 不需要进行登录 false 需要登录
 * */
const checkLogin = shouldNotLogin => {
  if (shouldNotLogin) {
    return true;
  }
  const uid = store.state.h5.userUid;
  return !!uid;
};

router.beforeEach((to, from, next) => {
  const meta = to.meta;
  document.title = meta.title;

  // 储存容器标识
  const container = to.query.container;
  if (container) {
    store.commit(types.SET_CONTAINER, container);
  }

  if (checkLogin(meta.shouldNotLogin)) {
    next();
    return;
  }

  store.dispatch(types.GET_USERLOGIN).then(() => {
    next();
  });
});
router.afterEach(() => {
  // 路由跳转后调用原生关闭Loading
  APPUtil.goToMobile('hideLoading');
});

const isAndroid = window.navigator.userAgent.indexOf('androidNative') > -1;
const isIOS = window.navigator.userAgent.indexOf('iosNative') > -1;

const getCodeByClient = (pageCodes = {}) => {
  if (isAndroid || isIOS) {
    return [];
  } else {
    return [pageCodes.h5];
  }
};

// 接口埋码
const updateUemData = (type, pageCode) => {
  const params = {
    type,
    pageCode,
    pagePath: window.location.href,
  };
  axios.post(api.post.updateUemData, params);
};

router.afterEach(to => {
  if (isAndroid) {
    updateUemData('android', 'P5CE5B7E9253FB5');
    return;
  }
  if (isIOS) {
    updateUemData('ios', 'P5CE1DC29953B86');
    return;
  }
  getCodeByClient(to.meta.pageCodes).forEach(code => {
    if (isBuriedPageCode()) {
      window.hwa('trackPageView', code);
    }
  });
});

export default router;
