/*
 * @Date: 2024-03-30
 * @Description: 圈子资源文件
 */
export default {
  myJoinedGroups: 'My Groups',
  joined: 'Joined',
  join: 'Join',
  apply: 'Apply',
  underReview: 'Under review',
  createGroup: 'Create Group',
  noDataAvailable: 'No data available.',
  verifyAccount: 'Verify Now',
  applyGroup: 'Apply for {0} group',
  applyForGroup: 'Apply for "{groupName}" group',
  mailNeedsVerifying: 'Sorry, but you must verify your <strong>enterprise email address</strong> before joining the group.',
  mailNotHuawei: 'Sorry, but your enterprise email address is not <strong>with a customer of Huawei.</strong> Modify the email address and try again.',
  verifyNow: 'Verify Now',
  contactAdministrator: 'Contact Administrator',
  enterYourReason: 'Required.',
  groupNeedsReasonToApproval: 'Enter your reason for joining this group. Your request needs to be approved by the administrator. ',
  reason: 'Reason',
  recommendedBy: 'Recommended by',
  huaweiJDCGroupsTitle: 'Huawei JDC Groups–Digital Transformation_Products and Solutions_Innovative Technologies_Cloud Computing and IoT–Huawei JDC',
  huaweiJDCGroupsIndustry:
    'Huawei JDC, Finance, Transportation, Electric power, Manufacturing, Education, Carrier, Healthcare, Petro, Digital energy, Data center, Smart mining, Agriculture, PV',
  huaweiJDCDescription:
    'Huawei JDC, Digital transformation, Finance, Transportation, Electric power, Manufacturing, Education, Carrier, Healthcare, Petro. A technical knowledge sharing community that gathers practitioners from fields such as digital energy, data center, smart mining, agriculture, and PV.',
  recentVisits: 'Recent Visits',
  recommendedGroups: 'Recommended Groups',
  groupNeedsInvitation: 'This is an invite only group.',
  accessDenied: 'Access denied. Redirecting to the home page in 5 seconds…',
  clickHereToRedirect: 'Click here if your browser does not automatically redirect you.',
  huaweiJDCCommunity: 'Huawei JDC–Joint Definition Community Huawei JDC–Joint Definition Community',
  groupName: 'Group Name',
  groupDescription: 'Group Description',
  allowedCharacters: 'Letters, digits, and the following special characters are allowed: ( ) + - _ / & |',
  publicGroup: 'Public',
  public: 'Public',
  privateGroup: 'Private',
  private: 'Private',
  members: 'Members',
  member: 'Members',
  topics: 'Topics',
  replies: 'Replies',
  pointsDistributedToday: 'Points distributed today',
  manage: 'Manage',
  manageGroup: 'Setting',
  editIntroduction: 'Edit Introduction',
  introductionNotEmpty: 'Forum Introduction Can Not Be Empty!',
  inviteUsers: 'Invite users',
  scanToJoinGroup: 'Scan to join group',
  logInFirst: 'Log in to perform this operation.',
  leave: 'Leave',
  pending: 'Pending',
  joinGroup: 'Join',
  groupNeedsApproval: 'This is an invite only group.',
  InviteUsersToJoin: 'Invite Users to Join {0}',
  InviteRegisteredUsers: 'Invite Registered Users',
  plsSelect: 'Select',
  selected: 'selected',
  sendInvitation: 'Send Invitation',
  byNickname: 'By nickname',
  add: 'Add',
  inviteUsersTips:
    '1. You can invite users in this mode only if they have registered with the community or Huawei Support website.\n2. The system will automatically display user nicknames when you enter at least two characters of a nickname, so that you can select the target user to add.\n3. You may experience a slower response time due to variations in network connectivity."',
  inviteUsersTipsNotice: 'Note: After you send an invitation, the invitees will be added to the group automatically and receive a notification.',
  added: 'Added',
  type: 'Type:',
  topic: 'Topic',
  category: 'Category',
  allTime: 'All Time',
  lastDay: 'Last Day',
  last2Days: 'Last 2 Days',
  lastWeek: 'Last Week',
  lastMonth: 'Last Month',
  last3Months: 'Last 3 Months',
  sortBy: 'Sort By',
  lastPosted: 'Last Posted',
  mostReplied: 'Most Replied',
  mostViewed: 'Most Viewed',
  lastReplied: 'Last Replied',
  allPosts: 'All Posts',
  myPosts: 'My Posts',
  myReplies: 'My Replies',
  unmarked: 'Unmarked',
  unreplied: 'Unreplied',
  unprocessed: 'Unprocessed',
  processed: 'Processed',
  expired: 'Expired',
  valueless: 'Valueless',
  markAsValueless: 'Mark as valueless',
  toBeClarified: 'To be Clarified',
  valuableNotAccepted: 'Valuable Not accepted',
  enterKeyword: 'Enter a keyword.',
  visibleToAuthorized: 'Visible to authorized users',
  recommended: 'Recommended',
  latest: 'Latest',
  featured: 'Featured',
  stickyThread: 'Sticky Thread',
  hot: 'Hot',
  rewardedWithPoints: 'Rewarded with points',
  reposted: 'Reposted',
  attachmentsContained: 'Attachments contained',
  picturesAttached: 'Pictures Attached',
  videosAttached: 'Videos Attached',
  locked: 'Locked',
  submittedAsRR: 'Submitted as RR',
  submitAsRR: 'Submit as RR',
  repliesOnlyToUsers1: 'Replies visible only to authors/administrators/authorized experts',
  repliesOnlyToUsers2: 'Replies visible only to authors/administrators',
  huaweiEmployee: 'Huawei employee',
  administrator: 'Administrator',
  moderator: 'Moderator',
  groupOwner: 'Group owner',
  postedThisOn: 'Posted {postedTime}',
  lastRepliedTime: 'Last Replied {repliedTime}',
  toViewThisPage: 'To view this page, click',
  logIn: 'Log In',
  signUp: 'Sign Up',
  logInToViewMore: 'To explore more, click ',
  toJoinGroup: ' to join groups where you can share ideas with the like-minded.',
  all: 'All',
  top: 'Top',
  more: 'More',
  provideProductSuggestions: 'Provide Product Suggestions',
  createPost: 'Create Post',
  todaysNewPosts: "Today's New Posts:",
  todaysReplies: "Today's Replies:",
  todaysNewPostsNoSymbol: "Today's New Posts",
  todaysRepliesNoSymbol: "Today's Replies",
  leaderboard: 'Leaderboard',
  myGroups: 'My Groups',
  showLess: 'Show Less',
  showMore: 'Show More',
  posts: 'Posts',
  post: 'Posts',
  none: 'None',
  onDutyModerator: 'On-Duty Moderator',
  points: 'Points',
  selectGroupJoin: 'Select a group to join',
  recommendedGroupJoin: 'You are recommended to join a group that you may like. Select a group to join and check it out.',
  nextTime: 'Next Time',
  joinNow: 'Join Now',
  batchJoinGroup: 'Batch-Joining Group',
  exitGroupHint: 'Are you sure to exit the group?',
  exitGroupSuccess: 'You have exited the group successfully.',
  privacyStateTitle: '',
  privacyStateKeywords: '',
  privacyStateDescription: '',
  enterReason: 'Enter your reason',
  enterOptional: 'Enter (Optional)',
  joinPrivateGroupNote: 'You need to apply for joining the private group. After the application is approved, you can join the group to communicate.',
  inviteUsersDisaboleTip: 'You need to join the group in order to invite users.',
  createPostDisaboleTip: 'You need to join the group in order to creat a post.',
  pendingTip: "Your group request has been submitted. Please wait for the group owner's approval.",
  enterpriseAddress: 'Sorry, but you must verify your <strong>enterprise email address</strong> before joining the group.',
  verifyAccountFirst: 'Please verify Account first.',
};
