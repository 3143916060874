// 帖子详情页中文词条

export default {
  jdc: '华为JDC',
  ok: '确定',
  submit: '提交',
  cancel: '取消',
  yes: '是',
  noSimple: '否',
  post: '回复',
  group: '圈子',
  section: '板块',
  groupU: '圈子',
  sectionU: '板块',
  private: '私密',
  public: '公开',
  uncategorized: '未分类',
  sortBy: '',
  recommendations: '按推荐',
  byTime: '按时间',
  copyLink: '复制链接',
  publishedIn: '发表于',
  fromBrowser: '来自浏览器',
  fromAndroid: '来自 Android',
  fromIOS: '来自 IOS',
  from: '来自',
  showAllComments: '显示全部楼层',
  showAuthorComments: '只看该作者',
  threadStarter: '楼主',
  author: '作者',
  warned: '受到警告',
  warningHistory: '警告记录',
  recommend: '推荐',
  secondFloor: '1 #',
  thirdFloor: '2 #',
  fourthFloor: '3 #',
  copied: '链接复制成功',
  agree: '赞同',
  disagree: '不赞同',
  viewPostOperationHistory: '帖子模式',
  downloads: '下载{count}次',
  pendingReview: '审核中',
  redemptionHistory: '兑换记录',
  nickname: '用户昵称',
  onlyNickname: '昵称',
  redeemed: '兑换时间',
  pointsNum: '智豆数',
  status: '兑换状态',
  operation: '操作',
  system: '任务系统',
  failed: '失败',
  successful: '成功',
  returned: '已退还',
  operationRecords: '主题操作记录',
  noRedemptions: '暂无兑换记录，快去参与兑换吧',
  operatedBy: '操作人',
  operator: '操作者',
  operated: '操作时间',
  operatedTime: '时间',
  close: '关闭',
  return: '确认退还',
  returnPoints: '将退还{user} {count}智豆，该操作不可逆',
  confirmReturn: '确认退还吗？',
  returnedSuccess: '退还成功',
  reward: '赞赏',
  withdrawReward: '撤销赞赏',
  participants: '参与人数',
  point: '智豆',
  bonus: '积分',
  reason: '理由',
  showLess: '收起',
  showMore: '展开',
  showAll: '查看全部赞赏',
  username: '用户名',
  time: '时间',
  totalPoints: '总计: 智豆{count}',
  suspiciousPost: '可疑帖子',
  duplicatePost: '本帖可能重复！',
  similarity: '相似度：',
  comparedWithPost: '对比帖：',
  handleSuggestion: '处理产品建议',
  forward: '转交处理',
  inviteToReply: '邀请互动',
  favorite: '收藏',
  share: '分享',
  repost: '转载',
  postRepost: '主题转载',
  repostTips: '如帖子要转载到公开圈，请注意信息安全。',
  selectModule: '选择模块：',
  selectCategorize: '选择分类：',
  selected: '已选择：',
  add: '添加',
  remove: '移除',
  repostSuccess: '转载成功',
  likesNum: '{count}人赞',
  like: '点赞 | 点赞 {count} | 点赞 {count}',
  favorited: '收藏成功',
  unfavorited: '已取消收藏',
  goTo: '电梯直达',
  jumpTo: '跳转到指定楼层',
  reply: '回复',
  postReply: '发表回复',
  quickReply: '快速回复',
  contentSecurityTips:
    '内容安全提示：尊敬的用户您好，为了保障您、社区及第三方的合法权益，请勿发布可能给各方带来法律风险的内容，包括但不限于政治敏感内容，涉黄赌毒内容，泄露、侵犯他人商业秘密的内容，侵犯他人商标、版本、专利等知识产权的内容，侵犯个人隐私的内容等。也请勿向他人共享您的账号及密码，通过您的账号执行的所有操作，将视同您本人的行为，由您本人承担操作后果。详情请参看',
  userAgreement: '用户协议',
  termsOfUse: '用户协议',
  privacyStatement: '隐私声明',
  contentSecurityTipsPost:
    '尊敬的用户您好，为了保障您、社区及第三方的合法权益，请勿发布可能给各方带来法律风险的内容，包括但不限于政治敏感内容，涉黄赌毒内容，泄露、侵犯他人商业秘密的内容，侵犯他人商标、版本、专利等知识产权的内容，侵犯个人隐私的内容等。也请勿向他人共享您的账号及密码，通过您的账号执行的所有操作，将视同您本人的行为，由您本人承担操作后果。详情请参看“',
  note: '内容安全提示：',
  and: '”和“',
  information: '提示信息',
  notEnteredContent: '抱歉，您尚未输入标题或内容',
  congratulations: '哇哦，恭喜获得抽奖机会',
  goDrawNow: '立即抽奖',
  comment: '回复',
  replyPost: '回复',
  replyComment: '回复',
  replyTo: '回复',
  quote: '引用',
  edit: '编辑',
  report: '举报',
  selectedPost: '选中',
  Post: '篇',
  cancelWarning: '解除警告',
  warning: '警告',
  block: '屏蔽',
  deletePostOpr: '删除主题',
  deleteArticle: '删除文章',
  delete: '删除',
  pinToTop: '置顶',
  manage: '管理',
  showMoreComment: '还有{count}条点评',
  clickToView: '点击查看',
  commentTo: '评论',
  moreCharacterAllowed: '还可输入{count}个字符',
  violationMarksForPosts: '用户 {user} 帖子已被违规登记',
  violationMark: '违规登记',
  notifyAuthor: '通知作者',
  operationReason: '操作原因',
  notJoinedTips: '你还没有加入圈子,不能进行此操作',
  blockReply: '屏蔽回复操作',
  deleteReply: '删除回复',
  visibleOnly: '该主题已设置仅管理员和授权专家可见',
  rewardAt: '赞赏(当前操作: {count}楼)',
  rewardToUserTips: '温馨提示：智豆价值较高，可兑换实物礼品，请按赞赏规则或参考括号内建议进行赞赏！',
  pointsAllowed: '赞赏区间',
  pointsInput: '赞赏失败，请输入赞赏区间的整数数字',
  pointsExceeds: '赞赏失败，当日发放智豆数已超过限制',
  remainingToday: '今日剩余',
  reasonForReward: '赞赏理由',
  withdrawRewardAt: '撤销赞赏(当前操作{count}楼)',
  operationDescription: '操作说明：',
  deletePost: '您确认要删除选择的主题么？',
  deleteComment: '您确认要删除选择的评论么？',
  validityPeriod: '有效期',
  longPermanent: '长期有效',
  highlight: '高亮',
  bold: '文字加粗',
  italic: '文字斜体',
  underline: '文字加下划线',
  background: '背景色',
  selectBadge: '选择主题图章',
  pleaseSelect: '请选择',
  pleaseInput: '请输入',
  noBadge: '无图章',
  badge: '图章',
  groupNoSettingTips: '当前圈子{groupName}无分类设置，请到圈子设置页设置主题分类',
  postBlocksOfUser: '用户{user}帖子已被屏蔽',
  userWarns: '用户{user}已被警告',
  times: '次',
  whoCanSeePost: '帖子访问权限',
  onlyAuthorAndHuaweiEmployees: '仅作者和华为员工可见',
  whoCanSeeReplies: '回帖可见权限',
  whoCanSeeContents: '内容可见权限',
  everyone: '所有人',
  onlyAuthorAndAdministrators: '仅作者和管理员可见',
  commasSeparate: '用逗号隔开多个标签，最多可填写 5 个',
  recentTags: '最近使用标签',
  targetSection: '目标版块',
  targetCategory: '目标分类',
  pinToTopOfComments: '置顶到主题',
  unpinFromTop: '解除置顶',
  deleteThisReply: '您确认要删除选择的回复么？',
  deleteThisPoat: '您确认要删除选择的主题么？',
  deleteThisArticle: '您确认要删除选择的文章么？',
  banned: '禁言天数',
  title: '标题',
  upToAllowed: '最多添加5个标签',
  enterCharactersTips: '请输入中文、字母、数字或特殊字符(-_)，长度2-36位',
  operationReasonRequired: '操作原因必填',
  today: '今天',
  oneDay: '一天',
  oneWeek: '一周',
  twoWeeks: '两周',
  oneMonth: '一个月',
  twoMonths: '两个月',
  threeMonths: '三个月',
  unhandled: '未处理',
  handled: '已处理',
  noValue: '无价值',
  toBeReplied: '待回复',
  expired: '已过期',
  setBanDurationTips: '禁言天数必选',
  none: '无',
  pinToTopOfSection: '本版置顶',
  pinToTopGlobally: '全局置顶',
  selectValidityPeriod: '抱歉，有效期无效，有效期应该介于{count1}至{count2}之间',
  permanent: '永久',
  threeDays: '三天',
  fiveDays: '五天',
  sixMonths: '半年',
  oneYear: '一年',
  knowledgeTalent: '知识达人',
  technicalExpert: '技术大牛',
  industryLeader: '行业领袖',
  someHoursAgo: '{count} 小时前',
  halfAnHourAgo: '半小时前',
  someMinutesAgo: '{count} 分钟前',
  someSecondsAgo: '{count} 秒前',
  justNow: '刚刚',
  yesterday: '昨天',
  twoDaysAgo: '前天',
  someDaysAgo: '{count} 天前',
  someWeeksAgo: '{count} 周前',
  undoRepost: '取消转载',
  viewOriginalPost: '访问原帖',
  undoRepostReq: '是否要取消转载？',
  open: '打开',
  categorize: '分类',
  move: '移动',
  export: '导出',
  posts: '帖子',
  followers: '粉丝',
  follow: '关注作者',
  message: '发私信',
  unfollowUserReq: 'Unfollow this user?',
  thinkAgain: '我再想想',
  unfollow: '取消关注',
  noMatchingResults: '抱歉，没有找到匹配结果',
  selectUsersToMention: '选择提醒的人',
  recentContacts: '最近联系',
  back: '点击这里返回上一页',
  emailSharing: '邮件分享',
  enterEmailTip: '请输入邮箱地址，多个邮箱请用半角分号（;）分割。',
  enterEmail: '请输入内容',
  leftEmails: '您还能输入{count}个邮箱',
  mostEmails: '邮箱个数不能超过100个',
  sharingDescription: '分享说明:',
  enterDescription: '请输入内容',
  sharedNum: '已有 {count} 人分享',
  simultaneousReply: '同时回复',
  notAllowRepost: '该主题不允许转载',
  notGroupsRepost: '非圈内成员不能转载',
  likedsuccessfully: '点赞成功',
  cancelLikedsuccessfully: '已取消点赞',
  likedUnsuccessful: '点赞失败',
  cancelLikedUnsuccessful: '取消点赞失败，请重试',
  scorecard: '评分表立场',
  shareEssence: '分享推精华',
  featured: '精华',
  cancelFeatured: '解除',
  fieldsOfExpertise: '擅长领域',
  saySomething: '说点什么...',
  processingAction: '处理动作',
  replyContent: '回复用户文案',
  support: '（支持自行编写）',
  pleaseEnter: '请选择/输入回复内容',
  failedTemplate: '获取模板失败，请稍后再试',
  selectingMembers: '选择成员',
  reasonTransfer: '转交原因',
  transfer: '转交',
  employeeID: '请输入一个华为员工工号',
  selectExpert: '请选择处理专家',
  transferSucceeded: '转交成功',
  notExist: '不存在',
  submitRrTips: '价值需求请记得给用户发放智豆',
  rewardNow: '马上评分',
  later: '稍后再说',
  submitRrFloor: '(当前操作: {number}楼)',
  conversionRecord: '需求转化记录',
  submitNum: '提交次数',
  submitTime: '提交时间',
  requirementNo: '需求编号',
  requirementStatus: '状态',
  submitTitle: '提交成功',
  gotIt: '已获取',
  replyMessage: '感谢您的回复!',
  effortMessage: '您的努力将帮助我们定义下一代产品。',
  prizeMessage: '这个话题将于下个月结束。如果您获奖，我们将通过电子邮件通知您。请留意您的邮箱。',
  selectContact: '输入昵称或选择最近联系的人',
  unansweredTips: '至少回答一道题目才能提交问卷',
  congratulation: '恭喜',
  giftNotice: '您赢得了一份礼物！如需领取，只需联系我们的现场工作人员即可。请注意，礼品只能在现场兑换，离开展馆后此优惠无效。',
  giftNotice1: '您赢得了一份礼物！',
  giftNotice2: '如需领取，只需联系我们的现场工作人员即可。',
  giftNotice3: '请注意，礼品只能在现场兑换，',
  giftNotice4: '离开展馆后此优惠无效。',
  lockNotice: '一旦解锁，礼物将被视为已领取。',
  chanceNotice: '您已获得一次幸运抽奖机会。',
  chanceNoticeH5: '恭喜！您已获得一次幸运抽奖机会。',
  doDraw: '立即抽奖',
  SendNotifyAuthor: '通知作者',
  btnOk: '确定',
  rewardedSuccessful: '赞赏成功！',
  noRecentContacts: '暂无最近联系人',
  noMatchesFound: '暂无匹配的用户',
  searching: '搜索中...',
  selectUsers: '选择用户',
  enterNickname: '请输入昵称',
  selectToMention: '选择提醒的人',
};
