import axios from '@/axios/h5';
import h5Api from '@/axios/h5Api';
import env from '@/config/env.js';
import errCode from '@/utils/common/errCode.js';
import Html from '@/utils/common/HtmlUtils.js';

/**
 * @description 批量获取图片成功处理
 * @param data 返回的数据
 * @param imgMap 图片映射
 * @param list 图片数组
 * @param imgField 图片字段
 */
function imgSuccessHandler(data, imgMap, list, imgField) {
  data.forEach(item => {
    const idxArr = imgMap[item.key];
    if (Array.isArray(idxArr)) {
      idxArr.forEach(idx => {
        list[idx][imgField] = Html.handleImage(item.base64Image);
      });
    }
  });
}

export default {
  /**
   * 批量根据uuid获取base64图片
   * 最多获取10张图片的处理结果
   * @param list 图片数组
   * @param imgField 图片字段
   */
  async handleMultiBase64Img(list, imgField) {
    const imgMap = {};
    const params = [];
    list.forEach((item, idx) => {
      const key = item[imgField];
      // 空的key会报参数错误，这里做一下判断过滤
      if (key) {
        if (!imgMap[key]) {
          imgMap[key] = [];
        }
        item[imgField] = '';
        imgMap[key].push(idx);
        params.push(key);
      }
    });
    // 如果本次请求没有图片，则不请求
    if (!params || params.length <= 0) {
      return list;
    }
    const url = h5Api.user.downloadImages;
    const { code, data } = await axios.post(url, params, { baseURL: env.newApiBaseURL }).catch(() => ({}));
    if (Array.isArray(data) && code === errCode.success) {
      imgSuccessHandler(data, imgMap, list, imgField);
    }
  },
  /**
   * 批量处理list中的图片字段，接口限制每次最多10张，如果超出就需要分片
   * @param list 图片数组
   * @param imgField 图片字段
   */
  async batchTransferBase64Img(list, imgField) {
    if (list.length <= 10) {
      await this.handleMultiBase64Img(list, imgField);
      return;
    }
    const promiseArr = [];
    const count = Math.ceil(list.length / 10);
    const CHUNK_SIZE = 10;
    for (let i = 0; i < count; i++) {
      const sliceList = list.slice(i * CHUNK_SIZE, (i + 1) * CHUNK_SIZE);
      promiseArr.push(this.handleMultiBase64Img(sliceList, imgField));
    }
    await Promise.all(promiseArr);
  },
  /**
   * 将图片uuid转为base64编码
   * @param uuid 图片uuid
   * @returns {string}
   */
  async transferBase64Img(uuid) {
    if (!uuid) {
      return;
    }
    const url = h5Api.user.downloadImage;
    const params = {
      key: uuid,
    };
    const res = await axios.get(url, { params, baseURL: env.newApiBaseURL }).catch(() => '');
    return res ? Html.handleImage(res) : '';
  },
  /**
   * 根据uid获取头像加载地址
   * @param uid 用户id
   * @param type {string} 图片尺寸，不传默认中等尺寸。LARGE_AVATAR 0，MIDDLE_AVATAR 1，SMALL_AVATAR 2
   * */
  getBase64AvatarFree(uid, type = '') {
    let typeParams = '';
    if (type) {
      typeParams = `&type=${type}`;
    }
    return uid ? `${env.newApiBaseURL}${h5Api.user.getBase64AvatarFree}?uid=${uid}${typeParams}` : '';
  },
  /**
   * 获取图片文件流
   * @param {object} item
   * @param {string} imgField
   */
  getImgBlob(item, imgField) {
    if (!item || !item[imgField]) {
      return;
    }
    const apiUrl = h5Api.user.attachmentDownload;
    const params = { id: item[imgField], isCommon: true };
    item[imgField] = '';
    axios.get(apiUrl, { params, baseURL: env.newApiBaseURL, responseType: 'blob' }).then(({ data }) => {
      const blob = new Blob([data]);
      item[imgField] = window.URL.createObjectURL(blob);
    });
  },
  /**
   * 根据图片id下载
   * @param {string} id 图片id
   * @param {boolean} isUpload 是否是运营后台上传的图片
   * */
  getImgUrlById(id, isUpload = false) {
    if (!id) {
      return '';
    }
    let paramString = `id=${encodeURIComponent(id)}`;
    if (isUpload) {
      paramString += '&isCommon=true';
    }
    return `${env.newApiBaseURL}${h5Api.user.attachmentDownload}?${paramString}`;
  },
};
