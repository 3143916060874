import * as types from '../mutation-types';

// state
const state = {
  fullbgMode: false,
  updatedInfo: false,
  WEChatPageCode: {
    page: '',
    params: {},
  },
  toBottomBtnChange: false,
};

// getters
const getters = {};

// mutations
const mutations = {
  [types.COMMON_FULLBG_MODE_SET](state, fullbgMode) {
    state.fullbgMode = fullbgMode;
  },
  [types.UPDATED_INFO](state, data) {
    state.updatedInfo = data;
  },
  [types.COMMON_WECHAT_PAGE_CODE](state, { page, params }) {
    state.WEChatPageCode = { page, params };
  },
  [types.PAGE_TO_BOTTOM_BTN_CHANGE](state, data) {
    state.toBottomBtnChange = data;
  },
};

// actions
const actions = {
  refreshFullBgMode: ({ commit }, object) => commit(types.COMMON_FULLBG_MODE_SET, object),
  updateWeChatPage: ({ commit }, { page, params }) => {
    commit(types.COMMON_WECHAT_PAGE_CODE, { page, params });
  },
  updatePageBottomBtn: ({ commit }, data) => commit(types.PAGE_TO_BOTTOM_BTN_CHANGE, data),
};

export default {
  state,
  getters,
  mutations,
  actions,
};
