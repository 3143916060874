const string = {
  name: 'String',
  /**
   * 将传入的值转换成字符串
   *
   * @param {any} val
   * @returns
   */
  toString(val) {
    if (val === null) {
      return '';
    } else {
      return typeof val === 'object' ? JSON.stringify(val, null, 2) : String(val);
    }
  },

  /**
   * 判断字符串是否为空
   * @param {any} val
   */
  isEmpty(val) {
    if (!val) {
      return true;
    }
    const regu = '[ ]+$';
    const re = new RegExp(regu);
    return re.test(val);
  },
  /**
   * 将字符串空格替换为'-'
   * @param {any} val
   */
  formatSpace(str) {
    if (str) {
      const emptyReg = /(^\s*)|(\s*$)/g; // 去除头尾空格
      const reg = /[^\u4e00-\u9fa5a-zA-Z0-9]/g; // 特殊字符全部替换为-
      const regRepeat = /(-)+/g; // 对-去重，多个只保留一个
      const str1 = str.replace(emptyReg, '');
      const str2 = str1.replace(reg, '-');
      return str2.replace(regRepeat, '-');
    }
  },
  // 深拷贝
  deepClone(obj) {
    if (!obj) {
      return false;
    }
    const _obj = JSON.stringify(obj);
    const objClone = JSON.parse(_obj);
    return objClone;
  },
  // 把对象里为只为null和空的去掉
  removeNullItem(option) {
    if (!option) {
      return {};
    }
    for (const attr in option) {
      if (option[attr] === null || option[attr] === '') {
        delete option[attr];
        continue;
      }
    }
    return option;
  },
  // 把对象转成用&拼接的参数
  parseParam(url, data) {
    if (typeof url === 'undefined' || url === null || url === '') {
      return '';
    }
    if (typeof data === 'undefined' || data === null || typeof data !== 'object') {
      return '';
    }
    url += url.indexOf('?') !== -1 ? '' : '?';
    Object.keys(data).forEach(k => {
      url += `${(url.indexOf('=') !== -1 ? '&' : '') + k}=${encodeURI(data[k])}`;
    });
    return url;
  },
  // 格式化内容，防止脚本执行
  formatContent(content) {
    return content
      .replace(/<script>/g, '[script]')
      .replace(/<\/script>/g, '[/script]')
      .replace(/\r\n/g, '<br/>')
      .replace(/\n/g, '<br/>');
  },
  // 删除url中某个参数
  funcUrlDel(names) {
    if (typeof names === 'string') {
      names = [names];
    }
    const loca = window.location;
    const obj = {};
    const arr = loca.search.substr(1).split('&');
    // 获取参数转换为object
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].split('=');
      obj[arr[i][0]] = arr[i][1];
    }
    // 删除指定参数
    for (let i = 0; i < names.length; i++) {
      delete obj[names[i]];
    }
    const params = JSON.stringify(obj).replace(/["{}]/g, '').replace(/:/g, '=').replace(/,/g, '&');
    // 重新拼接url
    const url = `${loca.origin + loca.pathname}?${params}`;
    return url;
  },
  // 截取字符串 (串,长度,增加...)
  funSubString(str, len, hasDot) {
    let newLength = 0;
    let newStr = '';
    const chineseRegex = /[\u0391-\uFFE5]/g;
    let singleChar = '';
    const strLength = str.replace(chineseRegex, '***').length;
    for (let i = 0; i < strLength; i++) {
      singleChar = str.charAt(i).toString();
      if (singleChar.match(chineseRegex) !== null) {
        newLength += 3;
      } else {
        newLength++;
      }
      if (newLength > len) {
        break;
      }
      newStr += singleChar;
    }
    if (hasDot && strLength > len) {
      newStr += '...';
    }
    return newStr;
  },
  /**
   * @version 1.0.0
   * @description 链接中用本网站域名替换非本网站域名
   * @param {Str} link 链接
   * @param {Str} forumURL 本网站域名
   * @param {Obj} domainConf 域名白名单
   * @param {Obj} normalConf 常量
   * @return {Str} result 结果
   */
  replaceDomain(link, forumURL, domainConf, normalConf) {
    let result = link;
    if (link.includes(forumURL)) {
      return result;
    } else {
      const domains = Object.values(domainConf);
      const index = domains.findIndex(i => i === forumURL);
      domains.splice(index, 1);
      domains.forEach(item => {
        if (link.includes(item)) {
          result = link.replace(normalConf.domainReg, forumURL);
          return result;
        }
      });
      return result;
    }
  },
  // 判断是否是JSON字符串
  isJsonString(str) {
    let res = false;
    try {
      if (typeof JSON.parse(str) === 'object') {
        res = true;
      }
    } catch (e) {
      res = false;
    }
    return res;
  },
  /**
   * 索引格式化序号，一位数前面补零，索引从0开始，序号从1开始
   * @param {number} num - 索引
   * @return {string} 返回格式化后的序号，如果序号小于10，则在序号前补0
   */
  formatOrderNumber(num) {
    return `${num + 1}`.padStart(2, '0');
  },
  /**
   * 将包涵\n的字符串换成p标签的字符串
   * @param {string} inputString - 输入的字符串
   * @return {string} 替换后的字符串
   */
  generateHTML(inputString) {
    if (!inputString?.includes('\n')) {
      return inputString;
    }
    // 使用split方法，根据\n将字符串分割成数组
    let lines = inputString.split('\n');
    // 使用map方法，将数组中的每个元素都替换成<p>{}</p>的形式
    lines = lines.map(line => `<p>${line}</p>`);
    // 使用join方法，将数组中的元素连接成一个字符串
    const result = lines.join('');
    return result;
  },
  /**
   * 去除html中的非img标签和空格
   * @param {string} html - 输入的字符串
   * @return {string} 替换后的字符串
   */
  getHtmlText(html) {
    return html.replace(/&nbsp;|<((?!img)[^>])+>/g, '').trim();
  },
};

export default string;
