// 帖子详情页英文词条

export default {
  jdc: 'Huawei Joint Definition Community',
  ok: 'OK',
  submit: 'OK',
  cancel: 'Cancel',
  yes: 'Yes',
  noSimple: 'No',
  post: 'Reply',
  group: 'group',
  section: 'section',
  groupU: 'Groups',
  sectionU: 'Sections',
  private: 'Private',
  public: 'Public',
  uncategorized: 'Uncategorized',
  sortBy: '',
  recommendations: 'Recommendations',
  byTime: 'Time',
  copyLink: 'Copy Link',
  publishedIn: '',
  fromBrowser: 'From browser',
  fromAndroid: 'From Android',
  fromIOS: 'From iOS',
  from: 'From ',
  showAllComments: "Show all users' comments",
  showAuthorComments: "Show the author's comments only",
  threadStarter: 'Thread Starter',
  author: 'Author',
  warned: 'Warned',
  warningHistory: 'Warning History',
  recommend: 'Recommend',
  secondFloor: '1 #',
  thirdFloor: '2 #',
  fourthFloor: '3 #',
  copied: 'Copied',
  agree: 'Agree',
  disagree: 'Disagree',
  viewPostOperationHistory: 'View Post Operation History',
  downloads: 'no downloads | {count} download | {count} downloads',
  pendingReview: 'Pending review',
  redemptionHistory: 'Redemption History',
  nickname: 'Nickname',
  onlyNickname: 'Nickname',
  redeemed: 'Redeemed',
  pointsNum: 'Points',
  status: 'Status',
  operation: 'Operation',
  system: 'System',
  failed: 'Failed',
  successful: 'Successful',
  rewardedSuccessful: 'Rewarded successfully.',
  returned: 'Returned',
  operationRecords: 'Operation Records',
  noRedemptions: 'No redemptions. Come and redeem points now?',
  operatedBy: 'Operated By',
  operator: 'Operated By',
  operated: 'Operated',
  operatedTime: 'Operated',
  close: 'Close',
  return: 'Return',
  returnPoints:
    '{count} point will be returned to user {user}. This cannot be undone. | {count} points will be returned to user {user}. This cannot be undone.',
  confirmReturn: 'Confirm return?',
  returnedSuccess: 'Returned',
  reward: 'Reward',
  withdrawReward: 'Withdraw Reward',
  participants: 'Participants',
  point: 'Point',
  bonus: 'Point',
  reason: 'Reason',
  showLess: 'Show Less',
  showMore: 'Show More',
  showAll: 'Show All',
  username: 'Username',
  time: 'Time',
  totalPoints: 'Total points: {count}',
  suspiciousPost: 'Suspicious post',
  duplicatePost: 'Duplicate post!',
  similarity: 'Similarity:',
  comparedWithPost: 'Compared with post:',
  handleSuggestion: 'Handle Suggestion',
  forward: 'Forward',
  inviteToReply: 'InviteToReply',
  favorite: 'Favorite',
  share: 'Share',
  repost: 'Repost',
  postRepost: 'Repost',
  repostTips: 'If the post is to be reposted to the public group, please pay attention to information security.',
  selectModule: 'Select Module:',
  selectCategorize: 'Select Category:',
  selected: 'Selected:',
  add: 'Add',
  remove: 'Remove',
  repostSuccess: 'Repost successfully.',
  likesNum: '0 like | {count} like | {count} likes',
  like: 'Like | Like {count} | Like {count}',
  favorited: 'Favorited.',
  unfavorited: 'Unfavorited.',
  goTo: 'Go to',
  jumpTo: 'Input the sequence number (#) of the comment you want to view',
  reply: 'Reply',
  postReply: 'Post Reply',
  quickReply: 'Quick Reply',
  contentSecurityTips:
    "Note: Dear user, to protect the legitimate rights and interests of you, the community, as well as third parties, you shall not publish any content that may bring legal risks to involved parties, which includes but not limited to the following: politically sensitive content; pornographic, gambling, and drug-related content; content that discloses or infringes upon others' business secrets; content that imposes infringement upon others' intellectual property rights like trademarks, versions, and patents, as well as personal privacy. You must not share your account and password with others. All operations performed using your account will be deemed as your own behavior and you shall bear the consequences of the operations. For details, see the ",
  userAgreement: 'User Agreement',
  termsOfUse: 'Terms of Use',
  privacyStatement: 'Privacy Statement',
  contentSecurityTipsPost:
    "Dear user, to protect the legitimate rights and interests of you, the community, as well as third parties, you shall not publish any content that may bring legal risks to involved parties, which includes but not limited to the following: politically sensitive content; pornographic, gambling, and drug-related content; content that discloses or infringes upon others' business secrets; content that imposes infringement upon others' intellectual property rights like trademarks, versions, and patents, as well as personal privacy. You must not share your account and password with others. All operations performed using your account will be deemed as your own behavior and you shall bear the consequences of the operations. For details, see ",
  note: 'Note: ',
  and: 'and',
  information: 'Information',
  notEnteredContent: 'Sorry, but you have not entered a title or content.',
  congratulations: 'Congratulations! You have earned a lucky draw chance.',
  goDrawNow: 'Go Draw Now',
  comment: 'Reply',
  replyPost: 'Reply',
  replyComment: ' replied to ',
  replyTo: 'Replied to ',
  quote: 'Quote',
  edit: 'Edit',
  report: 'Report',
  selectedPost: 'Selected',
  Post: 'post',
  cancelWarning: 'Cancel Warning',
  warning: 'Warning',
  block: 'Block',
  deletePostOpr: 'Delete',
  delete: 'Delete',
  pinToTop: 'Pin to Top',
  manage: 'Manage',
  showMoreComment: 'Show {count} more replies.',
  clickToView: ' Click to view.',
  commentTo: 'Reply to ',
  moreCharacterAllowed: '{count} more character allowed | {count} more characters allowed',
  violationMarksForPosts: 'Violation marks for posts of user {user}: ',
  violationMark: 'Mark as Violation',
  notifyAuthor: 'Notify Author',
  operationReason: 'Reason',
  notJoinedTips: 'You need to join a group in order to perform this operation.',
  blockReply: 'Block Reply',
  deleteReply: 'Delete Reply',
  visibleOnly: 'This post is visible to only administrators and authorized users.',
  rewardAt: 'Reward (at {count}#)',
  rewardToUserTips: 'Points are valuable and can be redeemed for gifts. Give reward according to the reward rules or as suggested.',
  pointsAllowed: 'Points Allowed',
  pointsInput: 'Award failed. Please enter an integer greater than 0.',
  pointsExceeds: 'Award failed. The number of points exceeds the limit of 24 hours.',
  remainingToday: 'Remaining Today',
  reasonForReward: 'Reason for Reward',
  withdrawRewardAt: 'Withdraw Reward (at {count}#)',
  operationDescription: 'Operation Description:',
  deletePost: 'Delete this post?',
  deleteComment: 'Delete this reply?',
  validityPeriod: 'Validity Period',
  longPermanent: 'Permanent',
  highlight: 'Highlight',
  bold: 'Bold',
  italic: 'Italic',
  underline: 'Underline',
  background: 'Background',
  selectBadge: 'Select Badge',
  pleaseSelect: '--Select--',
  pleaseInput: 'Enter your reason.',
  noBadge: 'No Badge',
  badge: 'Badge',
  groupNoSettingTips: 'You have not set the category for this group. Switch to the group setting page to set the category.',
  postBlocksOfUser: 'Post blocks of user {user}: {count}',
  userWarns: 'User {user} has been warned',
  times: 'times.',
  whoCanSeePost: 'Who Can See This Post',
  onlyAuthorAndHuaweiEmployees: 'Only the author and Huawei employees',
  whoCanSeeReplies: 'Who Can See the Replies',
  whoCanSeeContents: 'Who Can See the Contents',
  everyone: 'Everyone',
  onlyAuthorAndAdministrators: 'Only the author and administrators',
  commasSeparate: 'Use commas (,) to separate multiple tags. You can add up to five tags.',
  recentTags: 'Recent tags:',
  targetSection: 'Target Section',
  targetCategory: 'Target Category',
  pinToTopOfComments: 'Pin to top of replies',
  unpinFromTop: 'Unpin from top',
  deleteThisReply: 'Delete this reply?',
  deleteThisPoat: 'Delete this post?',
  deleteThisArticle: 'Delete this article?',
  banned: 'Banned',
  title: 'Title',
  upToAllowed: 'Up to 5 tags are allowed.',
  enterCharactersTips: 'Enter a string of 2 to 36 characters, including letters, digits, hyphens (-), and underscores (_).',
  operationReasonRequired: 'Required',
  today: 'Today',
  oneDay: '1 day',
  oneWeek: '1 week',
  twoWeeks: '2 weeks',
  oneMonth: '1 month',
  twoMonths: '2 months',
  threeMonths: '3 months',
  unhandled: 'Unhandled',
  handled: 'Handled',
  noValue: 'No value',
  toBeReplied: 'To be replied',
  expired: 'Expired',
  setBanDurationTips: 'Set the ban duration.',
  none: 'None',
  pinToTopOfSection: 'Pin to top of this section',
  pinToTopGlobally: 'Pin to top globally',
  selectValidityPeriod: 'Select a validity period in the range from {count1} to {count2}.',
  permanent: 'Permanent',
  threeDays: '3 days',
  fiveDays: '5 days',
  sixMonths: '6 months',
  oneYear: '1 year',
  knowledgeTalent: 'Knowledge Talent',
  technicalExpert: 'Technical Expert',
  industryLeader: 'Industry Leader',
  someHoursAgo: '{count} hour ago | {count} hours ago',
  halfAnHourAgo: 'Half an hour ago',
  someMinutesAgo: '{count} minute ago | {count} minutes ago',
  someSecondsAgo: '{count} second ago | {count} seconds ago',
  justNow: 'Just now',
  yesterday: 'Yesterday',
  twoDaysAgo: '2 days ago',
  someDaysAgo: '{count} day ago | {count} days ago',
  someWeeksAgo: '{count} week ago | {count} weeks ago',
  undoRepost: 'Undo Repost',
  viewOriginalPost: 'View Original Post',
  undoRepostReq: 'Undo repost?',
  open: 'Open',
  categorize: 'Categorize',
  move: 'Move',
  export: 'Export',
  posts: 'Posts',
  followers: 'Followers',
  follow: 'Follow',
  message: 'Message',
  unfollowUserReq: 'Unfollow this user?',
  no: 'No',
  unfollow: 'Yes',
  noMatchingResults: 'No matching results found.',
  selectUsersToMention: 'Select Users to Mention',
  recentContacts: 'Recent Contacts',
  back: 'Back',
  emailSharing: 'Email sharing',
  enterEmailTip: 'Enter email addresses. Separate multiple email addresses with semicolons.',
  enterEmail: 'Enter email addresses',
  leftEmails: 'You can enter {count} more email address | You can enter {count} more email addresses',
  mostEmails: 'The number of email addresses cannot exceed 100.',
  sharingDescription: 'Sharing description:',
  enterDescription: 'Enter description',
  sharedNum: '{count} person has shared | {count} people have shared.',
  simultaneousReply: 'Simultaneous Reply',
  notAllowRepost: 'The current post is not allowed to be reposted.',
  notGroupsRepost: 'Non-members of the group can not be reposted',
  likedsuccessfully: 'Liked successfully.',
  cancelLikedsuccessfully: 'Unliked successfully.',
  likedUnsuccessful: 'Liked unsuccessful.',
  cancelLikedUnsuccessful: 'Unliked unsuccessful.',
  scorecard: '',
  shareEssence: '',
  featured: 'Featured',
  cancelFeatured: 'Cancel Featured',
  fieldsOfExpertise: 'Fields of Expertise',
  saySomething: 'Say something...',
  processingAction: 'Action',
  replyContent: 'Reply to User',
  support: 'You can customize it',
  pleaseEnter: 'Select or enter a reply.',
  pleaseOnlyEnter: 'Enter',
  failedTemplate: 'Failed to obtain the template. Try again later.',
  selectingMembers: 'Selecting Members',
  reasonTransfer: 'Reason',
  transfer: 'OK',
  employeeID: 'Enter a Huawei employee ID.',
  selectExpert: 'Select a handling expert.',
  transferSucceeded: 'Successful',
  notExist: 'not exist',
  submitRrTips: 'Reward points to the users for their valuable suggestions.',
  rewardNow: 'Reward Now',
  later: 'Later',
  submitRrFloor: ' (Current operator: {number}#)',
  conversionRecord: 'Requirement Conversion History',
  submitNum: 'Submissions',
  submitTime: 'Submitted',
  requirementNo: 'RR No',
  requirementStatus: 'Status',
  submitTitle: 'Submitted successfully.',
  gotIt: 'Got It',
  replyMessage: 'Thanks for your reply!',
  effortMessage: 'Your efforts will help us define next-generation products.',
  prizeMessage: 'This topic will conclude next month. If you win a prize, we will notify you via email. Please keep an eye on your mailbox.',
  selectContact: 'Enter a nickname',
  unansweredTips: 'Answer at least one question to submit the questionnaire.',
  congratulation: 'Congratulations! ',
  giftNotice:
    "You've won a gift! To claim it, simply approach our on-site staff. Please note that gifts are only redeemable on-site and this offer becomes invalid once you leave the exhibition hall.",
  giftNotice1: "You've won a gift! To claim it, simply approach",
  giftNotice2: 'our on-site staff. Please note that gifts are only',
  giftNotice3: 'redeemable on-site and this offer becomes',
  giftNotice4: 'invalid once you leave the exhibition hall.',
  lockNotice: 'Once unlocked, the gift will be considered claimed.',
  chanceNotice: 'You have earned a lucky draw chance.',
  chanceNoticeH5: 'Congratulations! You have earned a lucky draw chance.',
  doDraw: 'Go Draw Now',
  verifyStart: 'Slide to unlock and claim your gift.',
  verifySuccess: 'Gift claimed successfully.',
  successfully: 'Submitted successfully.',
  SendNotifyAuthor: 'Send notification to author.',
  btnOk: 'Ok',
  noRecentContacts: 'No recent contacts',
  noMatchesFound: 'No matches found',
  searching: 'Searching...',
  selectUsers: 'Select Users',
  enterNickname: 'Enter a nickname',
  selectToMention: 'Select Users',
};
