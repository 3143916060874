export default {
  message: 'Message',
  noDataTips: 'None',
  pleaseSelect: 'Select',
  pleaseEnter: '-Enter-',
  pleaseInputKeyword: 'Please Input Keyword',
  save: 'Save',
  infoCompleceTips: 'All fields are required.',
  hint: 'Hint',
  uploadImage: 'Upload image',
  promptInformation: 'Prompt Information',
  confirm: 'Confirm',
  cancel: 'Cancel',
  plsSelect: 'Please Select',
  selectFile: 'Select File',
  loading: 'loading',
  jdc: 'Huawei Joint Definition Community',
  noData: 'No Data',
  goTop: 'Back to Top',
  goBottom: 'Back to Bottom',
  support: 'Support',
  contactUs: 'Contact us',
  advice: 'Propose',
  search: 'Search',
  searchFor: 'Search',
  reset: 'Reset',
  login: 'Login',
  sign: 'Sign',
  post: 'Post',
  reply: 'Reply',
  like: 'Like',
  success: 'Success',
  to: 'To',
  and: 'And',
  collection: 'Collection',
  comment: 'Comment',
  all: 'All',
  zh: 'Chinese',
  en: 'English',
  ru: 'Russian',
  es: 'Spanish',
  fr: 'French',
  minute: 'Minute',
  day: 'Day',
  noProxy: 'Sorry, you are not authorized to use this feature!',
  uploadSuccess: 'Upload success.',
  loginTips: 'Login and enjoy all the member benefits!',
  loginTipsBtn: 'Login',
  backToHomePage: 'Back to the homepage',
  notFound: 'Sorry, this page does not exist.',
  submit: 'Submit',
  yes: 'Yes',
  no: 'No',
  days: '0 days | one day | {count} days',
  header: {
    myProducts: 'My Products',
    settings: 'Settings',
    managementCenter: 'Management center',
    enterpriseName: 'Enterprise',
    enterprise: 'Enterprise',
    login: 'Login',
    logOut: 'Log Out',
    language: 'Language',
    consumer: 'Consumer',
    corporate: 'Corporate',
    carriers: 'Carriers',
    enterpriseDes: 'Products, Solutions and Services for Enterprise',
    consumerDes: 'Smartphones, PC & Tablets, Wearables and More',
    corporateDes: 'About Huawei, Press & Events , and More',
    carriersDes: 'Operator solutions',
    HuaweiEnterprise: 'Huawei Enterprise Support Community',
    community: 'Community',
    forums: 'Forums',
    groups: 'Groups',
    collections: 'Collections',
    rewards: 'Rewards',
    FAQ: 'FAQ',
    cookieTip: 'This site uses cookies. By continuing to browse the site you are agreeing to our use of cookies.',
    cookieTipLink: ' Read our privacy policy>',
  },
  umeditor: {
    fileStyleSupportRR: 'Each file cannot exceed 100 MB.',
    fileStyle: 'The file format is not supported.',
    uploading: 'Uploading',
    VIDEO: 'VIDEO',
    compressVideo: 'The video bitrate exceeds 6000 kbit/s. Compress it before uploading.',
  },
  ueditor: {
    ueditorTitle: 'image',
    ueditorLocalPicture: 'local upload',
    ueditorOnlinePicture: 'network pictures',
    ueditorPicturetitle: 'no files selected',
    ueditorNoData: 'please enter image description',
    ueditorMaxLength: 'please enter less than 50 characters',
    ueditorImgTitle: 'support drag upload',
    ueditorAdd: 'add',
    ueditorCancel: 'cancel',
    ueditorSave: 'ok',
    numberExceeds: 'upload limit to the number!',
    insertCode: 'insert code',
    imgPlaceholder: 'enter a description',
    atTitle: 'select people you want to @ Mention:',
    atMyFollowers: 'my followers',
    atMyFollowings: 'my followings',
    atRecentContacts: 'recent contacts',
  },
  sizeContent: "Upload attachment size can't exceed 50MB!",
  messageTip: {
    newMessageInfo: 'You have new message,',
    newMessageUrl: 'go to check it',
    busy: 'The system is busy. Please wait or refresh the page',
    beReviewed: 'Sorry, the specified content does not exist, has been deleted, or is being reviewed.',
  },
  footer: {
    hwWebsite: 'Huawei Website',
    support: 'support',
    aboutHw: 'About Huawei',
    privacy: 'privacy',
    termsOfUser: 'Terms of use',
    copyright: 'Copyright © 2020 Huawei Technologies Co., Ltd. All rights reserved.',
  },
  tags: {
    top: 'Top',
    hot: 'Hot',
    essences: 'Featured',
    rewarded: 'Rewarded with points',
    locked: 'Locked',
    reposted: 'Reposted',
    attachments: 'Attachments contained',
    repliesLimit: 'Replies visible only to authors/administrators',
    authenticated: 'Authenticated user',
    vip: 'VIP user',
    administrator: 'Administrator',
    administratorHover: 'JDC administrator',
    groupOwner: 'Group Owner',
    groupOwnerHover: 'Group owner of {value}',
    moderator: 'Moderator',
    moderatorHover: 'Moderator of {value}',
    banned: 'Banned',
    bannedHover: 'The user account is banned.',
    accessForbidden: 'Access forbidden',
    accessForbiddenHover: 'The user account is forbidden to access.',
    authorBannedTitle: 'Prohibited from speaking',
    authorAccessForbiddenTitle: 'Access Denied',
    newReviews: 'You have new replies',
    invited: "You're invited",
    newReplies: 'New replies',
    invitedAns: 'Invited to answer',
    surprised: 'Someone surprised you',
  },
  huaweiJDC: 'Huawei Joint Definition Community',
  flowControlTips: 'Too many users. Please try again later.',
  gotIt: 'I got it',
};
