import { showToast } from 'vant';
import wx from 'weixin-js-sdk';

import * as types from '../mutation-types';

import axios, { requestCsrfToken } from '@/axios/h5';
import api from '@/axios/h5Api';
import refactorApi from '@/axios/refactorApi';
import { COOKIE_KEY, ONLY_REQUIRED } from '@/components/h5/login';
import env from '@/config/env';
import router from '@/router/h5.js';
import common, { getMsgFromMiniOriginal } from '@/utils/common/common';
import { cookie } from '@/utils/common/CommonUtils';
import errCode from '@/utils/common/errCode.js';
import APPUtil from '@/utils/h5/APPUtil';
import util from '@/utils/h5/common.js';
import imgUtils from '@/utils/h5/imgUtils';
const DIGIT = 1;
const isAndroid = 'Android';
const isIOS = 'iOS';
const userAgent = window.navigator.userAgent;
// state
const state = {
  userUid: '',
  tabType: '',
  container: '', // 容器标识
  userInfo: {}, // 用户信息
  messageList: [],
  prvMessageList: [], // 私信消息
  prvPageVo: {
    start: 0,
    size: 10,
    total: 0,
  },
  xCsrfToken: '', // token 值
  xCsrfTokenCreated: 0, // token 创建时间
  leaveActive: '',
  firstLogin: false, // 打开页面调用的第一个接口postLogin设置登录标志
  visibleArea: false, // 提交问卷
  touchmove: false,
  commenPostContent: '',
  commenReply: {}, // 回复评论
  appreciate: {}, // 赞赏
  personalParams: {},
  auctionStatus: '',
  refreshPost: false,
  isAndroid: false,
  isIOS: false,
  isH5: true,
  client: {
    isIOS: /(iPhone|iPad|iPod|iOS)/i.test(userAgent),
    isAndroid: /(Android)/i.test(userAgent),
    isWeChat: userAgent.includes('MicroMessenger'),
  },
  clientChange: '',
  longPress: {}, // 长按帖子详情页回复、评论
  clickContent: {}, // 点击帖子详情页回复、评论
  nicknameModify: false, // 是否修改用户昵称
  launchUrl: '',
  popOutRecomment: false, //帖子详情页推荐问卷是否弹出推荐页
  appNotifyControl: 'notyet', // 系统是否开启app应用通知权限 'open' 'close' 'notyet'
};

// getters
const getters = {
  // 嵌套在 app 内
  isNative(state) {
    return state.container === 'native';
  },
};

// mutations
const mutations = {
  [types.USER_UID](state, data) {
    state.userUid = data;
  },
  [types.COMMON_TAB_TYPE](state, data) {
    state.tabType = data;
  },
  [types.SET_CONTAINER](state, data) {
    state.container = data;
  },
  [types.USER_INFO](state, data) {
    state.userInfo = data;
  },
  [types.MESSAGE_LIST](state, data) {
    state.messageList = data;
  },
  [types.PRV_MESSAGE_LIST](state, data) {
    state.prvMessageList = data;
  },
  [types.USER_TOKEN_VALUE](state, data) {
    state.xCsrfToken = data;
  },
  [types.USER_TOKEN_CREATED](state, data) {
    state.xCsrfTokenCreated = data;
  },
  [types.LEAVE_ACTIVE](state, data) {
    state.leaveActive = data;
  },
  [types.IS_LOGIN](state, data) {
    state.firstLogin = data;
  },
  [types.VISIBLE_AREA](state, data) {
    state.visibleArea = data;
  },
  [types.TOUCH_MOVE](state, data) {
    state.touchmove = data;
  },
  [types.COMMON_POST_CONTENT](state, data) {
    state.commenPostContent = data;
  },
  [types.COMMON_REPLY](state, data) {
    state.commenReply = data;
  },
  [types.APPRECIATE](state, data) {
    state.appreciate = data;
  },
  [types.PERSONAL_PARAMS](state, data) {
    state.personalParams = data;
  },
  [types.AUCTION_STATUS](state, data) {
    state.auctionStatus = data;
  },
  [types.REFRESH_POST](state, data) {
    state.refreshPost = data;
  },
  [types.LONG_PRESS](state, data) {
    state.longPress = data;
  },
  [types.CLICK_CONTENT](state, data) {
    state.clickContent = data;
  },
  [types.CURRENT_CLIENT](state, data) {
    state.isAndroid = data === isAndroid;
    state.isIOS = data === isIOS;
    state.isH5 = !state.isAndroid && !state.isIOS;
    state.clientChange = new Date().getTime();
  },
  [types.UPDATE_PRV_PAGE](state, { start, total }) {
    if (start > -1) {
      state.prvPageVo.start = start;
    }
    if (total > -1) {
      state.prvPageVo.total = total;
    }
  },
  [types.GO_TO_USER_CENTER](state, { uid, vue }) {
    const msgFromMiniOriginal = getMsgFromMiniOriginal();
    const params = {
      showTitleBar: true,
      route: {
        path: '/postDetail',
        query: {},
      },
    };
    if (Number(uid) === Number(state.userUid)) {
      if (msgFromMiniOriginal) {
        wx.miniProgram.navigateTo({ 'url': '/pages/personalData/personalData' });
        return;
      }
      params.route.path = '/personalData';
    } else {
      if (msgFromMiniOriginal) {
        wx.miniProgram.navigateTo({ 'url': `/pages/hiscenter/hiscenter?uid=${uid}` });
        return;
      }
      params.route.path = '/hisHomePage';
      params.route.query.uid = uid;
    }
    vue.$rm.APPUtil.goToMobile('loadUrl', params, vue.$router);
  },
  [types.NICKNAME_MODIFY](state, data) {
    state.nicknameModify = data;
  },
  [types.USER_IMAGE](state, data) {
    state.userInfo.userImage = data;
  },
  [types.LAUNCH_URL](state) {
    state.launchUrl = window.location.href;
  },
  [types.POP_OUT_RECOMMENT](state, data) {
    state.popOutRecomment = data;
  },
  [types.SET_APP_NOTIFY_CONTROL](state, data) {
    state.appNotifyControl = data;
  },
};

// actions
const actions = {
  async [types.MESSAGE_LIST](context) {
    const { start, size } = state.prvPageVo;
    const params = { start, size };
    const [res1, res2] = await Promise.all([
      axios.get(api.user.getMessageList, { baseURL: env.newApiBaseURL }),
      axios.get(api.message.getFriend, { params, baseURL: env.newApiBaseURL }),
    ]);
    if (res1.code === errCode.success && res2.code === errCode.success) {
      APPUtil.toMessage();
      const list = res1.data.notifications || [];
      const prvList = res2.data.messageList || [];
      list.forEach(item => {
        item.typeName = util.getTitleType(item.group);
      });
      prvList.forEach(i => {
        i.avatar = imgUtils.getBase64AvatarFree(i.userId);
      });
      context.commit(types.MESSAGE_LIST, list);
      if (start === 0) {
        context.commit(types.PRV_MESSAGE_LIST, prvList);
      } else {
        const { prvMessageList } = state;
        const existMessageIds = new Set(prvMessageList.map(item => item.plid));
        prvList.forEach(item => {
          if (!existMessageIds.has(item.plid)) {
            prvMessageList.push(item);
          }
        });
        context.commit(types.PRV_MESSAGE_LIST, prvMessageList);
      }
      context.commit(types.UPDATE_PRV_PAGE, { total: res2.data.total });
      return list;
    }
    return [];
  },
  async [types.MESSAGE_LIST_LOOP](context) {
    const params = { start: 0, size: 20 };
    const [value1, value2] = await Promise.all([
      axios.get(api.user.getMessageList, { baseURL: env.newApiBaseURL }),
      axios.get(api.message.getFriend, { params, baseURL: env.newApiBaseURL }),
    ]);
    if (value1.code === errCode.success && value2.code === errCode.success) {
      APPUtil.toMessage();
      const list = value1.data.notifications || [];
      const prvList = value2.data.messageList || [];
      list.forEach(item => {
        item.typeName = util.getTitleType(item.group);
      });
      prvList.forEach(i => {
        i.avatar = imgUtils.getBase64AvatarFree(i.userId);
      });
      context.commit(types.MESSAGE_LIST, list);
      const { prvMessageList } = state;
      const updatedMessageIds = new Set(prvList.map(item => item.plid));
      const oldMessageList = prvMessageList.filter(item => {
        return !updatedMessageIds.has(item.plid);
      });
      context.commit(types.PRV_MESSAGE_LIST, [...prvList, ...oldMessageList]);
      context.commit(types.UPDATE_PRV_PAGE, { total: value2.data.total });
      return list;
    }
    return [];
  },
  [types.NOT_LOGIN]() {
    const msgFromMiniOriginal = getMsgFromMiniOriginal();
    if (msgFromMiniOriginal) {
      atob(msgFromMiniOriginal)
        ?.split(';')
        ?.forEach(item => {
          const [key, value] = item.split('=');
          cookie.setCookie(key, value);
        });
      window.location.reload();
    }
    const redirectReg = /[?&]?redirect=([^&]+)/;
    const urlRedirect = window.location.search.match(redirectReg);
    const redirect = urlRedirect ? decodeURIComponent(urlRedirect[1]) : encodeURIComponent(window.location.href.replace(redirectReg, ''));
    const params = {
      route: {
        path: '/login',
        query: {
          redirect,
        },
      },
    };
    APPUtil.goToMobile('toLogout', params, router);
  },
  // 获取用户信息
  async [types.GET_USERLOGIN](context) {
    //APP不调用 /jdc/rest/sso/postLogin
    if (context.state.isH5) {
      const url = refactorApi.user.login;
      const res = await axios.post(url, null, { baseURL: env.newApiBaseURL });
      if (res.code === errCode.apiNoLogin && res.message === 'Not logon') {
        context.dispatch(types.NOT_LOGIN);
        context.commit(types.IS_LOGIN, false);
        return;
      }
      const msgFromMiniOriginal = getMsgFromMiniOriginal();
      if (res.code === errCode.apiNoLogin && !msgFromMiniOriginal) {
        showToast(res.message);
      }
      if (env.curVersionEnv === 'enJdc') {
        // 海外版H5登录前已签署协议，1代表同意协议
        await axios.post(refactorApi.user.privacy, { privacy: 1, agreement: 1 }, { baseURL: env.newApiBaseURL });
        //  cookie协议签署，1=全部同意；2=仅同意必要
        await axios.post(`${refactorApi.user.cookiePrivacy}?type=${sessionStorage.getItem(COOKIE_KEY) === ONLY_REQUIRED ? 2 : 1}`);
      }
      await context.dispatch(types.USER_TOKEN);
      context.commit(types.IS_LOGIN, true);
      return;
    }
    await context.dispatch(types.USER_TOKEN);
    context.commit(types.IS_LOGIN, true);
  },
  async [types.USER_TOKEN](context) {
    await requestCsrfToken();
    context.dispatch(types.GET_USERINFO);
  },
  async [types.GET_USERINFO](context) {
    const params = {};
    params.noencryption = DIGIT;
    // 获取自己的用户信息，isShowEmail 可以始终传 2 进行查询
    params.isShowEmail = 2;
    const url = `${refactorApi.user.profileInfo}0`;
    const { data, code } = await axios.get(url, {
      params,
      baseURL: env.newApiBaseURL,
    });
    if (code === errCode.success) {
      if (data.uid) {
        if (data.uid > 0 && data.isMerged) {
          // 进行登出操作
          await context.dispatch(types.NOT_LOGIN);
        }
        if (data.userImage) {
          data.userImageBase64 = data.userImage;
        }
        context.commit(types.USER_UID, data.uid);
        context.commit(types.USER_INFO, data);
        window.hwa('setEnable', true);
        const toCompletedInfo = data.isSuperUser !== 1 && data.isInvitedSuperUser !== 1 && data.registrationStatus !== 'completed';
        if (window.currentClient === 'h5') {
          const msgFromMiniOriginal = getMsgFromMiniOriginal();
          if (toCompletedInfo && msgFromMiniOriginal) {
            // H5跳小程序页面url需要带引号
            jumpCompelete();
            return;
          }
          // h5未否签署协议 0-未签署 1-已签署
          if (data.isPrivacyStatementVerified === 0) {
            router.push({
              path: '/userAgreement',
            });
            return;
          } else if (toCompletedInfo) {
            // h5未完善用户信息
            router.push({
              path: env.curVersionEnv === 'enJdc' ? '/enCompletedInfo' : '/completedInfo',
              query: env.curVersionEnv === 'enJdc' ? { redirect: window.location.href } : {},
            });
            return;
          }
        }
        if (data.nickname_modify !== '1') {
          context.commit(types.NICKNAME_MODIFY, true);
        }
      }
    }
    const msgFromMiniOriginal = getMsgFromMiniOriginal();
    if (code === errCode.noLogin && !msgFromMiniOriginal) {
      showToast(data.message);
    }
  },
};

// 跳转到完善用户信息页
const jumpCompelete = () => {
  common.stopClick('hotThreadCoe', 1000).then(() => {
    wx.miniProgram.navigateTo({ 'url': '/pages/completeUserInfo/completeUserInfo' });
  });
};

export default {
  state,
  getters,
  mutations,
  actions,
};
