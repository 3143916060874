export default {
  privacyStatement: '隐私声明',
  termsOfUse: '用户协议',
  JDCPrivacyStatement: '华为产品定义社区隐私声明',
  JDCTermsUse: '华为产品定义社区用户协议',
  huaweiJointDefinitionCommunity:
    '华为产品定义社区是一个知识社区，是华为产品用户、行业大咖和华为专家之间交流的互动平台，是用户反馈华为产品体验，提出产品改进建议和新需求的便捷渠道。',
  welcomeFutureHuaweiProducts: '诚邀您对华为产品提出建议，一起定义下一个产品。',
  agree: '同意',
  disagree: '不同意',
  agreeTermsPrivacystatement: '您同意用户协议和隐私声明后才可以使用本产品及服务',
  accessDeniedToHome: '当前页面访问受限， 5秒后将跳转到首页',
  and: '以及',
};
