const baseURL = '/CommunityGatewayService/com.huawei.ipd.sppm.jdcforum:';

// 不同的模块前缀
const refactor = {
  user: 'JDCCommunityUserService/CommunityUserService',
  forum: 'JDCCommunityGroupService/CommunityGroupService',
  post: 'JDCCommunityPostService/CommunityPostService',
  task: 'JDCCommunityTaskService/CommunityTaskService',
  message: 'JDCCommunityMessageService/CommunityMessageService',
  column: 'JDCCommunityColumnService/CommunityColumnService',
  gateway: '/CommunityGatewayService',
};
const refactorApi = {
  // 接口
  user: {
    // 用户模块
    userLevel: `${baseURL + refactor.user}/jdc/api/user/level`,
    creditRecord: `${baseURL + refactor.user}/jdc/api/credit/record`,
    detailUserInfo: `${baseURL + refactor.user}/jdc/api/userinfo/getDetailUserInfo`,
    emailCheckSend: `${baseURL + refactor.user}/jdc/api/emailcheck/send`,
    commonBanner: `${baseURL + refactor.user}/jdc/api/common/banner`,
    profileInfo: `${baseURL + refactor.user}/jdc/api/user/profile/`,
    emailCheck: `${baseURL + refactor.user}/jdc/api/emailcheck`,
    userRank: `${baseURL + refactor.user}/jdc/api/userinfo/getRank`,
    recommendList: `${baseURL + refactor.user}/jdc/api/recommend/list`,
    signList: `${baseURL + refactor.user}/jdc/api/sign/list`,
    signCount: `${baseURL + refactor.user}/jdc/api/sign/count`,
    getMedalList: `${baseURL + refactor.user}/jdc/api/userinfo/getMedalList`,
    followingsList: `${baseURL + refactor.user}/jdc/api/followings/list`,
    followersList: `${baseURL + refactor.user}/jdc/api/followers/list`,
    getProfilePost: `${baseURL + refactor.user}/jdc/api/profile/post`, // 查询用户主题列表
    profileBase: `${baseURL + refactor.user}/jdc/api/profile/base`,
    personArea: `${baseURL + refactor.user}/jdc/api/personal/area`, // 更新个人领域
    companyName: `${baseURL + refactor.user}/getEnterpriseName`, // 更新公司
    skilledFields: `${baseURL + refactor.user}/jdc/api/personal/skilledFields`, // 获取擅长领域
    getFollowerCount: `${baseURL + refactor.user}/jdc/api/personal/getFollowerCount`, // 关注数和粉丝数
    privacy: `${baseURL + refactor.user}/jdc/api/profile/privacy`, // 隐私声明签署
    cookiePrivacy: `${baseURL + refactor.user}/jdc/api/cookiePrivacy/log/addLog`, // 记录cookie协议签署日志
    user: `${baseURL + refactor.user}/jdc/api/user`, // 修改手机号
    send: `${baseURL + refactor.user}/jdc/api/common/sms/send`, // 修改手机号获取验证码
    getCommonIndustry: `${baseURL + refactor.user}/jdc/api/profile/industry`, // 获取行业列表
    uploaduseravatar: `${baseURL + refactor.user}/jdc/api/avatar/upload`, // 上传用户头像
    contact: `${baseURL + refactor.user}/jdc/api/personal/contact`, // 更新个人联系方式
    profileEmail: `${baseURL + refactor.user}/jdc/api/profile/email`, // 邮箱订阅
    getSkilledFiedls: `${baseURL + refactor.user}/jdc/api/personal/skilledField`, // 查询大V认证行业，产品线，解决方案(未认证)
    updateUserInfo: `${baseURL + refactor.user}/jdc/api/userinfo/updateUserInfo`, // 完善用户信息
    getMallNews: `${baseURL + refactor.user}/jdc/api/index/mallNews`, // 查询商城动态
    getNovice: `${baseURL + refactor.user}/jdc/api/index/novice`, // 查询新手FAQ
    activeExperts: `${baseURL + refactor.user}/jdc/api/index/activeExperts`, // 查询月度活跃专家
    downloadImage: `${baseURL + refactor.user}/jdc/api/singleimage/download`, // 下载单张图片
    downloadImages: `${baseURL + refactor.user}/jdc/api/image/download`, // 批量下载图片
    getRegionList: `${baseURL + refactor.user}/jdc/api/userinfo/getRegionCodeList`, // 查询国家地区
    getCommonBanner: `${baseURL + refactor.user}/jdc/api/common/banner`, // 查询商城banner
    getUserCardInfo: `${baseURL + refactor.user}/jdc/api/userinfo/getUserCardInfo`, // 用户卡片信息
    searchUserList: `${baseURL + refactor.user}/jdc/api/userList/searchUserList`, // 圈子邀请-搜索用户
    getIndustry: `${baseURL + refactor.user}/jdc/api/profile/industry`, // 获取行业
    postCheckAtUser: `${baseURL + refactor.user}/jdc/api/newthread/checkAtUser`, // 检测at用户的合法性
    getAtUser: `${baseURL + refactor.user}/jdc/api/newthread/getAtUser`, // 查询at用户
    getSignInfo: `${baseURL + refactor.user}/jdc/api/sign/getUserSignDetail`, // 获取签到详情
    postRepair: `${baseURL + refactor.user}/jdc/api/sign/replenishSign`, // 补签到
    getPrivacy: `${baseURL + refactor.user}/jdc/api/common/privacyStatement`, // 查询隐私声明和签署协议
    getWechatPic: `${baseURL + refactor.user}/jdc/api/wechat/getQrcode`, // 获取微信登录二维码
    getLoginStatus: `${baseURL + refactor.user}/jdc/api/wechat/getWeChatLoginStatus`, // 获取微信登录二维码状态
    postScanInfo: `${baseURL + refactor.user}/jdc/api/userLogin/appScanInfo`, // 华为JDC App登录信息
    weLinkLogin: `${baseURL + refactor.user}/jdc/api/userLogin/welinkLogin`, // weLink登录
    accountConfirm: `${baseURL + refactor.user}/jdc/api/user/bind/confirm`, // 提交账号合并
    getAccountBind: `${baseURL + refactor.user}/jdc/api/user/bind/account`, // 获取可合并的账号
    bindSkip: `${baseURL + refactor.user}/jdc/api/user/bind/skip`, // 取消账号绑定
    logout: `${baseURL + refactor.user}/jdc/rest/sso/logout`, // 退出登录
    login: `${baseURL + refactor.user}/jdc/rest/sso/postLogin`, // 登录
    downByPath: `${baseURL + refactor.user}/jdc/api/attachment/downLoadByAid`, // 编辑器上传图片
    deleteMessage: `${baseURL + refactor.user}/jdc/api/message/history`, // 删除好友私信
    attachmentDownload: `${baseURL + refactor.user}/jdc/api/attachment/download`, // 附件下载
    taskGift: `${baseURL + refactor.user}/jdc/api/profile/task/gift`, // 领取任务礼盒
    getRecommends: `${baseURL}${refactor.user}/following/getRecommends`, // 关注列表
    getStarUsers: `${baseURL}${refactor.user}/user/getStarUsers`, // 推荐关注
    following: `${baseURL}${refactor.user}/following/`, // 关注/取消关注
    getLabel: `${baseURL}${refactor.user}/label`, // 获取标签
    postTaskActivity: `${baseURL}${refactor.user}/profile/task/activity`, // 完成任务后，领取奖励
    postTaskGift: `${baseURL}${refactor.user}/jdc/api/profile/task/gift`, // 领取任务礼盒
    getTaskProcess: `${baseURL}${refactor.user}/profile/task/process`, // 获取任务进度
    getTaskList: `${baseURL}${refactor.user}/profile/task/list`, // 获取任务列表
    getActivityRecord: `${baseURL}${refactor.user}/profile/task/activityRecord`, // 获取任务活动记录
    postSetStatus: `${baseURL}${refactor.user}/profile/browseActivityArea/setStatus`, // 浏览获取区域设置状态
    submitCertifiedInfo: `${baseURL}${refactor.user}/certifiedInfo/submit`, // POST 提交认证信息
    getCertifiedInfo: `${baseURL}${refactor.user}/certifiedInfo/0`, // GET 获取认证信息
    markFollowersAsRead: `${baseURL}${refactor.user}/user/markFollowersAsRead`, // 更新粉丝状态为已读
    postSign: `${baseURL}${refactor.user}/jdc/api/sign/sign`, // 签到
    getWechatInviteRecord: `${baseURL}${refactor.user}/wechat/thread/user/record`, // GET 获取成功邀请用户数
    getUserActivityInfo: `${baseURL}${refactor.user}/activity/getUserActivityInfo/`, // 参会二维码中的获取活动信息
    getSingleUserActivityInfo: `${baseURL}${refactor.user}/activity/getSingleUserActivityInfo/`, // 参会二维码中的获取活动信息详情
    getMessageList: `${baseURL}${refactor.user}/notification/home`, // GET 请求消息分组列表
    setMsgRead: `${baseURL}${refactor.user}/notification/read`, // GET 设置消息已读
    sendFeedback: `${baseURL}${refactor.user}/jdc/api/userinfo/sendFeedback`, // 用户反馈
    getDeviceList: `${baseURL}${refactor.user}/jdc/api/userLogin/device/list`, // GET 获取用户登录设备列表
    tagWhiteListSearch: `${baseURL}${refactor.user}/tagwhitelist/search`, // 查询用户，转交处理
    loginWidthCode: `${baseURL}${refactor.user}/jdc/rest/sso/loginWithCode`, // 登录获取 uipsid
    notificationList: `${baseURL}${refactor.user}/notification/list`, // GET 获取用户消息
    andoridApp: `${baseURL}${refactor.user}/jdc/api/attachment/download/andoridApp`, // GET 下载app
    delFriendMessage: `${baseURL}${refactor.user}/jdc/api/message/delFriendMessage`, // GET 删除聊天用户
    hasPermission: `${baseURL}${refactor.user}/jdc/api/user/pool/hasPermission`, // GET 检查是否在白名单
    getUserPersona: `${baseURL}${refactor.user}/jdc/api/user/pool/getUserPersona`, // GET 查询大咖用户
    getUserTags: `${baseURL}${refactor.user}/jdc/api/user/pool/getUserTags`, // GET 查询大咖用户标签
    getAllPartnerType: `${baseURL}${refactor.user}/jdc/api/user/pool/getAllPartnerType`, // GET 查询合作伙伴
    searchCompany: `${baseURL}${refactor.user}/jdc/api/user/pool/searchCompany`, // GET 查询搜索公司
    searchIndustry: `${baseURL}${refactor.user}/jdc/api/user/pool/searchIndustry`, // GET 搜索行业
    getUserPage: `${baseURL}${refactor.user}/jdc/api/user/pool/getUserPage`, // POST 查询用户
    exportUser: `${baseURL}${refactor.user}/jdc/api/user/pool/exportUser`, // POST 导出用户
    statisticUser: `${baseURL}${refactor.user}/jdc/api/user/pool/statisticUser`, // POST 统计用户特征
    loginFromWeChat: `${baseURL}${refactor.user}/jdc/api/wechat/loginFromWeChat`, // h5 微信登录
    getUrlScheme: `${baseURL}${refactor.user}/jdc/api/wechat/getUrlScheme`, // 获取跳转小程序地址
    getCurrentQrcode: `${baseURL}${refactor.user}/jdc/api/wechat/getCurrentQrcode`, // 获取二维码
    wechatUserCode: `${baseURL}${refactor.user}/wechat/user/code`, // 微信登录二维码
    unreadCount: `${baseURL}${refactor.user}/notification/unread/count`, // 未读消息数
    getBase64AvatarFree: `${baseURL}${refactor.user}/jdc/api/getBase64AvatarFree`, // 根据uid获取用户头像
    getSignature: `${baseURL}${refactor.user}/wechat/jsapi/signature`, // 获取 weixin-js-sdk 注册签名
    personalizedRecommendation: `${baseURL}${refactor.user}/jdc/api/profile/personalizedRecommendation/update`, // 个人设置增加“个性化推荐”开关
    getUserMedal: `${baseURL}${refactor.user}/jdc/api/medal/getUserMedal`, // 获取用户勋章
    getLatestUserMedal: `${baseURL}${refactor.user}/jdc/api/medal/getLatestUserMedal`, // 获取用户最新获取的勋章
    productLineExpert: `${baseURL}${refactor.user}/jdc/api/reportExport/productLineExpert`, // 产品线专家导出
    updateWearMedal: `${baseURL}${refactor.user}/jdc/api/medal/updateWearMedal`, // 用户佩戴勋章
    getMedalProgress: `${baseURL}${refactor.user}/jdc/api/medal/progress`, // 获取勋章进度
    certificationQuery: `${baseURL}${refactor.user}/jdc/api/user/certification/query`, // 查询用户最新认证信息
    getProductField: `${baseURL}${refactor.user}/jdc/api/profile/productField`, // 获取产品领域
    cookieContent: `${baseURL}${refactor.user}/jdc/api/common/cookie/content`, // 获取cookie信息
    contactEmailStatus: `${baseURL}${refactor.user}/jdc/api/personal/contactEmailStatus`, // 获取邮箱状态
    updateRemark: `${baseURL}${refactor.user}/jdc/api/user/updateRemark`, // 获取用户备注
    originalReport: `${baseURL}${refactor.user}/jdc/api/userList/searchUserList/originalReport`, // 原创报告 发起人、贡献用户查询
    loginFromWeChatWeb: `${baseURL}${refactor.user}/jdc/api/wechat/loginFromWeChatWeb`, // 微信登录
    getAuthenticationUrl: `${baseURL}${refactor.user}/jdc/oauth2/login/getAuthenticationUrl`, // 海外apple和google登录地址
  },
  forum: {
    // 圈子模块
    forumInfo: `${baseURL + refactor.forum}/jdc/api/forum/info`, // 查询我的圈子列表
    unJoinGroup: `${baseURL + refactor.forum}/jdc/api/group/unjoin`, // 退出圈子
    joinGroup: `${baseURL + refactor.forum}/jdc/api/forum/join`, // 加入圈子
    passCode: `${baseURL + refactor.forum}/jdc/api/forum/passcodeJoin`, // 邀请码加圈
    inviteRegister: `${baseURL + refactor.forum}/jdc/api/forum/invite`, // 邀请已注册用户
    inviteUnregister: `${baseURL + refactor.forum}/jdc/api/forum/inviteUnregistered`, // 邀请未注册用户
    getGroupInfo: `${baseURL + refactor.forum}/jdc/api/forum/getgroupinfo`, // 查询圈子信息
    getAnnouncement: `${baseURL + refactor.forum}/jdc/api/index/announcement`, // 查询社区公告
    getProduct: `${baseURL + refactor.forum}/jdc/api/product/productSearch`, // 商品搜索
    getProductList: `${baseURL + refactor.forum}/jdc/api/product/productList`, // 商品列表
    getRecommendation: `${baseURL + refactor.forum}/jdc/api/group/recommendation`, // 推荐圈子
    getFrequentList: `${baseURL + refactor.forum}/jdc/api/group/hot`, // 常逛圈子
    getActionList: `${baseURL + refactor.forum}/jdc/api/auction/recommend`, // 推荐商品、新手专属、VIP专属
    getTradeList: `${baseURL + refactor.forum}/jdc/api/user/trade/list`, // 查询发布商品兑换记录
    getAuctionNotice: `${baseURL + refactor.forum}/jdc/api/auction/notice`, // 查询商城公告
    getForumList: `${baseURL + refactor.forum}/jdc/api/forum/forumDetail`, // 查询圈子列表
    getQrcode: `${baseURL + refactor.forum}/jdc/api/group/qrCode`, // 查询圈子二维码信息
    getFirstRecommend: `${baseURL + refactor.forum}/jdc/api/group/firstrecommend`, // 推荐圈子弹窗
    getGroupInfoext: `${baseURL + refactor.forum}/jdc/api/forum/getgroupinfoext`, // 查询圈子扩展信息
    getGroupPlaza: `${baseURL + refactor.forum}/jdc/api/group/plaza`, // 查询圈子广场
    getForumSeting: `${baseURL + refactor.forum}/jdc/api/manage/groupManage/getForumSetting`, // 查询圈子设置信息
    getForumBanner: `${baseURL + refactor.forum}/jdc/api/manage/groupManage/forumBanner`, // 查询圈子banner
    forumInitialData: `${baseURL + refactor.forum}/jdc/api/inviteGroup/inviteInitialData`, // 圈子邀请-邀请初始数据
    forumInviteExcel: `${baseURL + refactor.forum}/jdc/api/inviteGroup/inviteExcel`, // 圈子邀请-excel导入
    changeForumSetting: `${baseURL + refactor.forum}/jdc/api/manage/groupManage/changeForumSetting`, // 修改圈子信息
    emailShield: `${baseURL + refactor.forum}/jdc/api/forum/mailBlock`, // 邮箱屏蔽
    forumBannerUpload: `${baseURL + refactor.forum}/jdc/api/manage/groupManage/forumBannerUpload`, // 图片上传
    forumBannerSubmit: `${baseURL + refactor.forum}/jdc/api/manage/groupManage/forumBannerSubmit`, // 提交圈子封面表单
    joinGroupCheck: `${baseURL + refactor.forum}/jdc/api/forum/joinGroupCheck`, // 圈子广场-加圈校验
    recommendJoinGroup: `${baseURL + refactor.forum}/jdc/api/forum/recommendJoinGroup`, // 推荐加圈
    auctionHot: `${baseURL + refactor.forum}/jdc/api/auction/hot`, // 热门交易排行榜
    auctionCount: `${baseURL + refactor.forum}/jdc/api/user/auction/count`, // 发布礼品和兑换记录统计
    membercheck: `${baseURL + refactor.forum}/jdc/api/manage/groupManage/membercheck`, // 成员审核-待审核列表
    memberList: `${baseURL + refactor.forum}/jdc/api/manage/groupManage/memberList`, // 成员审核-审核列表
    attachmentcheck: `${baseURL + refactor.forum}/jdc/api/manage/groupManage/attachmentcheck`, // 附件审核-获取删除原因列表
    attachmentchecklist: `${baseURL + refactor.forum}/jdc/api/manage/groupManage/attachmentchecklist`, // 附件审核-附件审核列表
    checkAttachmement: `${baseURL + refactor.forum}/jdc/api/manage/groupManage/checkAttachmement`, // 附件审核提交
    memberdetail: `${baseURL + refactor.forum}/jdc/api/manage/groupManage/memberdetail`, // 查看申请信息详情
    approvalMember: `${baseURL + refactor.forum}/jdc/api/manage/groupManage/approvalMember`, // 成员审核-审核记录批处理
    groupDemiseList: `${baseURL}${refactor.forum}/jdc/api/manage/groupManage/groupDemiseList`, // 获取允许转让人员列表
    groupDemiseSubmit: `${baseURL}${refactor.forum}/jdc/api/manage/groupManage/groupDemiseSubmit`, // 提交圈子转让
    threadCopy: `${baseURL}${refactor.forum}/jdc/api/manage/groupManage/threadCopy`, // 圈子转载
    getManageInfo: `${baseURL}${refactor.forum}/jdc/api/manage/groupManage/manageUser/init`, // 查询圈子初始数据
    manageUser: `${baseURL}${refactor.forum}/jdc/api/manage/groupManage/manageUser`, // GET:查询圈子成员列表，POST:设置成员权限
    manageRemark: `${baseURL}${refactor.forum}/jdc/api/manage/groupManage/manageUser/remark`, // GET:获取历史备注，POST:设置备注
    updateDesc: `${baseURL}${refactor.forum}/jdc/api/manage/groupManage/forumSection/updateDesc`, // 编辑简介
    searchForumUser: `${baseURL}${refactor.forum}/jdc/api/manage/groupManage/forumSection/manageUser/userSearch`, // 版块-搜索成员
    addForumUser: `${baseURL}${refactor.forum}/jdc/api/manage/groupManage/forumSection/manageUser/userAdd`, // 版块-添加成员
    delForumUser: `${baseURL}${refactor.forum}/jdc/api/manage/groupManage/forumSection/manageUser/userDel`, // 版块-删除成员
    getForumSetting: `${baseURL}${refactor.forum}/jdc/api/manage/groupManage/getForumSetting`, // 获取圈子设置
    createGroup: `${baseURL}${refactor.forum}/jdc/api/manage/groupManage/groupCreate`, // 创建圈子
    manageMenu: `${baseURL}${refactor.forum}/jdc/api/manage/groupManage/manageMenu`, // 管理菜单权限
    queryPostTemplates: `${baseURL}${refactor.forum}/jdc/api/manage/groupManage/queryPostTemplates`, // 发帖模板查询
    queryThreadType: `${baseURL}${refactor.forum}/jdc/api/manage/groupManage/queryThreadType`, // 主题分类查询
    updateThreadType: `${baseURL}${refactor.forum}/jdc/api/manage/groupManage/updateThreadType`, // 主题分类设置
    getGroupList: `${baseURL}${refactor.forum}/jdc/api/group/list`, // 当前已启用且用户已加入的所有圈子
    getRecProductList: `${baseURL}${refactor.forum}/jdc/api/product/recProductList`, // 获取推荐页智豆兑换
    filterFields: `${baseURL}${refactor.forum}/jdc/api/forum/oriReport/filterFields`, // 原创报告 筛选条件 领域
    filterIndustries: `${baseURL}${refactor.forum}/jdc/api/forum/oriReport/filterIndustries`, // 原创报告 筛选条件 行业
    getSideBar: `${baseURL}${refactor.forum}/jdc/api/forum/oriReport/sideBar`, // 原创报告侧边栏
  },
  post: {
    // 帖子模块
    pollVote: `${baseURL + refactor.post}/jdc/api/newthread/vote/pollVote`, //  post 投票
    recovery: `${baseURL + refactor.post}/jdc/api/manage/postManage/sensitiveWord/recovery`,
    recoveryList: `${baseURL + refactor.post}/jdc/api/manage/postManage/sensitiveWord/recoveryList`, // 帖子详情页恢复屏蔽
    postNewThread: `${baseURL + refactor.post}/jdc/api/thread/postNewThread`, // 发帖权限判断
    postSave: `${baseURL + refactor.post}/jdc/api/post/save`, // 发帖
    postSwfUpload: `${baseURL + refactor.post}/jdc/api/thread/swfUpload`, // 上传附件和视频
    postDeleteAttach: `${baseURL + refactor.post}/jdc/api/thread/deleteAttach`, //  删除附件信息
    postUpload: `${baseURL + refactor.post}/jdc/api/common/upload`, //  内容和封面上传图片
    getRecentTag: `${baseURL + refactor.post}/jdc/api/thread/recentTag`, //  获取最近使用标签
    getBreadcrumb: `${baseURL + refactor.post}/jdc/api/thread/getBreadcrumb`, //  获取面包屑导航
    lotteryInfo: `${baseURL + refactor.post}/jdc/api/thread/lotteryInfo`, // 抽奖信息
    getTemplate: `${baseURL + refactor.post}/jdc/api/thread/template`, // 获取模板贴
    postreport: `${baseURL + refactor.post}/jdc/api/newthread/postreport`, // 举报
    postVoter: `${baseURL + refactor.post}/jdc/api/post/voter`, // 获取投票人信息
    threadVote: `${baseURL + refactor.post}/jdc/api/thread/vote`, // 投票
    pollupload: `${baseURL + refactor.post}/jdc/api/newthread/pollupload`, // 投票贴上传图片
    getPostDetail: `${baseURL + refactor.post}/jdc/api/forum/queryDetail`, // 编辑帖子查询帖子详情
    getAuthor: `${baseURL + refactor.post}/jdc/api/newthread/getAuthor`, // 获取作者详细信息
    postDemandRR: `${baseURL + refactor.post}/jdc/api/newthread/demandRR`, // 查询pms数据
    getThreadList: `${baseURL + refactor.post}/jdc/api/thread/indexlist`, // 查询首页最新动态/有新的回复/专家互动/邀请回答/有人@了你
    threadLastVisit: `${baseURL + refactor.post}/jdc/api/thread/threadlastvisit`, // 未读帖子消息标记为已读功能
    draft: `${baseURL + refactor.post}/jdc/api/post/draft`, // 查询和保存草稿
    postEditSave: `${baseURL + refactor.post}/jdc/api/forum/updateDetail`, // 编辑帖子保存
    getExgHistory: `${baseURL + refactor.post}/jdc/api/post/commodityHistory`, // 查询商品兑换历史
    rollbackPrice: `${baseURL + refactor.post}/jdc/api/post/offerPrice/rollback`, // 商品智豆退还操作
    rollbackRecord: `${baseURL + refactor.post}/jdc/api/post/offerPrice/rollbackRecord`, // 查询商品智豆退还操作记录
    getPreview: `${baseURL + refactor.post}/jdc/api/post/preview`, // 查询帖子预览
    getNologinIndex: `${baseURL + refactor.post}/jdc/api/thread/indexlist`, // 查询首页未登录列表
    reply: `${baseURL + refactor.post}/jdc/api/post/reply`, // 回复
    collect: `${baseURL + refactor.post}/jdc/api/newthread/favorite/insert`, // 收藏
    cancelCollect: `${baseURL + refactor.post}/jdc/api/newthread/favorite/delete`, // 取消收藏
    comment: `${baseURL + refactor.post}/jdc/api/post/comment`, // 评论
    getTopicInfo: `${baseURL + refactor.post}/jdc/api/newThread/getTopicInfo`, // 帖子主题信息功能
    getOfferPrice: `${baseURL + refactor.post}/jdc/api/post/getOfferPrice`, // 获取出价信息
    postOfferPrice: `${baseURL + refactor.post}/jdc/api/post/offerPrice`, // 出价
    postReply: `${baseURL + refactor.post}/jdc/api/post/reply`, // 回复&用户提交问卷
    getThreadDetail: `${baseURL + refactor.post}/jdc/api/newthread/getThreadDetail`, // 获取帖子详情
    getThreadShare: `${baseURL + refactor.post}/jdc/api/newthread/getShare`, // 查询帖子分享数据
    postSetShare: `${baseURL + refactor.post}/jdc/api/newthread/setShare`, // 设置帖子分享数据
    PostComment: `${baseURL + refactor.post}/jdc/api/post/comment`, // 评论
    GetAddLikes: `${baseURL + refactor.post}/jdc/api/post/recommend/addRecommend`, // 回复点赞
    GetCancelLikes: `${baseURL + refactor.post}/jdc/api/post/recommend/cancelRecommend`, // 回复取消点赞
    GetAuctionDetail: `${baseURL + refactor.post}/jdc/api/auction/`, // 商品帖详情
    GetSurveyDetail: `${baseURL + refactor.post}/jdc/api/survey/getDetail`, // 问卷调查帖
    getPostComments: `${baseURL + refactor.post}/jdc/api/newthread/getPostComments`, // 评论分页查询
    GetSearchByGroup: `${baseURL + refactor.post}/jdc/api/search/searchbygroup`, // 综合搜索
    GetIndexList: `${baseURL + refactor.post}/jdc/api/thread/userindexlist`, // 首页登录用户最新动态/有新的回复/专家互动
    deleteThread: `${baseURL}${refactor.post}/jdc/api/thread/deleteThread`, //  POST 删除主题
    updatedDigest: `${baseURL}${refactor.post}/jdc/api/manage/postManage/setDigest`, // POST 精华
    updatedType: `${baseURL}${refactor.post}/jdc/api/manage/postManage/updatedType`, // POST 修改分类
    closeThread: `${baseURL}${refactor.post}/jdc/api/manage/postManage/thread/moderate/close`, // POST 关闭主题
    openThread: `${baseURL}${refactor.post}/jdc/api/manage/postManage/thread/moderate/open`, // POST 打开主题
    warnPost: `${baseURL}${refactor.post}/jdc/api/manage/postManage/warn`, // GET 获取警告 POST 警告
    warnList: `${baseURL}${refactor.post}/jdc/api/manage/postManage/warn/list`, // GET 获取警告记录
    cancelWarnPost: `${baseURL}${refactor.post}/jdc/api/manage/postManage/warn/cancel`, // POST 取消警告
    stickPost: `${baseURL}${refactor.post}/jdc/api/manage/postManage/stick`, // POST 置顶
    stampPost: `${baseURL}${refactor.post}/jdc/api/manage/postManage/stamp`, // POST 图章
    highlightPost: `${baseURL}${refactor.post}/jdc/api/manage/postManage/highlight`, // POST 高亮
    threadExport: `${baseURL}${refactor.post}/jdc/api/manage/postManage/threadExport`, // GET 导出
    getCreateAuctionInfo: `${baseURL}${refactor.post}/jdc/api/thread/getCreateAuctionInfo`, // GET 获取兑换信息
    getVeriMedalList: `${baseURL}${refactor.post}/jdc/api/manage/postManage/select/getMedalList`, // GET 特定勋章持有者
    getInvite: `${baseURL}${refactor.post}/thread/invite/recommend`, // 邀请回答
    searchInvite: `${baseURL}${refactor.post}/thread/invite/search`, // 邀请回答搜索
    invite: `${baseURL}${refactor.post}/thread/invite`, // 邀请
    inviteHistoryUrl: `${baseURL}${refactor.post}/thread/invite/history`, //  已邀请的用户
    getApiBanner: `${baseURL}${refactor.post}/api/banner`, // 活动页获取banner
    getBannerTypes: `${baseURL}${refactor.post}/api/getBannerTypes`, // 活动页获取banner类型
    threadPost: postId => `${baseURL}${refactor.post}/thread/${postId}`, // GET 获取帖子详情， POST 操作帖子
    deleteThreadPost: postId => `${baseURL}${refactor.post}/thread/delete/${postId}`, // POST 删除帖子
    deleteComment: commentId => `${baseURL}${refactor.post}/thread/comment/${commentId}`, // DELETE 删除评论
    threadComment: `${baseURL}${refactor.post}/thread/comment`, // GET 获取评论 POST 回复评论
    getRecommendThread: postId => `${baseURL}${refactor.post}/thread/${postId}/detail/recommend`, // GET 获取随机推荐的文章
    threadCategory: `${baseURL}${refactor.post}/thread/category`, // GET 获取栏目   POST 添加栏目
    deleteCategory: id => `${baseURL}${refactor.post}/thread/category/delete/${id}`, // POST 删除栏目
    uploadCover: `${baseURL}${refactor.post}/jdc/api/post/upload/cover`, // POST 上传商品封面
    downloadCover: `${baseURL}${refactor.post}/jdc/api/post/download/cover`, // GET 下载商品封面
    saveAuction: `${baseURL}${refactor.post}/jdc/api/manage/postManage/save/auction`, // POST 发布兑换帖
    editAuction: `${baseURL}${refactor.post}/jdc/api/manage/postManage/edit/auction`, // POST 编辑兑换帖，GET 获取兑换帖信息
    getHistoryAuctionTag: `${baseURL}${refactor.post}/jdc/api/auction/getHistoryAuctionTag`, // GET 获取礼品标签历史记录

    getUserlabel: `${baseURL}${refactor.post}/jdc/api/manage/postManage/getUserlabel`, // GET 获取用户标签
    setUserlabel: `${baseURL}${refactor.post}/jdc/api/manage/postManage/setUserlabel`, // POST 设置用户标签
    getForwardForum: `${baseURL}${refactor.post}/jdc/api/manage/postManage/forward/forum`, // GET 获取可转载的圈子、版块
    forwardForum: `${baseURL}${refactor.post}/jdc/api/manage/postManage/forward`, // POST 帖子转载
    cancelForwardForum: `${baseURL}${refactor.post}/jdc/api/manage/postManage/forward/cancel`, // POST 取消帖子转载
    sendWechatInvite: `${baseURL}${refactor.post}/jdc/api/manage/postManage/wechatInvite/send`, // POST 发送微信邀请
    uploadWechatInvite: `${baseURL}${refactor.post}/jdc/api/manage/postManage/wechatInvite/upload`, // POST 批量导入微信邀请
    exportWechatInvite: `${baseURL}${refactor.post}/jdc/api/manage/postManage/wechatInvite/export`, // POST 导出微信邀请结果
    exportExcelWechatInvite: `${baseURL}${refactor.post}/jdc/api/manage/postManage/wechatInvite/expExcelRes`, // POST 导出批量微信邀请结果
    downloadTemplate: `${baseURL}${refactor.post}/jdc/api/manage/postManage/wechatInvite/downloadTemplate`, // GET 导出微信邀请模板
    appreciationInfo: `${baseURL}${refactor.post}/jdc/api/manage/postManage/appreciation/info`, // GET 获取赞赏基本信息
    appreciate: `${baseURL}${refactor.post}/jdc/api/manage/postManage/appreciation/appreciate`, // POST 赞赏
    cancelAppreciate: `${baseURL}${refactor.post}/jdc/api/manage/postManage/appreciation/cancel`, // POST 撤销赞赏
    getAllThreadTag: `${baseURL}${refactor.post}/jdc/api/post/tag/getAllThreadTag`, // GET 获取全部标签
    getByName: `${baseURL}${refactor.post}/jdc/api/post/tag/getByName`, // GET 按名称获取标签
    getTagThread: `${baseURL}${refactor.post}/jdc/api/post/tag/getTagThread`, // GET 按标签获取帖子
    award: `${baseURL}${refactor.post}/api/award`, // 颁奖活动
    awardPrizeLevel: `${baseURL}${refactor.post}/api/award/prizelevel`, // 颁奖活动奖项等级
    awardId: `${baseURL}${refactor.post}/api/award/detail`,
    awardTemplateId: `${baseURL}${refactor.post}/api/award/templateId`, // 获取颁奖模板id
    getCrimeInfo: `${baseURL}${refactor.post}/jdc/api/thread/getCrimeInfo`, // GET 获取用户违规信息
    deletePostReply: `${baseURL}${refactor.post}/jdc/api/thread/deleteReply`, // POST 删除帖子回复
    deletePostComment: `${baseURL}${refactor.post}/jdc/api/thread/deleteComment`, // POST 删除帖子评论
    getMovableForum: `${baseURL}${refactor.post}/jdc/api/manage/postManage/getMovableForum`, // GET 获取可移动的版块
    moveThread: `${baseURL}${refactor.post}/jdc/api/manage/postManage/moveThread`, // POST 移动帖子
    getOperateRecord: `${baseURL}${refactor.post}/jdc/api/manage/postManage/getOperateRecord`, // GET 获取帖子操作记录
    getAppRecommendedBanner: `${baseURL}${refactor.post}/jdc/api/post/getAppRecommendedBanner`, // GET 获取轮播图
    getThreadTypes: `${baseURL}${refactor.post}/jdc/api/manage/postManage/threadTypes`, // GET 获取帖子分类(无加圈校验等逻辑)
    getHotTopics: `${baseURL}${refactor.post}/jdc/api/thread/getHotTopics`, // GET H5 获取推荐列表
    updateSelectTypes: `${baseURL}${refactor.post}/jdc/api/post/updateSelectTypes`, // Post H5 更新选择分类
    queryFavorite: `${baseURL}${refactor.post}/jdc/api/newthread/favorite/query`, // 获取收藏列表
    awardPresentation: `${baseURL}${refactor.post}/api/award/presentation`, // 查询颁奖详情
    getProductModel: `${baseURL}${refactor.post}/jdc/api/post/getProductModel`, // 获取产品型号
    getProductIndustry: `${baseURL}${refactor.post}/jdc/api/post/getProductIndustry`, // 获取产品行业
    getReceiveinfo: `${baseURL}${refactor.post}/api/award/post/receiveinfo`, // 获取领奖信息
    receiveAward: `${baseURL}${refactor.post}/api/award/post/receive`, // 领奖
    getValuableProductThread: `${baseURL}${refactor.post}/jdc/api/newthread/getValuableProductThread`, // 获取首页banner滚动
    getProductSuggestionMessage: `${baseURL}${refactor.post}/jdc/api/newthread/getProductSuggestionMessage`, // 获取产品建议推荐文案
    updateUemData: `${baseURL}${refactor.post}/jdc/api/uem/updateUemData`, // uem 数据更新
    postInviteProductSuggestion: `${baseURL}${refactor.post}/jdc/api/newthread/postInviteProductSuggestion`, // 提交产品建议转交处理
    postProductSuggestion: `${baseURL}${refactor.post}/jdc/api/newthread/postProductSuggestion`, // 提交产品建议状态处理
    replyTop: `${baseURL}${refactor.post}/jdc/api/manage/postManage/newthread/replyTop`, // 置顶回复
    getShield: `${baseURL}${refactor.post}/jdc/api/manage/postManage/newthread/getShield`, // 获取屏蔽信息
    setShield: `${baseURL}${refactor.post}/jdc/api/manage/postManage/newthread/setShield`, // 更新屏蔽信息
    getLongLink: `${baseURL}${refactor.post}/jdc/api/excel/getLongLink`, // 获取长链接
    getRecommendList: `${baseURL}${refactor.post}/jdc/api/thread/recommendList`, // 获取推荐其他问卷
    getSelected: `${baseURL}${refactor.post}/api/banner/selected`, // 获取活动标签列表
    recordAnswerTime: `${baseURL}${refactor.post}/jdc/api/answerTime/record`, // 记录答题时间
    queryAnswerTime: `${baseURL}${refactor.post}/jdc/api/answerTime/query`, // 查询用户答题时间
    getLatestGifts: `${baseURL}${refactor.post}/jdc/api/mall/getLatestGifts`, // 最新礼品
    getThreadHotListData: `${baseURL}${refactor.post}/jdc/api/hotList/getThreadHotListData`, // 获取帖子热榜
    getBonusHotListData: `${baseURL}${refactor.post}/jdc/api/hotList/getBonusHotListData`, // 获取智豆获得榜单
    getUserAndPostLvInfo: `${baseURL}${refactor.post}/jdc/api/post/getUserInfos`, // 获取帖子详情页用户等级
    getJwtToken: `${baseURL}${refactor.post}/jdc/api/newthread/postreport/sign`, // 获取举报中心所需token
    complaintsGetToken: `${baseURL}${refactor.post}/jdc/api/newthread/postreport/api/v2/h5/complaints/getToken`, // H5举报中心获取token
    complaintsSubmit: `${baseURL}${refactor.post}/jdc/api/newthread/postreport/api/v2/h5/complaints/submit`, // H5举报中心提交
    querySpecifyLinkInWhite: `${baseURL}${refactor.post}/jdc/api/querySpecifyLinkInWhite`, //校验指定链接是否在白名单中
    createOriginalReport: `${baseURL}${refactor.post}/jdc/api/post/createOriginalReport`, // 创建原创报告
    updateOriginalReport: `${baseURL}${refactor.post}/jdc/api/forum/updateOriginalReport`, // 更新原创报告
    getSurveyReport: `${baseURL}${refactor.post}/jdc/api/newthread/getSurveyReport`, // 原创报告 - 查询调研报告
    getSurveyReportDetail: `${baseURL}${refactor.post}/jdc/api/newthread/getSurveyReportDetail`, // 原创报告 - 获取报告详情
    getPreviewEdmToken: `${baseURL}${refactor.post}/jdc/api/common/getPreviewEdmToken`, // 获取附件预览所需token
    uploadEdm: `${baseURL}${refactor.post}/jdc/api/thread/uploadEdm`, // 原创报告 - 上传附件
    downloadPreviewImage: `${baseURL}${refactor.post}/jdc/api/common/downloadPreviewImage`, // 原创报告 - 下载预览附件图片
    getFileFormatImageStatus: `${baseURL}${refactor.post}/jdc/api/thread/getFileFormatImageStatus`, // 是否转换图片
    getContributors: `${baseURL}${refactor.post}/jdc/api/post/originalReport/contributors`, // 原创报告获取贡献用户
    addSearchLog: `${baseURL}${refactor.post}/jdc/api/searchLog/addSearchLog`, // 添加搜索记录
  },
  task: {
    // 任务模块
    postSign: `${baseURL + refactor.task}/jdc/api/sign/sign`, // 签到
  },
  message: {
    // 消息模块
    getHistoryMsg: `${baseURL + refactor.message}/jdc/api/message/history`, // 查询私信列表
    postSendMsg: `${baseURL + refactor.message}/jdc/api/message/send`, // 发私信
    getFriend: `${baseURL + refactor.message}/jdc/api/message/list`, // 查询私信好友列表
    getNotice: `${baseURL + refactor.message}/jdc/api/user/mynotice`, // 查询消息通知
    setPushNotification: `${baseURL}${refactor.message}/jdc/api/app/notificationConfig`, // 推送通知设置
  },
  column: {
    // 专栏模块
    followStatus: `${baseURL + refactor.column}/jdc/api/follow/status`, // 获取专栏状态
    attentionsFollow: `${baseURL + refactor.column}/jdc/api/follow`, // 获取我关注的专栏
    rateOptions: `${baseURL + refactor.column}/jdc/api/rate/options`, // 获取当日剩余赞赏智豆
    rateRecord: `${baseURL + refactor.column}/jdc/api/rate/record`, // 获取概要和赞赏记录列表
    columnFollow: `${baseURL + refactor.column}/jdc/api/follow`, // 对专栏文章进行点赞、关注
    columnRate: `${baseURL + refactor.column}/jdc/api/rate`, // 赞赏弹出框确定
  },
  lottery: {
    user: `${baseURL}${refactor.post}/jdc/lottery/user`, // GET 获取用户信息，POST 提交用户信息
    list: `${baseURL}${refactor.post}/jdc/lottery/list`, // GET 获取页面详情信息
    award: `${baseURL}${refactor.post}/jdc/lottery/award`, // GET 抽奖入口
    userRecord: `${baseURL}${refactor.post}/jdc/lottery/user/record`, // GET 获取个人抽奖记录
    userTimes: `${baseURL}${refactor.post}/jdc/lottery/user/times`, // GET 获取用户抽奖次数
    record: `${baseURL}${refactor.post}/jdc/lottery/record`, // GET 获取滚动名单
    grant: `${baseURL}${refactor.post}/jdc/lottery/grant`, // POST 单个授权用户抽奖
    exchange: id => `${baseURL}${refactor.post}/jdc/lottery/exchange/${id}`, // GET 抽奖奖品兑换
    limitTimes: id => `${baseURL}${refactor.post}/jdc/lottery/${id}/trustList/limitTimes`,
  },
  gateway: {
    getToken: `${refactor.gateway}/jdc/api/csrftoken`, // 获取token
  },
};

export default refactorApi;
