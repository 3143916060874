/**
 * @file 问卷模块国际化，英文
 */
export default {
  single: 'Single Answer',
  multiple: 'Multiple Answer',
  orderRank: 'Order Ranking',
  paragraph: 'Open-ended',
  shortAnswer: 'Short Answer',
  minSelect: ' ({value} options are required)',
  other: 'Others',
  submit: 'Submit',
  submited: 'Submitted',
  expired: 'Expired',
  pleaseSpecify: 'Please specify',
  uploadImgTips: 'Add Pictures',
  required: 'Required.',
  submitting: 'Submitting',
  joinGroupTip: 'Only group members can submit answers.',
  openPlaceholder: 'Providing detailed responses increases your chances of winning exciting gifts.',
  upload: 'Upload',
  uploadTips: 'A maximum of 9 images can be up and must be in the following format:JPG, JPEG, PNG, and GIF.',
  answerShortTips: 'Answer duration: The user spent {time}, which is {limit}% faster than the average time {averageTime}.',
  answerTips: 'Answer duration: The user spent {time}.',
  questionIsMultiAnswer: 'Question {num} is a multiple-answer question ({size} options are required). Please answer the question correctly and then submit.',
  questionIsMultiAnswerWithOne: 'Question {num} is a multiple-answer question (1 option is required). Please answer the question correctly and then submit.',
  questionMultiOnlyAnswer:
    'Question {num} is a multiple-answer question (only {size} options are required). Please answer the question correctly and then submit.',
  questionBlank: 'Question {num} is required, please complete all blanks!',
  questionNotComplete: 'The mandatory items of question {num} are not complete. Please answer them after completing them.',
  pollExpired: 'Sorry, but this poll has expired. Check out some other ones.',
  alreadySubmitted: 'You have already submitted your answers.',
  luckyDrawChance: 'Congratulations! You have earned a lucky draw chance.',
  drawNow: 'Go Draw Now',
  notComplete: 'The mandatory items of question {num} are not complete.',
  fillBlanks: 'Please complete the blanks.',
  onlyGorupMembers: 'Only group members can submit answers.',
  expiredToOthers: 'Sorry, but this poll has expired. Check out some other ones.',
};
