/*
 * @Author:
 * @Date: 2022-11-16 11:12:02
 * @Description:
 */
import env from '@/config/env.js';
import DateUtils from "@/utils/common/DateUtils";
const COLUMN_TYPE = {
  column: 6,
  post: 7,
};
const OPERATE_TYPE = {
  follow: 1,
  collection: 2,
  like: 3,
};
const ACTION_TYPE = {
  cancel: 0,
  ok: 1,
};

const SLIDER_DIREACTION = {
  up: 1,
  down: 2,
  left: 3,
  right: 4,
  none: 0,
};

const getStationId = () => {
  if (env.currentEnv === 'prod') {
    return 200061;
  } else {
    return 2548;
  }
};
const getTabsId = tabType => {
  if (env.currentEnv === 'prod') {
    const typeObj = {
      activitypage: 200515, // 活动
      highdiscussion: 200633, // 精华讨论
      productsuggestions: 200029, // 提产品建议
      interactivediscussion: 200641, // 互动讨论
      originality: 200642, // 创作者计划
    };
    return typeObj[tabType];
  } else {
    const typeObj = {
      activitypage: 1259, // 活动
      highdiscussion: 200499, // 精华讨论
      productsuggestions: 200501, // 提产品建议
      interactivediscussion: 200519, // 互动讨论
      originality: 200517, // 创作者计划
    };
    return typeObj[tabType];
  }
};
// 导航栏标题
const getTitleType = type => {
  switch (type) {
    case 'like':
      return '点赞';
    case 'system':
      return '系统通知';
    case 'invite':
      return '邀请回答';
    case 'at':
      return '@我';
    default:
      return '回复';
  }
};

// 私信页面图标
const typeImageMapping = {
  reply: require('../../../public/static_vue/miniProgramImg/wximage/message/reply.jpeg'),
  like: require('../../../public/static_vue/miniProgramImg/wximage/message/like.jpeg'),
  at: require('../../../public/static_vue/miniProgramImg/wximage/message/at.jpeg'),
  invite: require('../../../public/static_vue/miniProgramImg/wximage/message/invite.jpeg'),
  system: require('../../../public/static_vue/miniProgramImg/wximage/message/system.jpeg'),
  wisdom: require('../../../public/static_vue/miniProgramImg/wximage/message/wisdom.jpeg'),
};

// 私信页时间处理
const showDate = (val) => {
  const today = DateUtils.getShortDate()
  const todayTimestamp = Math.round(new Date(today).getTime() / 1000)
  const curYear = today.split('-')[0]
  const year = new Date(val * 1000).getFullYear()
  if (val >= todayTimestamp) {
    return DateUtils.dateFormat(new Date(val * 1000), 'hh:mm')
  }
  if (curYear > year) {
    return DateUtils.dateFormat(new Date(val * 1000), 'yyyy-MM-dd hh:mm')
  }
  return DateUtils.dateFormat(new Date(val * 1000), 'MM-dd hh:mm')
};

// 获取智豆记录--前面部分
const getBeforeMsg = type => {
  if (type.includes('thread')) {
    return '你的帖子';
  } else if (type.includes('post')) {
    return '你的回复';
  } else if (type.includes('comment')) {
    return '你的评论';
  } else if (type.includes('rollback')) {
    return '您兑换的';
  }
  return '';
};

// 获取智豆记录--中间部分
const getCenterMsg = item => {
  const { type } = item;
  if (type.includes('thread')) {
    return item.threadTitle;
  }
  if (type.includes('rollback')) {
    return item.threadTitle;
  }
  return item.content;
};

// 获取智豆记录--后面部分
const getAfterMsg = type => {
  let recall = '';
  if (type.includes('remove')) {
    recall = '撤销';
  }
  if (type.includes('rollback')) {
    return '礼品，智豆已被退还';
  }
  return `被${recall}赞赏`;
};

const getLevelStyle = level => {
  const data = {};
  switch (true) {
    case level < 5:
      data.imgUrl = require('@/assets/h5/profile/level1.png');
      data.color = '#79d19a';
      break;
    case level < 9:
      data.imgUrl = require('@/assets/h5/profile/level2.png');
      data.color = '#5ccdff';
      break;
    case level < 13:
      data.imgUrl = require('@/assets/h5/profile/level3.png');
      data.color = '#e6a7f5';
      break;
    case level < 17:
      data.imgUrl = require('@/assets/h5/profile/level4.png');
      data.color = '#faa4a4';
      break;
    default:
      data.imgUrl = require('@/assets/h5/profile/level5.gif');
      data.color = '#fec52d';
      break;
  }
  return data;
};
/**
 * 获取url中的参数
 * @param url
 * @param param
 * @returns {string}
 */
const getUrlParam = (url, param) => {
  const temp = url.split('?')[1];
  const getTid = new URLSearchParams(`?${temp}`);
  return getTid.get(param);
};

/**
 * 计算滑动角度
 * @param {number} dx - 滑动的x轴距离
 * @param {number} dy - 滑动的y轴距离
 * @return {number} 返回滑动角度，单位为度
 */
const getSlideAngle = (dx, dy) => {
  return (Math.atan2(dy, dx) * 180) / Math.PI;
};

/**
 * 根据起点和终点返回方向
 * @param {number} startX - 起点的x坐标
 * @param {number} startY - 起点的y坐标
 * @param {number} endX - 终点的x坐标
 * @param {number} endY - 终点的y坐标
 * @return {number} 返回方向，1：向上，2：向下，3：向左，4：向右,0：未滑动
 */
const getSlideDirection = (startX, startY, endX, endY) => {
  const dy = startY - endY;
  const dx = endX - startX;
  let result = SLIDER_DIREACTION.none;
  //如果滑动距离太短
  if (Math.abs(dx) < 2 && Math.abs(dy) < 2) {
    return result;
  }
  const angle = getSlideAngle(dx, dy);
  if (angle >= -45 && angle < 45) {
    result = SLIDER_DIREACTION.right;
  } else if (angle >= 45 && angle < 135) {
    result = SLIDER_DIREACTION.up;
  } else if (angle >= -135 && angle < -45) {
    result = SLIDER_DIREACTION.down;
  } else if ((angle >= 135 && angle <= 180) || (angle >= -180 && angle < -135)) {
    result = SLIDER_DIREACTION.left;
  }
  return result;
};

 /**
  * 是否是iPhone机型
  * @returns 
  */
const isIPhone = () => {
  return /iPhone|iPad|iPod/.test(window.navigator.userAgent);
};

export default {
  COLUMN_TYPE,
  OPERATE_TYPE,
  ACTION_TYPE,
  getStationId,
  getTabsId,
  getTitleType,
  getLevelStyle,
  getUrlParam,
  getSlideAngle,
  getSlideDirection,
  typeImageMapping,
  showDate,
  getBeforeMsg,
  getCenterMsg,
  getAfterMsg,
  isIPhone,
};
