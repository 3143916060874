export default {
  discussion: {
    discussion: 'Discussion',
    topicCategory: 'Topic Category',
    enterTopic: 'Enter a title',
    onlyHuawei: 'Only visible to Huawei employees',
  },
  template: {
    productModel: 'Product Model',
    industry: 'Industry',
    productIndustry: 'Product Industry',
    productDomain: 'Product Domain',
    mandatory: 'Mandatory',
    optional: 'Optional',
    atLeastThree: 'Enter at least three characters.',
    selectIndustries: 'Select industries.',
    loading: 'Loading…',
    overLength: 'Submission failed because the number of characters exceeds {0}.',
    getTemplateIDFailed: 'Get Template ID Failed',
  },
  topicTags: {
    industry: 'Industry',
    selectIndustry: 'Select Industies',
    selectDomain: 'Select Domain',
    selectProductDomain: 'Select Product Domain',
    coverSetting: 'Cover Settings',
    tag: 'Tag',
    tagCover: 'Tag&Cover',
    selectTag: 'Select Tags',
    tags: 'Tags',
    add: 'Add',
    customTags: 'Custom Tags',
    limitFiveTags: 'Use commas (,) to separate multiple tags. You can add up to five tags',
    recentTags: 'Recent tags',
    attachment: 'Attachment',
    limitXls: 'Note: A single file can be up to {fileSize} and must be in the following format: {fileType}',
    fileLimit: 'Only {fileType} format allowed. You can attach a file of up to {fileSize}.',
    fileSizeLimit: 'You can attach a file of up to {fileSize}.',
    videoLabel: 'Video',
    video: 'Select Video',
    limitVideo: 'A video can be up to {fileSize} and must be in {fileType} format.',
    limitVideoSize: 'You can attach a video of up to {fileSize}',
    noMoreVideoUpload: 'You can not upload more videos',
    maxUploadLimit: 'The maximum number of uploaded files is {maxLimit}',
    coverImage: 'Cover Image',
    supportedImageSize: 'An image must be in 800 x 450 pixels and in JPG, JPEG, or PNG format.',
    luckyDraw: 'Lucky Draw',
    luckyDrawChances: 'Lucky Draw Chances',
    atLeastRepliesLength: 'Replies must contain at least ',
    characters: 'characters',
    awardIssuing: 'Award Issuing',
    enterTag: 'Enter a tag.',
    supportedImg: 'Only JPG, JPEG, and PNG formats are supported.',
    deleteFailed: 'Deletion failed.',
    errorParameter: 'Incorrect lucky draw parameter.',
    autoSetting: 'Auto Configuration',
    singleImage: 'Single Picture',
    noImage: 'No Picture',
    reUpload: 'Re-upload',
    notChooseImageTips: 'Please upload the cover image first.',
    autoSettingTips: 'When using the [Auto Set] function to set the cover image, the first image will be grabbed from the post and cut into a suitable size.',
    uploadCoverImageDesc: 'A high-quality cover helps attract more readers.  JPEG, JPG, PNG, GIF (recommended cover size is 240*140px) is available.',
    uploadCoverImage: 'Upload',
  },
  thirdPartyTradeSecrets: {
    containThirdPartyTradeSecrets: 'Contain Third-Party Trade Secrets',
    thirdPartyTradeSecrets: 'Third-Party Trade Secrets',
    thirdPartyTradeSecretsDes:
      "Trade secrets refer to intellectual property rights on confidential technical and operations information that is commercially valuable. Examples include product pricing, roadmap planning, commercial authorization, core algorithms, and source code. If you have any questions, contact helponline{'@'}huawei.com.",
  },
  questionnaire: {
    preview: 'Preview',
    questionnaire: 'Questionnaire',
    multiOrderingQuestion: 'Multi-answer ordering question',
    dependsOnOptions: 'Depends on options 1 and 2 of question 1.',
    cancelAssociationToDelete: 'The question has been associated. Cancel the association before deleting it.',
    cancelAssociationToChangeNumber: 'Cancel the association before changing the sequence number.',
    positiveIntegerNumber: 'The sequence number must be a positive integer.',
    moreThanOneNumber: 'The sequence number cannot be smaller than 1. Enter another one.',
    uniqueNumber: 'The sequence number must be unique. Enter another one.',
    orderNumberEmpty: 'The sequence number cannot be empty.',
    blankQuestionnaire: 'The questionnaire is left blank. Click here to add questions.',
    addQuestion: 'Add Question',
    newQuestion: 'Add Question',
    validUntil: 'Valid Until',
    selectDate: 'Select a date and time.',
    note: 'Note: The questionnaire cannot be modified after being submitted. Please check it carefully.',
    currentNo: 'No.',
    type: 'Question Type',
    title: 'Question Title',
    enterTitle: 'Enter a question',
    enterTitleName: 'Enter a question name',
    cannotBlankTitle: 'Required.',
    description: 'Question Description',
    descriptionOver: 'Input exceeds the 2,000 character limit.',
    addBlank: 'Add Blank',
    options: 'Options',
    addOption: 'Add',
    blankOption: 'The option cannot be empty. Please check.',
    other: 'Others',
    requiredInput: 'Text input required',
    settings: 'Settings',
    mandatory: 'Mandatory',
    inputMandatoryOptions: 'Enter the number of options that must be selected',
    orderOptions: 'Option Ranking',
    associateQuestions: 'Add Associated Question',
    uploadImage: 'Upload image',
    addAssociatedQuestion: 'Add Associated Question',
    associatedQuestion: 'Associated Question',
    blankAssociatedQuestion: 'The associated question cannot be left blank.',
    associatedOptions: 'Associated Options',
    associatedQuestionRule: 'When you select any of the preceding options for Associated Question {index}, question {currentNum} appears.',
    threeUnderscores: 'Click to insert a blank space: ___',
    singleAnswer: 'Single Answer',
    selectSingleAnswer: "{'{'}Single Answer{'}'}",
    selectMultipleAnswer: "{'{'}Multiple Answer{'}'}",
    multipleAnswer: 'Multiple Answer',
    selectShortAnswer: "{'{'}Short Answer{'}'}",
    shortAnswer: 'Short Answer',
    selectParagraph: "{'{'}Open-ended{'}'}",
    paragraph: 'Open-ended',
    selectOrderRanking: "{'{'}Order Ranking{'}'}",
    orderRanking: 'Order Ranking',
    blank: 'Fill in the blank',
    question: 'Question',
    edit: 'Edit',
    overMaximumNumber: 'The maximum sequence number has been reached.',
    overMaximumQuestions: 'The maximum number of questions has been reached.',
    overMaximumOptions: 'The maximum number of options has been reached.',
    noQuestions: 'No questions can be associated. Please check and try again.',
    associatedQuestionsLimit: 'Up to 10 questions can be associated.',
    leastTwoOptions: 'A single-answer question must have at least two options.',
    leastThreeOptions: 'A multiple-answer question must have at least three options.',
    leastThreeUnderscores: 'Fill in the blank must contain at least one blank (three underscores).',
    duplicateOption: 'Duplicate option. Please check.',
    positiveInteger: 'Mandatory options must be a positive integer. ',
    mandatoryOptionsNumberError: 'The number of selected options exceeds the available choices.',
    background: '(Optional) Background',
    otherOption: 'Others',
    setPlaceholder: 'Set a placeholder',
    enterOption: 'Enter an option',
    enter: 'Enter',
    select: 'select',
    clickHere: 'Click here',
    blankQuestion: 'The questionnaire is left blank.',
    addQuestions: 'to add questions.',
    minSelects: ' ({minSelect} options are required)',
    minSelect: ' ({minSelect} option is required)',
    pleaseSpecify: 'Providing detailed responses increases your chances of winning exciting gifts.',
    otherEnterContent: 'Please specify',
    editIcon: 'Edit',
    delectIcon: 'Delete',
    no: 'No.',
    duplicate: 'Duplicate associate questions. Please check.',
    drag: 'drag',
    dragable: 'Drag and drop',
    dependOn: 'Question {relyIndex} depends on question {index}. The sequence can be adjusted after disassociation.',
    dependOnTip: 'If you drag to change the question sequence, the question association will be canceled and needs to be reconfigured. Are you sure you want to cancel the association?',
    disassociate: 'Disassociate the question?',
    disassociateBtn: 'Disassociate',
  },
  vote: {
    poll: 'Poll',
    eventID: 'Event ID',
    supportsOptions: 'Supports up to 80 options.',
    singleBox: 'Single box',
    eachLineOneOption: 'Enter one option in each line.',
    upload: 'Upload',
    delete: 'Delete',
    add: 'Add',
    showVotingResults: 'Show results after voting',
    showVoters: 'Show voters',
    uploadImage: 'Upload an image in JPEG, JPG, GIF, PNG, BMP, or OCTET-STREAM format.',
    imageLimit: 'The image cannot exceed 2 MB.',
    redeemPoints: 'Redeem Points',
    createExchange: 'Create Exchange',
    changedTopicCategoryTip: 'If the topic category is changed, all contents will be deleted. Continue?',
    createSurvey: 'Create Questionnaire',
    createPost: 'Create Post',
    expiredLogin: 'Your login has expired. Please log in again.',
    editPost: 'Edit Post',
    overMaximumTags: 'The maximum number of tags has been reached.',
    enterTags: 'Enter tags.',
    uploading: 'Uploading…',
    containsThirdPartyTradeSecrets: 'Sorry, but your file contains third-party trade secrets and cannot be published.',
    ifcontainsThirdPartyTradeSecrets: 'Please check if your content contains third-party commercial secrets below.',
    selectTopicCategory: 'Select a topic category.',
    topicCannotBeBlank: 'The topic cannot be left blank.',
    setMandatoryFields: 'Set mandatory fields.',
    requiredContentWarning: 'The content is required.',
    requiredGiftDescriptionWarning: 'The gift description is required.',
    pollDaysLimit: 'The poll days must be less than 365.',
    questionnaireCannotBeBlank: 'The questionnaire cannot be left blank.',
    leastTwoPollOptions: 'Sorry, but you must set at least two poll options.',
    pollOptionLimit: 'A poll option supports up to 255 characters.',
    optionsAndImagesCountError: 'Sorry, but the numbers of options and images must be the same.',
    surveyTime: 'Survey Time',
    deadline: 'Deadline',
    finished: 'Finished',
    imageSizeLimit: 'The supported image size is 800 x 450 pixels. Only JPG, JPEG, and PNG formats are allowed.',
    luckyDrawChancesTips:
      'You can earn lucky draw chances by replying to posts for the first time, joining in polls, and answering questionnaires. Lucky draw chances are automatically obtained based on the event ID.',
    selectOptionsLimit: 'Select up to {count} option | Select up to {count} options',
    pollDay: 'Poll for {count} day | Poll for {count} days',
    drawIdInvalid: 'Invalid event ID.',
    positiveInteger: 'Please Enter a positive Integer',
  },
};
