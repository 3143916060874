import i18n from '@/i18n/i18n';

const { t } = i18n.global;

const minute = 1000 * 60;
const hour = minute * 60;
const day = hour * 24;
const week = day * 7;
const month = day * 30;
const year = month * 12;

const dateFormat = function (date, fmt) {
  const o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds(), // 毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, `${date.getFullYear()}`.substr(4 - RegExp.$1.length));
  }
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : `00${o[k]}`.substr(`${o[k]}`.length));
    }
  }
  return fmt;
};

// 返回：时间差 lang:语言
const getTimeDiff = function (lang) {
  const timeZone = {
    ru: {
      // 俄语社区：莫斯科时间(东三区3)
      timeDiff: 3 * 3600000,
    },
    es: {
      // 西语社区：墨西哥时间(西六区-6)
      timeDiff: -6 * 3600000,
    },
    zh: {
      // 中文社区：北京时间(东八区8)
      timeDiff: 8 * 3600000,
    },
    en: {
      // 英文社区：英文社区的时间以0时区为准
      timeDiff: 0,
    },
    fr: {
      // 法语社区
      timeDiff: 1 * 3600000,
    },
  };
  if (lang) {
    return new Date().getTimezoneOffset() * 1000 * 60 + timeZone[lang].timeDiff;
  } else {
    // 默认为北京时间
    return new Date().getTimezoneOffset() * 1000 * 60 + timeZone.zh.timeDiff;
  }
};
const formatZero = function (val) {
  if (val < 10) {
    return `0${val}`;
  } else {
    return val;
  }
};
const zeroFix = monthOrDay => {
  if (parseInt(monthOrDay) < 10) {
    return `0${monthOrDay}`;
  }
  return monthOrDay;
};
export default {
  // 2019-02-23 (带语种分时区，不带语种以0时区为准)
  timestampToShortDate(timestamp, lang) {
    // 时区
    let timeOffset = 0;
    if (lang) {
      timeOffset = getTimeDiff(lang);
    }
    const date = new Date(timestamp * 1000 + timeOffset);
    let months = date.getMonth() + 1;
    let days = date.getDate();
    if (months < 10) {
      months = `0${months}`;
    }
    if (days < 10) {
      days = `0${days}`;
    }
    const dformat = [date.getFullYear(), months, days].join('-');
    return dformat;
  },

  // 2019/2/23
  timestampToNoZeroShortDate(timestamp, isZeroFix) {
    const d = new Date(timestamp * 1000);
    const months = d.getMonth() + 1;
    const days = d.getDate();
    let dformat = [d.getFullYear(), months, days].join('/');
    if (isZeroFix) {
      dformat = [d.getFullYear(), zeroFix(months), zeroFix(days)].join('/');
    }
    return dformat;
  },
  // 2019-2-23 18:11:10
  timestampToDate(timestamp) {
    if (!timestamp) {
      return '';
    }
    const ratio = timestamp.toString().length <= 10 ? 1000 : 1;
    const date = new Date(timestamp * ratio);
    const years = date.getFullYear();
    const months = date.getMonth() + 1;
    const days = date.getDate();
    const hours = formatZero(date.getHours());
    const minutes = formatZero(date.getMinutes());
    const seconds = formatZero(date.getSeconds());
    return `${years}-${months}-${days} ${hours}:${minutes}:${seconds}`;
  },
  // Feb 23, 2019 18:11:10
  // Feb 23, 2019 isShort=true
  timestampToDateEnMonth(timestamp, lang, isShort) {
    if (!timestamp) {
      return '';
    }
    const ratio = timestamp.toString().length <= 10 ? 1000 : 1;
    // 时区
    const timeOffset = getTimeDiff(lang);
    const date = new Date(timestamp * ratio + timeOffset);
    const EnMonth = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    const EsMonth = ['en.', 'febr.', 'mzo.', 'abr.', 'my.', 'jun.', 'jul.', 'ag.', 'set.', 'oct.', 'nov.', 'dic.'];
    const RuMonth = ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'];
    const langMonthMap = {
      es: EsMonth,
      ru: RuMonth,
      en: EnMonth,
    };
    return `${langMonthMap[lang || 'en'][date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}${
      isShort
        ? ''
        : ` ${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}:${
            date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()
          }`
    }`;
  },
  // 18:11
  timestampToTimeDate(timestamp) {
    if (!timestamp) {
      return '';
    }
    const ratio = timestamp.toString().length <= 10 ? 1000 : 1;
    const date = new Date(timestamp * ratio);
    return `${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}`;
  },
  // 2019-02-23 18:11 (带语种分时区，不带语种以0时区为准)
  timestampToNoSecondDate(timestamp, lang) {
    if (!timestamp) {
      return '';
    }
    const ratio = timestamp.toString().length <= 10 ? 1000 : 1;
    // 时区
    let timeOffset = 0;
    if (lang) {
      timeOffset = getTimeDiff(lang);
    }
    const date = new Date(timestamp * ratio + timeOffset);
    return `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${
      date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    } ${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}`;
  },
  // 12分钟前
  timestampToRelativeDate(timestamp) {
    if (!timestamp) {
      return '';
    }
    const ratio = timestamp.toString().length <= 10 ? 1000 : 1;
    let result = dateFormat(new Date(timestamp * ratio), 'yyyy-MM-dd');
    const diffValue = new Date().getTime() - timestamp * ratio;
    if (diffValue > 0) {
      const yearNum = diffValue / year;
      const monthNum = diffValue / month;
      const weekNum = diffValue / week;
      const dayNum = diffValue / day;
      const hourNum = diffValue / hour;
      const minuteNum = diffValue / minute;
      if (yearNum <= 1 && monthNum < 1 && weekNum < 1 && dayNum < 1) {
        if (hourNum >= 1) {
          // 非一小时内('X小时前')
          result = `${parseInt(hourNum)}小时前`;
        } else if (minuteNum >= 1) {
          // 非一分钟内('X分钟前')
          result = `${parseInt(minuteNum)}分钟前`;
        } else {
          // 一分钟内('刚刚')
          result = '刚刚';
        }
      }
    }
    return result;
  },

  getDiffResult(_diffValue) {
    let result = '';
    const _yearNum = _diffValue / year;
    const _monthNum = _diffValue / month;
    const _weekNum = _diffValue / week;
    const _dayNum = _diffValue / day;
    const _hourNum = _diffValue / hour;
    const _minuteNum = _diffValue / minute;
    if (_yearNum <= 1 && _monthNum < 1 && _weekNum < 1 && _dayNum < 1) {
      if (_hourNum >= 1) {
        // 非一小时内('X小时前')
        if (_hourNum === 1) {
          result = `${parseInt(_hourNum)} hour ago`;
        } else {
          result = `${parseInt(_hourNum)} hours ago`;
        }
      } else if (_minuteNum >= 1) {
        // 非一分钟内('X分钟前')
        if (_minuteNum === 1) {
          result = `${parseInt(_minuteNum)} minute ago`;
        } else {
          result = `${parseInt(_minuteNum)} minutes ago`;
        }
      } else {
        // 一分钟内('刚刚')
        result = 'Just now';
      }
    }
    return result;
  },
  // 12 minutes ago
  timestampToRelativeDate_En(timestamp) {
    if (!timestamp) {
      return '';
    }
    const _ratio = timestamp.toString().length <= 10 ? 1000 : 1;
    let result = dateFormat(new Date(timestamp * _ratio), 'yyyy-MM-dd');
    const _diffValue = new Date().getTime() - timestamp * _ratio;
    if (_diffValue > 0) {
      result = this.getDiffResult(_diffValue);
    }
    return result;
  },
  // 当天显示HH:MI , 当天之前显示 yy-mm-dd
  timestampToHHMI(timestamp, lang) {
    if (!timestamp) {
      return '';
    }
    const ratio = timestamp.toString().length <= 10 ? 1000 : 1;
    // 时区
    const timeOffset = getTimeDiff(lang);
    let result = dateFormat(new Date(timestamp * ratio + timeOffset), 'yyyy-MM-dd');
    const diffValue = new Date().getTime() - timestamp * ratio;
    if (diffValue > 0) {
      const yearNum = diffValue / year;
      const monthNum = diffValue / month;
      const weekNum = diffValue / week;
      const dayNum = diffValue / day;
      if (yearNum <= 1 && monthNum < 1 && weekNum < 1 && dayNum < 1) {
        const hours = new Date(timestamp * ratio + timeOffset).getHours();
        const mins = new Date(timestamp * ratio + timeOffset).getMinutes();
        result = `${hours >= 10 ? hours : `0${hours}`}:${mins >= 10 ? mins : `0${mins}`}`;
      }
    }
    return result;
  },
  // 获取某个月的总天数
  getDaysInOneMonth(years, months) {
    months = parseInt(months, 10);
    const d = new Date(years, months, 0);
    return d.getDate();
  },
  // 将单月时间戳装换成起止时间戳
  getOneMonthToStartEnd(date) {
    const years = date.getFullYear();
    const months = date.getMonth() + 1;
    const oneMonthDays = this.getDaysInOneMonth(years, months);
    const startDate = `${years}-${months > 10 ? months : `0${months}`}-01`;
    const endDate = `${years}-${months > 10 ? months : `0${months}`}-${oneMonthDays}`;
    const params = this.getSerachMsec(startDate, endDate);
    return params;
  },
  // 返回值：北京时间的秒数 参数：开始结束时间（格式：2019-01-01）
  getSerachMsec(start, end) {
    const startMsec = Date.parse(start) - 8 * 3600000;
    const endMsec = Date.parse(end) - 8 * 3600000 + 23 * 3600000 + 59 * 60000 + 59 * 1000;
    const params = {
      startTime: Math.floor(startMsec / 1000),
      endTime: Math.floor(endMsec / 1000),
    };
    return params;
  },
  // 返回值：2019-01-01格式的日期
  getShortDate(date) {
    let d = date || new Date();
    if (typeof d === 'string' && d.includes('-')) {
      // 兼容IOS
      d = new Date(d.replace(/-/g, '/'));
    } else if (typeof d !== 'object') {
      d = new Date(d);
    }
    let months = d.getMonth() + 1;
    let days = d.getDate();
    if (months < 10) {
      months = `0${months}`;
    }
    if (days < 10) {
      days = `0${days}`;
    }
    return [d.getFullYear(), months, days].join('-');
  },
  getDateDiffNowYear(diffValue, date, ieHours, isFullYear) {
    const monthNum = diffValue / month;
    const weekNum = diffValue / week;
    const dayNum = diffValue / day;
    const hourNum = diffValue / hour;
    const minuteNum = diffValue / minute;
    let result = '';
    // 非本月('MM-DD')
    if (monthNum >= 1) {
      if (ieHours) {
        result = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}:${
          date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
        }`;
      } else if (isFullYear) {
        result = `${date.getMonth() + 1}-${date.getDate()}`;
      } else {
        result = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      }
    } else if (weekNum >= 1) {
      // 非本周('X周前')
      result = t('lang.postDetails.someWeeksAgo', { count: parseInt(weekNum) });
    } else if (dayNum >= 1) {
      // 非今日('X天前')
      result = t('lang.postDetails.someDaysAgo', { count: parseInt(dayNum) });
    } else if (hourNum >= 1) {
      // 非一小时内('X小时前')
      result = t('lang.postDetails.someHoursAgo', { count: parseInt(hourNum) });
    } else if (minuteNum >= 1) {
      // 非一分钟内('X分钟前')
      result = t('lang.postDetails.someMinutesAgo', { count: parseInt(minuteNum) });
    } else {
      // 一分钟内('刚刚')
      result = t('lang.postDetails.justNow');
    }
    return result;
  },
  getDateDiff(dateStr, ieHours, isFullYear, isTimestamp = false) {
    let result = dateStr;
    let date = '';
    let diffValue = '';
    // 获取时间戳(此日期在企业是有时区的)
    if (!isTimestamp) {
      date = new Date(dateStr.replace(/-/g, '/'));
      diffValue = new Date().getTime() - date.getTime();
    } else {
      diffValue = dateStr;
    }
    if (diffValue > 0) {
      const yearNum = diffValue / year;

      // 今年
      if (yearNum <= 1) {
        result = this.getDateDiffNowYear(diffValue, date, ieHours, isFullYear);
      } else {
        // 非今年('YYYY-MM-DD')
        result = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      }
    }
    return result;
  },
  /*
   * @params date 需要格式化时间，支持 new Date() 类型及时间戳类型 ，new Date()
   * @params Boolean isTimestamp,是否时间戳格式  时间戳格式为 YYYY-MM-DD HH:MM:SS 或 YYYY/MM/DD HH:MM:SS
   * @params String separator，分割符，默认 - ，
   * return 时间在一个月内展示格式：xx分钟前/xx小时前/xx天前/xx周前；时间超过一个月且今年内展示格式：月份-日期；消息发送时间不在今年展示格式：年份-月份-日期，
   * */
  getDateByMS(date, isTimestamp = false, separator = '-') {
    if ((typeof date === 'string' && date.includes('-') && !isTimestamp) || isTimestamp) {
      // 时间戳格式，需要兼容IOS写法
      date = date.replace(/-/g, '/');
    }
    date = new Date(date);
    const nowDate = new Date();
    const dateTime = date.getTime();
    const nowTime = nowDate.getTime();
    const diff = nowTime - dateTime;
    if (diff < minute) {
      return t('lang.postDetails.justNow');
    }
    // 小于1小时展示X分钟前
    if (diff < hour) {
      return t('lang.h5.someMinuteAgo', { count: Math.floor(diff / minute) });
    }
    // 小于1天展示 XX小时前
    if (diff / hour < 24) {
      return t('lang.h5.someHoursAgo', { count: Math.floor(diff / hour) });
    }
    // 小于 一周 显示 XX天前
    if (diff / day <= 7) {
      return t('lang.h5.someDaysAgo', { count: Math.floor(diff / day) });
    }
    // 小于一月显示 XX周前
    if (diff / week <= 4) {
      return t('lang.h5.someWeeksAgo', { count: Math.floor(diff / week) });
    }
    const dateYear = date.getFullYear();
    const dateMonth = date.getMonth() + 1;
    const dateDate = date.getDate();
    const yearDiff = nowDate.getFullYear() - dateYear === 0;
    // 同一年内显示 月-日
    if (yearDiff) {
      return `${dateMonth}${separator}${dateDate}`;
    } else {
      // 今年之前显示 年-月-日
      return `${dateYear}${separator}${dateMonth}${separator}${dateDate}`;
    }
  },
  /**
   * @description 当日发送的消息展示格式："时:分"；非当日&本年的消息展示格式："月份-日期 时:分"；非当日&非本年的消息展示格式："年份-月份-日期 时:分"。
   * @date 需要格式化的时间
   * @return
   **/
  formatTime(date) {
    const nowDate = new Date();
    const formateDate = new Date(date);
    const years = formateDate.getFullYear();
    const months = formateDate.getMonth() + 1;
    const days = formateDate.getDate();
    let hours = formateDate.getHours();
    let minutes = formateDate.getMinutes();
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    if (hours < 10) {
      hours = `0${hours}`;
    }
    // 当天的显示 时：分
    if (nowDate.getDate() === days) {
      return `${hours}:${minutes}`;
    }
    if (nowDate.getFullYear() === years) {
      return `${months}-${days} ${hours}:${minutes}`;
    }
    return `${years}-${months}-${days} ${hours}:${minutes}`;
  },
  /**
   *  获取剩余时间
   * @param {Date} time
   * @returns
   */

  getCountdownDate(time) {
    const lastTime = (new Date(time).getTime() - new Date().getTime()) / 1000;
    const d = Math.floor(lastTime / (24 * 3600));
    const h = formatZero(Math.floor((lastTime % (24 * 3600)) / 3600));
    const m = formatZero(Math.floor((lastTime % 3600) / 60));
    const s = formatZero(Math.floor(lastTime % 60));

    return {
      d,
      h,
      m,
      s,
    };
  },
  dateFormat,
  /**
   * 获取当前时间与传入时间的先后关系
   * return
   *   true：传入时间小于当前时间
   *   false：传入时间大于当前时间
   */
  compareTimeSequence(dateStr) {
    let timestamp1 = Date.parse(new Date(dateStr));
    timestamp1 = timestamp1 / 1000;

    let timestamp2 = Date.parse(new Date());
    timestamp2 = timestamp2 / 1000;

    return timestamp1 <= timestamp2;
  },
};
