import { showDialog } from 'vant';
import wx from 'weixin-js-sdk';
import LoginTemplate from '@/components/h5/login/LoginTemplate.vue';
import env from '@/config/env';
import { getMsgFromMiniOriginal } from "@/utils/common/common";
import { cookie } from "@/utils/common/CommonUtils";
import h5Api from "@/axios/h5Api";
const AGREE_KEY = 'AGREE_STATUS';
export const ACCEPT_ALL = 'ACCEPT_ALL';
export const ONLY_REQUIRED = 'ONLY_REQUIRED';
export const COOKIE_KEY = 'hw_privacy_cookie_jdc_en';
// 同意签署协议 1
const AGREE_STATUS = 1;
const PROTOCO = 'https://';
// 谷歌登录类型值
const GOOGLE_LOGIN_TYPE = 1;
// 苹果登录类型值
const APPLE_LOGIN_TYPE = 2;
// 等待10s
const TEN_SECONDS = 10000;
export default {
  name: 'Login',
  components: {
    LoginTemplate
  },
  data() {
    return {
      agreeStatus: false,
      cookieAgree: false,
      loadingBtn: '',
      loadingTimer: null,
      appleLoadingTimer: null,
      show: false,
      type: ''
    };
  },
  computed: {
    isWeChat() {
      return this.$store.state.h5.client.isWeChat;
    }
  },
  mounted() {
    const msgFromMiniOriginal = getMsgFromMiniOriginal();
    if (msgFromMiniOriginal) {
      wx.miniProgram.navigateTo({
        'url': '/pages/login/login'
      });
    }
    this.cookieAgree = !!cookie.getCookie(COOKIE_KEY);
    this.agreeStatus = !!JSON.parse(sessionStorage.getItem(AGREE_KEY));
    this.loadingBtn = '';
    document.addEventListener("visibilitychange", () => this.reset());
    window.addEventListener('pageshow', () => this.loadingBtn = '');
  },
  unmounted() {
    clearTimeout(this.loadingTimer);
    clearTimeout(this.appleLoadingTimer);
    this.loadingTimer = null;
    this.appleLoadingTimer = null;
    document.removeEventListener("visibilitychange", () => this.reset());
    window.removeEventListener('pageshow', () => this.loadingBtn = '');
  },
  methods: {
    toPage(url) {
      const params = {
        showTitleBar: false,
        route: {
          path: url
        }
      };
      this.$rm.APPUtil.goToMobile('loadUrl', params, this.$router);
    },
    /**
     * @description 用户声明与隐私协议勾选框切换
     */
    agreeChange(val) {
      sessionStorage.setItem(AGREE_KEY, val);
    },
    /**
     * @description Cookie协议勾选框切换
     */
    cookieChange(val) {
      const cachedKey = sessionStorage.getItem(COOKIE_KEY);
      if (val && cachedKey === ONLY_REQUIRED) {
        cookie.setCookie(COOKIE_KEY, ONLY_REQUIRED, 30);
        return;
      }
      if (val && cachedKey === ACCEPT_ALL) {
        cookie.setCookie(COOKIE_KEY, ACCEPT_ALL, 30);
        return;
      }
      if (val) {
        // 刚开始给勾选过默认接受全部Cookie
        sessionStorage.setItem(COOKIE_KEY, ACCEPT_ALL);
        cookie.setCookie(COOKIE_KEY, ACCEPT_ALL, 30);
        return;
      }
      cookie.delCookie(COOKIE_KEY);
    },
    getRedirectUrl() {
      return this.$route.query.redirect || encodeURIComponent(`${window.location.origin}/h5/home`);
    },
    checkIsAgree() {
      if (!this.agreeStatus) {
        this.$toast('请先同意签署协议');
        return false;
      }
      return true;
    },
    /**
     * @description 海外版判断是否签署协议
     * @return {boolean}
     */
    checkEnIsAgree() {
      if (!this.agreeStatus || !this.cookieAgree) {
        this.$toast('Please agree to sign the agreement first.');
        return false;
      }
      return true;
    },
    checkAgree() {
      if (this.isEnJdc) {
        return this.agreeStatus && this.cookieAgree;
      }
      return this.agreeStatus;
    },
    /**
     * @description 跳转注册页面
     */
    toRegister() {
      if (!this.checkAgree()) {
        this.show = true;
        this.type = 'register';
        return;
      }
      if (this.isEnJdc && !this.checkEnIsAgree()) {
        // 海外版协议签署判断
        return;
      }
      window.location.href = `${env.uniportalMobileRegisterURL}${this.getRedirectUrl()}${this.isEnJdc ? '&lang=en_US' : ''}`;
    },
    async toLogin() {
      if (!this.checkAgree()) {
        this.show = true;
        this.type = 'login';
        return;
      }
      if (!this.isEnJdc && !this.checkIsAgree()) {
        // 国内版协议签署判断
        return;
      }
      if (this.isEnJdc && !this.checkEnIsAgree()) {
        // 海外版协议签署判断
        return;
      }
      if (this.loadingBtn) {
        return;
      }
      if (this.isEnJdc) {
        this.loadingBtn = 'uniportal';
      }
      sessionStorage.removeItem(AGREE_KEY);
      await this.logOut();
      window.location.href = `${env.uniportalMobileLoginURL}${this.getRedirectUrl()}${this.isEnJdc ? '&lang=en_US' : ''}`;
    },
    weChatLogin() {
      if (!this.checkAgree()) {
        this.show = true;
        this.type = 'wechat';
        return;
      }
      if (!this.checkIsAgree()) {
        return;
      }
      const redirectUrl = `${env.newApiBaseURL}${this.$api.user.loginFromWeChat}?redirect=${this.getRedirectUrl()}`;
      const url = `${PROTOCO}open.weixin.qq.com/connect/oauth2/authorize?appid=${env.wxOfficialAccountId}&redirect_uri=` + `${encodeURIComponent(redirectUrl)}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`;
      sessionStorage.removeItem(AGREE_KEY);
      window.location.href = url;
    },
    // 海外apple或google登录获取地址
    async getAuthenticationUrl(loginType) {
      if (this.isEnJdc && !this.checkEnIsAgree()) {
        // 海外版协议签署判断
        return;
      }
      if (this.loadingBtn) {
        return;
      }
      if (loginType === GOOGLE_LOGIN_TYPE) {
        this.loadingBtn = 'google';
        this.loadingTimer = setTimeout(() => {
          clearTimeout(this.loadingTimer);
          this.loadingTimer = null;
          this.loadingBtn = '';
          showDialog({
            title: 'Tips',
            message: 'Your internet connection is unstable. Please check your network connection.',
            confirmButtonText: 'OK',
            className: 'google-network-tip'
          }).then(() => {
            window.open(window.location.href, '_self');
          });
        }, TEN_SECONDS);
      } else {
        this.appleLoadingTimer = setTimeout(() => {
          this.loadingBtn = '';
          clearTimeout(this.appleLoadingTimer);
          this.appleLoadingTimer = null;
        }, 2000);
        this.loadingBtn = 'apple';
      }
      const url = this.$api.user.getAuthenticationUrl;
      const params = {
        loginType,
        redirectPageUrl: decodeURIComponent(this.$route.query.redirect)
      };
      const {
        code,
        data
      } = await this.$axios.get(url, {
        params,
        baseURL: env.newApiBaseURL
      });
      if (code === this.$rm.errCode.success) {
        window.location.href = data;
      }
    },
    // 海外apple登录
    toApple() {
      if (!this.checkAgree()) {
        this.show = true;
        this.type = 'apple';
        return;
      }
      this.getAuthenticationUrl(APPLE_LOGIN_TYPE);
    },
    // 海外goole登录
    toGoogle() {
      if (!this.checkAgree()) {
        this.show = true;
        this.type = 'google';
        return;
      }
      this.getAuthenticationUrl(GOOGLE_LOGIN_TYPE);
    },
    // 退出登录
    async logOut() {
      const url = `${h5Api.user.logout}?referer=${encodeURIComponent(`${window.location.origin}/h5/login`)}`;
      await this.$axios.get(url);
    },
    // 重置loading状态
    reset() {
      if (document.visibilityState !== 'visible') {
        this.loadingBtn = '';
      }
    },
    // 签订协议继续登录
    async next() {
      sessionStorage.setItem(AGREE_KEY, 'true');
      this.agreeStatus = true;
      if (this.isEnJdc && !this.cookieAgree) {
        this.cookieChange(true);
        this.cookieAgree = true;
      }
      const loginTypes = {
        wechat: this.weChatLogin,
        login: this.toLogin,
        register: this.toRegister,
        apple: this.toApple,
        google: this.toGoogle
      };
      const loginFn = loginTypes[this.type];
      this.show = false;
      await loginFn();
    }
  }
};