export default {
  overCharacters: '不超过500个字符',
  visibleTo: '浏览权限',
  All: '所有人',
  onlyMember: '仅成员',
  joiningMode: '运营商企业等加入方式',
  freeJoin: '不审核',
  applyJoin: '审核',
  invitedJoin: '邀请审核加入',
  notAllowedJoin: '关闭',
  groupOwnerJoin: '注：圈子关闭后只有群主才可以浏览',
  invitedJoinNote: '“邀请审核加入” 仅限浏览权限为“仅成员”时可用',
  passwordJoin: '口令加圈',
  enable: '启用',
  disable: '禁用',
  setPassword: '点击设置口令',
  joinCondition: '加入条件',
  registeredUsers: '注册用户',
  verifiedUsers: '验证用户',
  verifiedUsersNote: '注：“验证用户”仅限浏览权限为“仅成员”时可用',
  sharing: '分享',
  allow: '允许',
  forbid: '屏蔽',
  groupBulletin: '圈子公告',
  overTwoCharacters: '不超过255个字符',
  allowed: '允许发求助帖',
  no: '否',
  yes: '是',
  groupNotice: '圈子广场展示',
  icon: '图标',
  exceedImage: '自动缩略成 120 X 120 像素大小的图片，文件不能超过 512 KB',
  save: '保存',
  cancel: '取消',
  notSelected: '未选择任何文件',
  section: '版块',
  group: '圈子',
  upCharacters: '不能输入超出{WORD_LEN_LIMIT}字符',
  enterGroup: '请填写圈子名称',
  enterDescription: '请填写圈子简介',
  notExceed: '上传的图片大小不能超过 512KB!',
  sureInvalid: '已经启用的口令将全部作废，是否禁用？',
  submitNow: '圈子设置尚未提交，是否现在提交？',
  passwordDisabled: '口令加圈未启用',
  earnedScores: '创建圈子 经验值+5',
  submittedReview: '圈子图标已提交审核，请耐心等候',
  enterAllowed: '请输入中文、字母、数字或特殊字符(【】()+-_/&|、)',
  upEightyCharacters: '请不要超过80个字符',
  upFiftyCharacters: '请不要超过50个字符',
  securityNotice: '出于信息安全考虑，从2017年1月开始，圈子名称、浏览权限、加入方式和加入条件等配置需要平台运营组授权才能修改。',
  memberReview: '成员审核',
  pendingReview: '未审核',
  reviewHistory: '审核记录',
  batchApprove: '批量通过',
  batchReject: '批量拒绝',
  all: '全部',
  approved: '机审通过',
  rejected: '拒绝',
  noPending: '暂无需要审核成员',
  nickname: '昵称',
  email: '注册邮箱',
  account: '账号',
  applied: '申请时间',
  reason: '删除附件原因',
  referredBy: '推荐人',
  source: '来自',
  operatedBy: '操作人',
  message: '发私信',
  operated: '操作时间',
  result: '结果',
  remark: '备注',
  application: '申请信息',
  view: '查看',
  details: '查看申请信息',
  sureApprove: '是否确认通过申请？',
  sureReject: '是否确认拒绝申请？',
  addRemarks: '添加圈子成员备注',
  selectMember: '请选择成员',
  memberManagement: '成员管理',
  enterNickname: '请输入成员名称',
  search: '搜索',
  upMemberNotice: `提示：奖励发放人最多设{rewardermax}个`,
  addMember: '添加用户',
  lastVisited: '最近访问时间',
  modify: '修改',
  add: '添加',
  delete: '删除',
  pleaseEnter: '请输入',
  modificationHistory: '修改记录',
  historicalRemarks: '历史备注',
  modified: '修改时间',
  modifiedBy: '修改人',
  ok: '确定',
  awardDistributor: '奖励发放人',
  selectMembers: '请选择成员',
  online: '在线',
  starMember: '明星成员',
  moderator: '版主',
  groupOwner: '圈主',
  sectionAdmin: '版块管理员',
  groupAdmin: '圈子管理员',
  pointDistributor: '智豆发放人',
  topicCategory: '主题分类',
  informationAccordingly: '设置是否启用主题分类功能，您需要同时设定相应的分类选项，才能启用本功能',
  enableTopic: '启用主题分类',
  needTopic: '是否强制用户发表新主题时必须选择分类',
  categorizePosts: '发帖必须归类',
  displayCategory: '是否在主题前面显示分类的名称',
  preCategory: '类别前缀',
  notDisplay: '不显示',
  display: '显示',
  No: '顺序',
  categoryName: '分类名称',
  associatedTemplate: '关联模板',
  forciblyAddTag: '强制输入标签',
  addSubcategory: '添加子类',
  addCategory: '添加分类',
  enterCategory: '请填写分类名称',
  duplicateCategories: '请检验分类是否重复',
  duplicateSubcategories: '请检验子分类是否重复',
  none: '无',
  deleteSubcategories: '确定要删除该主题分类及其子类？',
  deleteCategories: '确定要删除该主题分类？',
  groupTransfer: '圈子转让',
  transferTitle: '接受转让资格：',
  transferConditions: '只能转让给本圈子的管理成员。\r\n' + '接收者要具备可再创建新圈子的资格。\r\n',
  transferTitle1: '温馨提示：',
  transferConditions1: '圈子转让后您依然是该圈的成员，但不再是圈主，所以可以自由退出该圈子。\r\n' + '转让后即刻生效，请谨慎操作。',
  transferGroup: '将圈子转让给',
  continueTransfer: '转让后操作不可逆，请确认是否转让圈主给【{demiseName}】',
  selectTransferee: '抱歉，您尚未选择被转让人',
  transferredSuccess: '圈子转让成功',
  attachmentReview: '附件审核',
  image: '图片',
  other: '非图片',
  reviewAll: '全部审核',
  fileName: '文件名',
  author: '作者',
  topic: '所在主题',
  size: '尺寸',
  downloads: '下载次数',
  reviewResult: '机审结果',
  reviewedBy: '审核人',
  operation: '操作',
  approve: '通过审核',
  cancelApproval: '撤销审核',
  deleteAttachment: '删除附件',
  deleReason: '请输入删除附件原因',
  scanFailed: '扫描失败',
  toScanned: '待扫描',
  scanning: '扫描中',
  rejectedControl: '风控审核不通过',
  noAttachments: '无可审核附件',
  approveAttachment: '风控机审不通过，可能存在未知风险，请谨慎审核，确定通过？',
  virusScan: '病毒扫描不通过',
  reposting: '帖子转载',
  invitedNotice: "提示: 浏览权限为'仅成员'，加入方式为'邀请审核加入'的圈子不允许转出帖子。",
  repostToOther: '是否开启转出功能',
  repostToOtherAppend: '（开启，则支持转载帖子至其它圈子）',
  open: '开启',
  close: '关闭',
  repostFromOther: '是否开启转入功能',
  repostFromOtherAppend: '（开启，则支持其它圈子转载帖子至该圈）',
  pcClient: 'PC端',
  browse: '浏览图像',
  dragUpload: '请上传尺寸为1200*230的图片',
  mobileDragUpload: '请上传尺寸为375*220的图片',
  upload: '上传',
  mobileClient: '移动端',
  redirectionURL: '跳转链接',
  enterURL: '请输入跳转链接',
  groupSetting: '圈子设置',
  groupCover: '封面',
  joinMode: '加入方式',
  joinFree: '自由加入',
  joinApply: '审核加入',
  groupName: '圈子名称',
  groupDescription: '圈子简介',
  groupIcon: '圈子图标',
  plateName: '板块名称',
  plateDescription: '板块简介',
  plateIcon: '板块图标',
  pendReview: '待审核列表',
  pass: '通过',
  applyReason: '申请理由',
  note: '提示',
  hasApproved: '已通过',
  deleteReason: '删除附件原因',
  KIAScanFailed: 'KIA扫描不通过',
  addRemark: '您还可以对此用户添加备注，比如公司、姓名等信息方便区分，这些信息您与圈子的其他管理员可以在“管理-成员管理”中查看和修改',
  accessDenied: '当前页面访问受限， 5秒后将跳转到首页',
  clickToRedirect: '如果您的浏览器没有自动跳转，请点击此链接',
  applicant: '申请人',
  inviter: '邀请人',
  information: '提示信息',
  confirm: '请确认是否 ',
  approval: '通过',
  rejection: '拒绝',
  thisApplication: '加圈申请？',
  joinTime: '加入时间',
  addDistributors: '是否申请在{groupSpaceName}增加以下"奖励发放人"？',
  distributionType: '奖励发放类型：',
  searchingUsers: '查找用户',
  pleaseEnterContext: '请输入内容',
  addList: '添加到列表',
  userFound: '以下是查找到的用户列表（最多显示20个）：',
  username: '用户名：',
  huaweiEmployee: '华为人',
  noteBefore: '提示: ',
  forbiddenNote: '私密圈E不允许转载。',
  pendedReview: '待审核',
  delectAttachment: '删除附件，需要传入删除附件原因',
  reviewResultNotice: '文件需要进行系统扫描，该过程大约需要一小时完成',
  addMemberRemarks: '请为该圈子成员添加备注，比如公司、姓名等信息以便区分，这些信息您与圈子的其他管理员可以在"管理圈子-成员管理"中查看和修改',
  remarksNotEmpty: '备注信息不能为空',
  enterRemarks: '请输入备注信息',
  approvalReason: '审批理由',
  enterOptional: '请输入理由（选填）',
  closeRemark: '关闭',
  transferFailed: '该成员是本圈圈主，不可转让给当前圈主',
};
