export default {
  name: 'Login',
  props: {
    showDesc: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {};
  }
};