import * as types from '../mutation-types'
import api from '@/axios/baseApi.js'
import env from '@/config/env'

// state
const state = {
  commonPostId: '',
  forumInfo: {}, // 圈子、板块信息
  stamped: '', // 管理员选择图章信息
  topicInfo: {}, // 帖子详情
  topicContent: {}, // 帖子内容
  privilege: {}, // 帖子管理员权限
  adminPermission: [], // 回复管理员权限
  topicReply: [], // 帖子回复列表
  detailParams: {}, // 帖子详情页入参
  threadDetail: {}, // 帖子详情页入参
  topicPoll: {}, // 投票帖子内容
  topicSurvey: {}, // 问卷帖子内容
  topicAuction: {}, // 商品帖子内容
  stampedInfo: {}, // 图章
  userInfo: {},
  xhrList: [], // xhr数组集合
  progress: 0, // 上传视频和附件的进度 【0-100】
  editUpload: [], // 编辑器上传图片
  bussinessType: 1, // 编辑器上传图片入参 发帖:1  编辑:2  评论:3  回复:4 私信:5 专栏:6
  hasUploadImg: false,
  tinymcyId: '', // tinymce编辑器id
  chatUserId: '', // 私信id
  columnId: '', // 专栏id
  isAutoJoinGroup: false, //是否是公开圈帖子自动加圈
}

// getters
const getters = {
}

// mutations
const mutations = {
  [types.COMMON_POST_ID](state, data) {
    state.commonPostId = data
  },
  [types.FORUM_INFO](state, data) {
    state.forumInfo = data
  },
  [types.COMMON_STAMPED](state, data) {
    state.stamped = data
  },
  [types.TOPIC_INFO](state, data) {
    state.topicInfo = data
  },
  [types.TOPIC_CONTENT](state, data) {
    state.topicContent = data
  },
  [types.TOPIC_REPLY](state, data) {
    state.topicReply = data
  },
  [types.PRIVILEGE_INFO](state, data) {
    state.privilege = data
  },
  [types.ADMIN_PERMISSION](state, data) {
    state.adminPermission = data
  },
  [types.DETAIL_PARAMS](state, data) {
    state.detailParams = data
  },
  [types.TOPIC_POLL](state, data) {
    state.topicPoll = data
  },
  [types.TOPIC_SURVEY](state, data) {
    state.topicSurvey = data
  },
  [types.TOPIC_AUCTION](state, data) {
    state.topicAuction = data
  },
  [types.COMMON_STAMPED_INFO](state, data) {
    state.stampedInfo = data
  },
  [types.REFRESH_DETAIL](state, data) {
    window.tinyMCE.remove('#fastpostmessage')
    state.threadDetail = data
  },
  [types.USER_INFO](state, data) {
    state.userInfo = data
  },
  [types.XHR_LIST](state, data) {
    state.xhrList.push(data)
  },
  [types.REFRESH_XHR_LIST](state) {
    state.xhrList = []
  },
  [types.EDIT_UPLOAD](state, data) {
    state.editUpload.push(data)
  },
  [types.REFRESH_EDIT_UPLOAD](state) {
    state.editUpload = []
  },
  [types.BUSSINESS_TYPE](state, data) {
    state.bussinessType = data
  },
  [types.UPDATE_PROGRESS](state, data) {
    if (data >= 100) {
      state.progress = 100
    } else {
      state.progress = data
    }
  },
  [types.HAS_UPLOADIMG](state, data) {
    state.hasUploadImg = data
  },
  [types.UPDATE_TINYMCYID](state, data) {
    state.tinymcyId = data
  },
  [types.CHAT_USERID](state, data) {
    state.chatUserId = data
  },
  [types.COLUMN_ID](state, data) {
    state.columnId = data
  },
  [types.AUTO_JOIN_GROUP](state, data) {
    state.isAutoJoinGroup = data
  },
}

// actions
const actions = {
  refreshDetail({ commit }, paObj = {}) {
    if (JSON.stringify(paObj) !== '{}') {
      commit(types.REFRESH_DETAIL, { query: paObj })
      let url = ''
      for (let key in paObj) {
        if (url.indexOf(key) === -1 && paObj[key]) {
          url += `${key}=${paObj[key]}&`
        }
      }
      location.href = window.location.origin + window.location.pathname + '?' + url.slice(0, -1)
      return
    }
    let loca = window.location
    let obj = {}
    let params = {}
    let arr = loca.search.substr(1).split('&')
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].split('=')
      obj[arr[i][0]] = arr[i][1]
    }
    params.query = obj
    commit(types.REFRESH_DETAIL, params)
  },
  // 投票操作
  voteBeforeLogin(store, paramString) {
    sessionStorage.setItem('VOTE_BEFORE_LOGIN', paramString)
  },

  async voteAfterLogin() {
    const voteSession = sessionStorage.getItem('VOTE_BEFORE_LOGIN')
    if (voteSession) {
      // 投票
      await api.POST(api.refactorApi.post.pollVote, JSON.parse(voteSession), env.newApiBaseURL)

      sessionStorage.removeItem('VOTE_BEFORE_LOGIN')
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
