/*
 * @Date: 2024-03-30
 * @Description: 圈子资源文件
 */
export default {
  myJoinedGroups: '我加入的圈子',
  joined: '已加入',
  join: '加入',
  apply: '申请',
  underReview: '审核中',
  createGroup: '创建我的圈子',
  noDataAvailable: '暂无内容',
  verifyAccount: '验证企业邮箱',
  applyGroup: '申请加入{0}圈子',
  applyForGroup: '申请加入“{groupName}”圈子',
  mailNeedsVerifying: '抱歉，该圈子需要验证<strong>企业邮箱</strong>之后才能申请加入!',
  mailNotHuawei: '抱歉，您当前账号的注册邮箱不是<strong>华为客户的企业邮箱，</strong>请修改后再试',
  verifyNow: '马上去验证',
  contactAdministrator: '联系管理员',
  enterYourReason: '理由不能为空',
  groupNeedsReasonToApproval: '该圈子需要管理员审核，请填写申请理由!',
  reason: '理由',
  recommendedBy: '推荐人',
  huaweiJDCGroupsTitle: '华为JDC圈子 行业数字化_产品解决方案_创新技术_云计算与物联网-华为JDC',
  huaweiJDCGroupsIndustry: '华为JDC,金融,交通,电力,制造,教育,运营商,医疗,石油石化,数字能源,数据中心,智慧矿山,农业,光伏',
  huaweiJDCDescription:
    '华为JDC,聊行业数字化,华为JDC广泛链接金融行业圈,交通行业圈,电力行业圈,制造行业圈,教育行业圈,运营商综合业务圈,医疗行业圈,石油石化行业圈用户。聚集了数字能源,数据中心,智慧矿山,农业,光伏产业等领域的从业者，围绕数字化升级需求和痛点讨论的技术知识分享社区。',
  recentVisits: '最近常逛',
  recommendedGroups: '推荐圈子',
  groupNeedsInvitation: '该圈子需要邀请审核才能加入',
  accessDenied: '当前页面访问受限， 5秒后将跳转到首页',
  clickHereToRedirect: '如果您的浏览器没有自动跳转，请点击此链接',
  huaweiJDCCommunity: '华为JDC-产品定义社区',
  groupName: '圈子名称',
  groupDescription: '圈子简介',
  allowedCharacters: '中文、字母、数字或特殊字符(【】()+-_/&|、)',
  publicGroup: '公开圈',
  public: '公开',
  privateGroup: '私密圈',
  private: '私密',
  members: '成员数',
  member: '成员',
  topics: '主题数',
  replies: '回复数',
  pointsDistributedToday: '今日发放智豆数',
  manage: '管理',
  manageGroup: '管理圈子',
  editIntroduction: '编辑简介',
  introductionNotEmpty: '版块简介不能为空！',
  inviteUsers: '邀请用户',
  scanToJoinGroup: '扫描加圈',
  logInFirst: '您需要先登录才能继续本操作',
  leave: '退出圈子',
  pending: '加圈审核中',
  joinGroup: '申请加入圈子',
  groupNeedsApproval: '该圈子需要邀请审核才能加入',
  inviteUsersToJoin: '邀请用户 加入{0}',
  inviteRegisteredUsers: '邀请已注册用户',
  plsSelect: '请选择',
  selected: '已选',
  sendInvitation: '发送邀请',
  byNickname: '按昵称',
  add: '添加',
  inviteUsersTips:
    '1、本功能只适用于邀请已经在社区或Support网站注册的用户；\n2、输入2个以上字符会自动联想，点击联想结果可以把用户添加到列表中；\n3、由于网络环境差异可能响应较慢，请耐心等待；',
  inviteUsersTipsNotice: '说明：点击发送邀请后，被邀请用户会立即加入（不需要被邀请用户同意），同时发送消息提醒至用户的消息中心。',
  added: '已添加',
  type: '类型：',
  topic: '话题',
  category: '分类',
  allTime: '全部时间',
  lastDay: '一天',
  last2Days: '两天',
  lastWeek: '一周',
  lastMonth: '一个月',
  last3Months: '三个月',
  sortBy: '默认排序',
  lastPosted: '最新发帖',
  mostReplied: '最多回帖',
  mostViewed: '最多查看',
  lastReplied: '最新回帖',
  allPosts: '所有帖子',
  myPosts: '我的发帖',
  myReplies: '我的回帖',
  unmarked: '未标记',
  unreplied: '待回复',
  unprocessed: '未处理',
  processed: '已处理',
  expired: '已过期',
  valueless: '无价值',
  markAsValueless: '标记为无价值',
  toBeClarified: '待澄清',
  valuableNotAccepted: '有价值暂不接纳',
  enterKeyword: '请输入关键词搜索',
  visibleToAuthorized: '权限组可见',
  recommended: '推荐',
  latest: '最新',
  featured: '精华',
  stickyThread: '置顶',
  hot: '热门',
  rewardedWithPoints: '帖子被赞赏',
  reposted: '转载帖',
  attachmentsContained: '包含附件',
  picturesAttached: '包含图片附件',
  videosAttached: '包含视频附件',
  locked: '帖子被关闭',
  submittedAsRR: '提交RR',
  submitAsRR: '提交RR',
  repliesOnlyToUsers1: '回帖仅作者/管理员/授权专家可见',
  repliesOnlyToUsers2: '回帖仅作者/管理员可见',
  huaweiEmployee: '华为员工',
  administrator: '管理员',
  moderator: '版主',
  groupOwner: '圈主',
  postedThisOn: '发帖时间 {postedTime}',
  lastRepliedTime: '最新回复时间：{repliedTime}',
  toViewThisPage: '本页面内容需要登录后查看，请点击',
  logIn: '登录',
  signUp: '注册',
  logInToViewMore: '查看更多精彩内容，请点击',
  toJoinGroup: '，申请加入圈子，发现更多同道中人',
  all: '全部',
  top: '置顶',
  more: '更多',
  provideProductSuggestions: '提产品建议',
  createPost: '发布新帖',
  todaysNewPosts: '今日新帖：',
  todaysReplies: '今日回复：',
  todaysNewPostsNoSymbol: '今日新帖',
  todaysRepliesNoSymbol: '今日回复',
  leaderboard: '专家排行',
  myGroups: '我的圈子',
  showLess: '收起我的所有圈子',
  showMore: '我的所有圈子',
  posts: '帖子数',
  post: '帖子',
  none: '暂无',
  onDutyModerator: '值班版主',
  points: '分',
  selectGroupJoin: '选择加入圈子',
  recommendedGroupJoin: '为您推荐了你可能喜欢的圈子，选择加入圈子一探究竟吧!',
  nextTime: '下次再说',
  joinNow: '立即加入',
  batchJoinGroup: '批量加圈',
  exitGroupHint: '您确定要退出该圈子吗？',
  exitGroupSuccess: '已成功退出圈子',
  privacyStateTitle: '隐私声明-华为JDC /华为社区',
  privacyStateKeywords:
    '隐私声明,华为,JDC,数字化,华为社区,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,实践案例,知识共享,解决方案,行业洞察,华为产品建议,无线业务,数字能源,数据存储,云核心网,家庭网络WIFI,光产品,数据通信,智慧园区,用户反馈,产品改进,产品创新,用户体验',
  privacyStateDescription:
    '华为JDC（jdc.huawei.com）是一个知识型社区，是华为产品用户、行业大咖与华为技术团队的交流互动平台，是用户反馈华为产品体验，提出产品改进建议与新需求的便捷渠道。',
  enterReason: '请输入申请加入圈子的理由',
  enterOptional: '请输入推荐人（选填）',
  joinPrivateGroupNote: '私密圈需要申请加入，审核通过后即可入圈交流哦',
  inviteUsersDisaboleTip: '加入圈子之后才能邀请用户',
  createPostDisaboleTip: '加入圈子之后才能发布新帖',
  pendingTip: '您已经申请加入该圈子，请等待圈主审核',
  enterpriseAddress: '抱歉，该圈子需要验证企业邮箱之后才能申请加入',
  verifyAccountFirst: '请先验证企业邮箱',
};
