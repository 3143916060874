/*
 * @Author:
 * @Date: 2022-09-22 20:47:26
 * @Description: h5页面
 */
export default {
  desc: 'Huawei JDC is an interactive platform for user to communicate with Huawai product experts. It is a convenient channel for feedback on product suggestions and new needs.',
  login: 'Sign In with Uniportal ID',
  register: 'Sign in',
  policy1: 'I have read and agree to the ',
  userAgreement: 'Terms of Use',
  policy2: ' and ',
  privacyStatement: 'Privacy Statement',
  agree: 'Agree',
  disagree: 'Disagree',
  bottomBar1: 'Home',
  bottomBar2: 'Group',
  bottomBar3: 'Guide',
  bottomBar4: 'Me',
  post: 'Post',
  suggest: 'Suggestions',
  updateName: "What's New",
  updated: ' has been updated.',
  noData: "That's all",
  homeTitle: 'Personal Details',
  avatarUploading: 'Uploading...',
  avatar: 'Profile Picture',
  verified: 'Verify',
  unverify: 'Not verified',
  nickname: 'Nickname',
  level: 'Level',
  personalSign: 'Personal Signature',
  realName: 'Name',
  industry: 'Industry',
  company: 'Company',
  email: 'Email',
  phoneNumber: 'Mobile Phone',
  myPosts: 'My posts',
  someMinuteAgo: '{count}m ago',
  someHoursAgo: '{count}h ago',
  someDaysAgo: '{count}d ago',
  someWeeksAgo: '{count}w ago',
  end: `- That's all -`,
  noPoint: 'No points',
  noContent: 'No data',
  imgUploadTip: 'Avatar submitted for approval. Please wait.',
  certifiedUser: 'Certified User',
  certifiedInfo: 'Certified Information',
  administrator: 'Administrator',
  repliesOnlyToUsers1: 'Replies visible only to authors/administrators/authorized experts',
  repliesOnlyToUsers2: 'Replies visible only to authors/administrators',
  submittedAsRR: 'Submitted as RR',
  reward: 'Reward',
  bean: 'Bean',
  myReply: 'My Reply',
  allReply: 'All Replies',
  showMore: 'Show More',
  recommended: 'Recommended',
  latest: 'Latest',
  earliest: 'Earliest',
  newPost: 'Post',
  posting: 'Posting',
  postSuccessful: 'Posted Successful',
  pleaseEnter: 'Enter ',
  uploading: 'Uploading...',
  enterTitle: 'Enter a title',
  enterRequired: 'Please fill in the required fields',
  enterContert: 'Enter the content',
  postSuccess: 'Posted successful',
  confirm: 'OK',
  title: 'Title',
  thinkMore: 'Cancel',
  point: '0 point | {count} point | {count} points',
  cancelBlock: 'Cancel Block',
  copy: 'Copy',
  copySuccessful: 'Copied',
  commentSuccess: 'Reply Successful',
  replySuccess: 'Reply Successful',
  enterReply: 'Enter the reply content',
  noReply: 'No replies',
  createReply: 'Say something...',
  topicCategory: 'Category',
  enterTopicCategory: 'Select the category',
  ok: 'OK',
  favoritesuccess: 'Favorite',
  cancelFavorite: 'Remove from favorites',
  praiseMyself: "You can't like your own post.",
  praiseMyselfReplies: "You can't like your own replies.",
  showMoreComment: 'View all {count} replies',
  noTopics: 'No topics',
  author: 'Author',
  noSuggestions: 'No suggestions',
  noEvents: 'No events',
  lastVisited: 'Last visited',
  nameAlreadyUse: 'The nickname is already in use. Try another one.',
  nameNotUse: "That nickname isn't available, try another one.",
  enterSign: 'Enter your signature',
  savingFailed: 'Saving failed.',
  sendEmail: 'Send Verification Email',
  sendEmailFailed: 'Sending failed.',
  verificationEmail: 'A verification email has been sent to your mailbox. Please log in to your mailbox to complete the verification.',
  contactEmail: 'Email',
  loading: 'Loading',
  agreeTitle: 'Terms and Conditions',
  agreeDes: 'To proceed, please read and accept ',
  accpeAndContinue: 'Accept and Continue',
  userAgreementBook: 'Terms of Use',
  privacyStatementBook: 'Privacy Statement',
};
