export const USER_TRUSTLIST = 'USER_TRUSTLIST';
export const USER_BASEINFO_UPDATE = 'USER_UPDATE';
export const USER_MALL_TRADE_UPDATE = 'USER_TRADE';
export const USER_ISLOGIN = 'USER_ISLOGIN';
export const USER_INFOGET = 'USER_INFOGET';
export const USER_INNERIP = 'USER_INNERIP';
export const COMMON_FULLBG_MODE_SET = 'COMMON_FULLBG_MODE_SET';

export const ROUTER_CLEAR = 'ROUTER_CLEAR';
export const ROUTER_ADD = 'ROUTER_ADD';

export const ROUTER_LANG_SET = 'ROUTER_LANG_SET';
export const ROUTER_HUAWEICONNECT_SET = 'ROUTER_HUAWEICONNECT_SET';
export const ROUTER_APP_SET = 'ROUTER_APP_SET';
export const COMMON_POST_ID = 'COMMON_POST_ID';
export const FORUM_INFO = 'FORUM_INFO';
export const COMMON_STAMPED = 'COMMON_STAMPED';
export const TOPIC_INFO = 'TOPIC_INFO';
export const TOPIC_CONTENT = 'TOPIC_CONTENT';
export const PRIVILEGE_INFO = 'PRIVILEGE_INFO';
export const ADMIN_PERMISSION = 'ADMIN_PERMISSION';
export const TOPIC_REPLY = 'TOPIC_REPLY';
export const DETAIL_PARAMS = 'DETAIL_PARAMS';
export const REFRESH_DETAIL = 'REFRESH_DETAIL';
export const TOPIC_POLL = 'TOPIC_POLL';
export const TOPIC_SURVEY = 'TOPIC_SURVEY';
export const TOPIC_AUCTION = 'TOPIC_AUCTION';
export const COMMON_STAMPED_INFO = 'COMMON_STAMPED_INFO';
export const USER_INFO = 'USER_INFO';

export const XHR_LIST = 'XHR_LIST';
export const REFRESH_XHR_LIST = 'REFRESH_XHR_LIST';
export const EDIT_UPLOAD = 'EDIT_UPLOAD';
export const REFRESH_EDIT_UPLOAD = 'REFRESH_EDIT_UPLOAD';
export const BUSSINESS_TYPE = 'BUSSINESS_TYPE';

export const CLOSE_LOGIN = 'CLOSE_LOGIN';
export const SHOW_LOGIN_DIALOG = 'SHOW_LOGIN_DIALOG';
export const SET_TIMER = 'SET_TIMER';
export const CLEAR_TIMER = 'CLEAR_TIMER';

export const HANDLE_IMAGE = 'HANDLE_IMAGE';

export const UPDATE_PROGRESS = 'UPDATE_PROGRESS';

export const FIRST_LOGIN = 'FIRST_LOGIN';

export const UPLOAD_IMG_COMPLETE = 'UPLOAD_IMG_COMPLETE';
export const HAS_UPLOADIMG = 'HAS_UPLOADIMG';

export const UPDATE_TINYMCYID = 'UPDATE_TINYMCYID';
export const CHAT_USERID = 'CHAT_USERID';
export const COLUMN_ID = 'COLUMN_ID';
export const UPDATED_INFO = 'UPDATED_INFO';

export const USER_UID = 'USER_UID';
export const COMMON_TAB_TYPE = 'COMMON_TAB_TYPE';

export const GET_USERINFO = 'GET_USERINFO';
export const NOT_LOGIN = 'NOT_LOGIN';
export const SET_CONTAINER = 'SET_CONTAINER';
export const MESSAGE_LIST = 'MESSAGE_LIST';
export const USER_TOKEN_VALUE = 'USER_TOKEN_VALUE';
export const USER_TOKEN = 'USER_TOKEN';
export const GET_USERLOGIN = 'GET_USERLOGIN';
export const LEAVE_ACTIVE = 'LEAVE_ACTIVE';
export const IS_LOGIN = 'IS_LOGIN';
export const VISIBLE_AREA = 'VISIBLE_AREA';
export const TOUCH_MOVE = 'TOUCH_MOVE';
export const COMMON_POST_CONTENT = 'COMMON_POST_CONTENT';
export const COMMON_REPLY = 'COMMON_REPLY';
export const APPRECIATE = 'APPRECIATE';
export const PRV_MESSAGE_LIST = 'PRV_MESSAGE_LIST';
export const UPDATE_PRV_PAGE = 'UPDATE_PRV_PAGE';
export const PERSONAL_PARAMS = 'PERSONAL_PARAMS';
export const COMMON_WECHAT_PAGE_CODE = 'COMMON_WECHAT_PAGE_CODE';
export const AUCTION_STATUS = 'AUCTION_STATUS';
export const REFRESH_POST = 'REFRESH_POST';
export const CURRENT_CLIENT = 'CURRENT_CLIENT';
export const LONG_PRESS = 'LONG_PRESS';
export const CLICK_CONTENT = 'CLICK_CONTENT';
export const GO_TO_USER_CENTER = 'GO_TO_USER_CENTER';
export const NICKNAME_MODIFY = 'NICKNAME_MODIFY';
export const USER_IMAGE = 'USER_IMAGE';
export const USER_TOKEN_CREATED = 'USER_TOKEN_CREATED';

export const MESSAGE_LIST_LOOP = 'MESSAGE_LIST_LOOP';

export const LAUNCH_URL = 'LAUNCH_URL';

export const SMALL_AVATAR = '2';
export const MIDDLE_AVATAR = '1';
export const LARGE_AVATAR = '0';

export const INDUSTRYLIST_UPDATE = 'INDUSTRYLIST_UPDATE';
export const PRODUCTFIELD_UPDATE = 'PRODUCTFIELD_UPDATE';
export const POP_OUT_RECOMMENT = 'POP_OUT_RECOMMENT';

export const SET_APP_NOTIFY_CONTROL = 'SET_APP_NOTIFY_CONTROL';
export const PAGE_TO_BOTTOM_BTN_CHANGE = 'PAGE_TO_BOTTOM_BTN_CHANGE';
export const AUTO_JOIN_GROUP = 'AUTO_JOIN_GROUP';
