export default {
  discussion: {
    discussion: '交流分享',
    topicCategory: '选择主题分类',
    enterTopic: '请输入标题',
    onlyHuawei: '仅华为员工可见',
  },
  template: {
    productModel: '产品型号',
    industry: '产品行业',
    productIndustry: '产品行业',
    productDomain: '产品领域',
    mandatory: '必填',
    optional: '选填',
    atLeastThree: '请至少输入3个字',
    selectIndustries: '请选择涉及行业',
    loading: '加载中',
    overLength: '超出{0}字符不能被提交！',
    getTemplateIDFailed: '获取模板ID失败',
  },
  topicTags: {
    industry: '行业',
    selectIndustry: '选择行业',
    selectDomain: '选择领域',
    selectProductDomain: '选择产品领域',
    coverSetting: '封面设置',
    tag: '标签',
    selectTag: '选择标签',
    tags: '主题标签',
    tagCover: '标签封面',
    add: '增加一项',
    customTags: '自定义标签',
    limitFiveTags: '用英文逗号分隔多个标签，最多可填写 5 个',
    recentTags: '最近使用标签',
    attachment: '上传附件',
    limitXls: '单个文件大小{fileSize}，支持格式：{fileType}',
    fileLimit: '上传附件只能是{fileType}格式，文件大小不能超过{fileSize}!',
    fileSizeLimit: '上传附件大小不能超过{fileSize}!',
    videoLabel: '视频',
    video: '上传视频',
    limitVideo: '支持上传{fileSize}内的视频。支持{fileType}格式',
    limitVideoSize: '只支持上传{fileSize}以内的视频',
    noMoreVideoUpload: '无法上传更多的视频',
    maxUploadLimit: '当前限制最大上传数为{maxLimit}',
    coverImage: '封面图片',
    supportedImageSize: '图片尺寸为：800*450。支持上传jpg、jpeg和png格式',
    luckyDraw: '抽奖设置',
    luckyDrawChances: '抽奖次数',
    atLeastRepliesLength: '回帖内容长度最少',
    characters: '字符',
    awardIssuing: '颁奖配置',
    enterTag: '请输入自定义标签',
    supportedImg: '只支持上传jpg、jpeg和png格式',
    deleteFailed: '删除失败',
    errorParameter: '您传入的抽奖参数有误',
    autoSetting: '自动设置',
    singleImage: '单图',
    noImage: '无图',
    reUpload: '重新上传',
    notChooseImageTips: '请先上传封面图片',
    autoSettingTips: '使用【自动设置】功能设置封面图时,将从帖子中抓取首图裁取合适尺寸。',
    uploadCoverImageDesc: '优质的封面有助于推荐，格式支持JPEG、JPG、PNG、GIF（封面建议尺寸240*140px）',
    uploadCoverImage: '上传封面',
  },
  thirdPartyTradeSecrets: {
    containThirdPartyTradeSecrets: '是否包含第三方商业秘密',
    thirdPartyTradeSecrets: '第三方商业秘密',
    thirdPartyTradeSecretsDes:
      "第三方商业秘密是指第三方不为公众所知悉、具有商业价值并经权利人采取保密措施的技术信息和经营信息，包括但不限于：产品的价格信息、路标规划、商务授权、核心算法和源代码等。如有疑问，请联系:helponline{'@'}huawei.com（各社区公共邮箱）。",
  },
  questionnaire: {
    preview: '预览',
    questionnaire: '调研问卷',
    multiOrderingQuestion: '多选排序题',
    dependsOnOptions: '依赖于第1题的第1、2选项',
    cancelAssociationToDelete: '题目已经有关联关系，请解除关联关系，才能删除！',
    cancelAssociationToChangeNumber: '题目已经有关联关系，请解除关联关系，才能修改序号！',
    positiveIntegerNumber: '序号为正整数',
    moreThanOneNumber: '序号不能小于1，请重新填写！',
    uniqueNumber: '序号不能重复，请重新填写序号！',
    orderNumberEmpty: '题目序号不能为空，请检查',
    blankQuestionnaire: '问卷为空，点击此处增加问题',
    addQuestion: '增加问题',
    validUntil: '问卷有效期至：',
    selectDate: '选择日期时间',
    note: '注意：问卷提交后不可以修改，请认真检查。',
    currentNo: '当前题目',
    type: '问题类型',
    title: '问题名称',
    enterTitle: '请输入问题',
    enterTitleName: '请输入问题名称',
    cannotBlankTitle: '问题名称不能为空！',
    description: '问题描述',
    descriptionOver: '问题描述超出2000字符不能被提交',
    addBlank: '插入填空符',
    options: '创建选项',
    addOption: '添加选项',
    blankOption: '选项不能为空！请细心检查！',
    otherOption: '其它选项',
    requiredInput: '文本内容必填',
    settings: '题目设置',
    mandatory: '必填',
    inputMandatoryOptions: '请输入必选选项数',
    orderOptions: '选项排序',
    associateQuestions: '题目关联',
    uploadImage: '需要回答者上传图片',
    addAssociatedQuestion: '添加关联题目',
    associatedQuestion: '关联题目',
    blankAssociatedQuestion: '关联题目不能为空',
    associatedOptions: '关联选项',
    associatedQuestionRule: '当“关联题目{index}”选择以上的任意一个选项时，当前题目{currentNum} 出现',
    threeUnderscores: '填空符用3个下划线表示，填空题显示长度跟下划线的个数相关，点击按钮可以在光标处插入填空符。',
    selectSingleAnswer: '【单选题】',
    singleAnswer: '单选题',
    selectMultipleAnswer: '【多选题】',
    multipleAnswer: '多选题',
    selectParagraph: '【文本题】',
    paragraph: '文本题',
    selectShortAnswer: '【填空题】',
    shortAnswer: '填空题',
    selectOrderRanking: '【多选排序题】',
    orderRanking: '多选排序题',
    blank: '填空题',
    question: '题目',
    edit: '编辑问题',
    overMaximumNumber: '序号已经是最大值，无法增加',
    overMaximumQuestions: '调查问卷题目数量超过最大限制',
    newQuestion: '新增问题',
    overMaximumOptions: '选项数量超过上限，最多支持添加20个选项',
    noQuestions: '暂未发现可关联题目，请检查后重试',
    associatedQuestionsLimit: '最多可支持关联10个题目',
    leastTwoOptions: '单项选择题选项至少为2个，请增加选项！',
    leastThreeOptions: '多项选择题选项至少为3个，请增加选项！',
    leastThreeUnderscores: '填空题至少要包含1个填空符（3个下划线）',
    duplicateOption: '选项有重复值，请检查',
    positiveInteger: '必选选项数必须是正整数，请检查后重新输入',
    mandatoryOptionsNumberError: '必选选项数不能超过选项数，请检查后重新输入',
    other: '其它',
    background: '调研背景（选填）',
    setPlaceholder: '设置提示语',
    enterOption: '请输入',
    enter: '请输入',
    select: '请选择',
    clickHere: '点击此处',
    blankQuestion: '问卷为空，',
    addQuestions: '增加问题',
    minSelect: '（必选{minSelect}项）',
    minSelects: '（必选{minSelect}项）',
    pleaseSpecify: '请输入内容',
    otherEnterContent: '请输入内容',
    editIcon: '编辑',
    delectIcon: '删除',
    no: '序号：',
    duplicate: '有重复的关联题目，请检查',
    drag: '拖拽',
    dragable: '拖拽调整选项顺序',
    dependOn: '该题目存在关联关系，第{relyIndex}题依赖于第{index}题，需解除关联后才能调整顺序',
    dependOnTip: '若拖动改变题目顺序，则关联关系将被解除，需要重新配置，是否继续？',
    disassociate: '提示',
    disassociateBtn: '解除关联',
  },
  vote: {
    poll: '发起投票',
    eventID: '活动ID',
    supportsOptions: '选项: 最多可填写 80 个选项',
    singleBox: '单框模式',
    eachLineOneOption: '每行填写 1 个选项',
    upload: '点击上传',
    delete: '点击删除',
    add: '增加一项',
    showVotingResults: '投票后结果才可见',
    showVoters: '公开投票参与人',
    uploadImage: '请选择图片文件（jpeg,jpg,gif,png,bmp,octet-stream）',
    imageLimit: '图片大小不能超过 2MB!',
    redeemPoints: '智豆兑换',
    createExchange: '发布兑换',
    changedTopicCategoryTip: '切换主题分类后当前所有内容将不会保留，是否继续？',
    createPost: '发表帖子',
    createSurvey: '发布问卷',
    expiredLogin: '用户登录信息过期，请重新登录！',
    editPost: '编辑帖子',
    overMaximumTags: '主题标签超过上限，最多支持添加5个主题标签',
    enterTags: '请输入自定义标签',
    uploading: '附件或视频正在上传中…',
    containsThirdPartyTradeSecrets: '抱歉，您的文件中包含第三方商业秘密，不能发布',
    ifcontainsThirdPartyTradeSecrets: '请在下方勾选您的内容是否包含第三方商业秘密',
    selectTopicCategory: '请选择主题分类',
    topicCannotBeBlank: '主题名称不能为空',
    setMandatoryFields: '请完成必填项',
    requiredContentWarning: '请填写内容',
    requiredGiftDescriptionWarning: '请填写礼品描述',
    pollDaysLimit: '计票天数应小于365天',
    questionnaireCannotBeBlank: '问卷题目不能为空',
    leastTwoPollOptions: '抱歉，至少要填写2个投票选项',
    pollOptionLimit: '投票选项最多255个字符',
    optionsAndImagesCountError: '抱歉，选项数量和图片数量需要一致',
    limitGiftNameLength: '礼品名称和主题名称总长度最多支持150个字符',
    surveyTime: '调查时间',
    deadline: '调查截止时间',
    closeVote: '关闭投票',
    finished: '已结束',
    imageSizeLimit: '图片尺寸为：800*450。支持上传jpg、jpeg和png格式',
    luckyDrawChancesTips: '抽奖次数指投票、回答问卷、第一次回帖所获得的抽奖次数，次数会自动获取活动id的白名单限制次数',
    selectOptionsLimit: '最多可选{count}项',
    pollDay: '记票天数{count}天',
    drawIdInvalid: '抽奖活动ID无效',
    positiveInteger: '请输入大于0的整数数字',
  },
};
